import ioTypes from '@/constants/order/ioTypes';
const values = {
  wagon: {
    basedata: {
      chapters: {
        basedata: {
          data: [
            {
              path: 'Waggonnummer',
              type: 'string',
              ignoreProgress: true,
            },
            {
              path: 'Halterkurzkennzeichen',
              type: 'option',
              option: 'ListEigentumsmerkmal',
              showTranslatedText: false,
            },
            {
              path: 'EntryDate',
              type: 'date',
              ioType: ioTypes.INCOMING,
              halfWidth: true,
              activityType: 'ACTIVITY',
            },
            {
              path: 'ExitDate',
              type: 'date',
              ioType: ioTypes.OUTGOING,
              halfWidth: true,
              activityType: 'ACTIVITY',
            },
            {
              path: 'OverhaulDate',
              type: 'date',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'ExecutedMaintenanceLevel',
              type: 'option',
              option: 'ListingExecutedMaintenanceLevel',
              showTranslatedText: true,
            },
            {
              path: 'RevisionType',
              type: 'option',
              option: 'ListingExecutedMaintenanceLevel',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'Eigengewicht',
              type: 'double',
              unit: 'kg',
            },
            {
              path: 'AnzahlTeilfahrzeuge',
              type: 'option',
              option: 'AnzahlTeilfahrzeuge',
              inputType: 'double',
            },
            {
              path: 'AnzahlAchsen',
              type: 'option',
              option: 'AnzahlAchsen',
              inputType: 'double',
            },
            {
              path: 'AnzahlDrehgestelle',
              type: 'option',
              option: 'AnzahlDrehgestelle',
              inputType: 'double',
            },
            {
              path: 'LetztesLadegut',
              type: 'string',
            },
            {
              path: 'AllowableAxleLoad',
              type: 'option',
              option: 'MaximalWheelsetLoad',
              inputType: 'double',
              unit: 't',
            },
            /*{
                                path: 'Understructure.BogieCollection.Bogie[0].BearingSpringCollection.BearingSpring2[0].SchluesselzifferVPI',
                                name: 'SchluesselzifferVPI',
                                type: 'option',
                                option: 'ListingSchluesselzifferVPI',
                                showTranslatedText: true
                            },*/
            {
              path: 'TemperaturbereichgemaessTSI',
              type: 'option',
              option: 'TemperaturbereichgemaessTSI',
            },
            {
              path: 'TSIApproval',
              type: 'boolean',
            },
            {
              path: 'TSIZulassungsstelle',
              type: 'option',
              option: 'TSIZulassungsstelle',
              showTranslatedText: true,
            },
          ],
        },
        baseBogies: {
          uniformPaths: [
            'BearingSpringCollection.BearingSpring2[0].SchluesselzifferVPI',
            'DGArtdesFedergehaenges',
            'BogieAxleCenters',
          ],
          listPath: 'Understructure.BogieCollection.Bogie',
          differentiator: 'PositionDrehgestell',
          differentiatorOption: 'ListingPositionDrehgestell',
          differentiatorName: 'Position',
          currentUniform: true,
          data: [
            {
              path: 'BearingSpringCollection.BearingSpring2[0].SchluesselzifferVPI',
              name: 'SchluesselzifferVPI',
              type: 'option',
              option: 'ListingSchluesselzifferVPI',
              showTranslatedText: true,
            },
            {
              path: 'DGArtdesFedergehaenges',
              name: 'ArtdesFedergehaenges',
              type: 'option',
              option: 'ListingArtdesFedergehaenges',
            },
            {
              path: 'BogieAxleCenters',
              type: 'double',
              option: 'ListingBogieAxleCenters',
              unit: 'm',
            },
          ],
        },
        bogies: {
          differentiator: 'PositionDrehgestell',
          differentiatorOption: 'ListingPositionDrehgestell',
          differentiatorName: 'Position',
          uniformPaths: [
            'BearingSpringCollection.BearingSpring2[0].SchluesselzifferVPI',
          ],
          dependentPaths: [
            {
              quantity: 3,
              path: 'AnzahlTeilfahrzeuge',
              option: 'AnzahlTeilfahrzeuge',
              upperValue: 2,
              lowerValue: 1,
            },
          ],
          data: [
            {
              path: 'PositionDrehgestell',
              name: 'Position',
              type: 'option',
              option: 'ListingPositionDrehgestell',
              inputType: 'double',
            },
            {
              path: 'productIdentifier',
              type: 'baseString',
            },
            {
              path: 'DbseriesNumber',
              name: 'BogieDbseriesNumber',
              type: 'option',
              option: 'Drehgestellbauart',
            },
            {
              path: 'Type',
              name: 'BogieType',
              type: 'option',
              option: 'ListingDrehgestelltyp',
              showTranslatedText: true,
            },
            {
              path: 'BearingSpringCollection.BearingSpring2[0].SchluesselzifferVPI',
              name: 'SchluesselzifferVPI',
              type: 'option',
              option: 'ListingSchluesselzifferVPI',
              showTranslatedText: true,
            },
            {
              path: 'DGArtdesFedergehaenges',
              name: 'ArtdesFedergehaenges',
              type: 'option',
              option: 'ListingArtdesFedergehaenges',
            },
            {
              path: 'Drehgestellspezifikation',
              type: 'option',
              option: 'ListingDrehgestellspezifikation',
            },
            {
              path: 'GroestmassZ8',
              type: 'double',
              unit: 'mm',
            },
            {
              path: 'KleinstmassZ8',
              type: 'double',
              unit: 'mm',
            },
            {
              path: 'NennmassZ8',
              type: 'double',
              unit: 'mm',
            },
            {
              path: 'BogieAxleCenters',
              type: 'double',
              option: 'ListingBogieAxleCenters',
              unit: 'm',
            },
          ],
          listPath: 'Understructure.BogieCollection.Bogie',
        },
        additional: {
          data: [
            {
              path: 'SonstigeAngabenBetriebsfreigabe',
              ioType: ioTypes.OUTGOING,
              type: 'textarea',
            },
          ],
        },
      },
    },
    labeling: {
      data: [
        {
          path: 'AbstossundAuflaufverbot',
          type: 'boolean',
        },
        {
          path: 'Auflaufverbot',
          type: 'boolean',
        },
        {
          path: 'Austauschverfahren',
          type: 'option',
          option: 'Austauschverfahren',
        },
        {
          path: 'Begrenzungsprofil',
          type: 'option',
          option: 'Begrenzungsprofil',
        },
        {
          path: 'Bogenhalbmesser',
          type: 'option',
          option: 'Bogenhalbmesser',
          inputType: 'double',
          unit: 'm',
        },
        {
          path: 'Drehzapfenabstand',
          type: 'double',
          unit: 'm',
        },
        {
          path: 'Faehrbootfaehigkeit',
          type: 'boolean',
        },
        {
          path: 'Knickwinkel230',
          type: 'boolean',
        },
        {
          path: 'KuppenradiusbeiAblaufbergen',
          type: 'option',
          option: 'Kuppenradius',
          inputType: 'double',
          unit: 'm',
        },
        {
          path: 'LaengeueberPuffer',
          type: 'double',
          unit: 'm',
        },
        {
          path: 'Laufkreisdurchmessermax',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'Tunnelfaehigkeit',
          type: 'boolean',
        },
        {
          path: 'UICGattung',
          type: 'option',
          option: 'UICGattung',
        },
        {
          path: 'Understructure.ChassisCollection.Chassis[0].TorsionallyStiff',
          name: 'TorsionallyStiff',
          type: 'boolean',
        },
        {
          path: 'Understructure.Brake.HandBrakeBrakeload',
          type: 'double',
          unit: 't',
        },
        {
          path: 'Understructure.Brake.MaxDecline',
          type: 'double',
          unit: '%',
        },
        {
          path: 'Anschriften.Fremdreklame',
          type: 'boolean',
        },
      ],
    },
    revisionDeadline: {
      data: [
        {
          path: 'WerkletzteG40',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteG40',
          type: 'date',
        },
        {
          path: 'WerkletzteG42',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteG42',
          type: 'date',
        },
        {
          path: 'WerkletzteG43',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteG43',
          type: 'date',
        },
        {
          path: 'WerkletzteG48',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteG48',
          type: 'date',
        },
        {
          path: 'WerkletzteG481',
          type: 'option',
          option: 'Workshop',
        },
        {
          path: 'DatumletzteG481',
          type: 'date',
        },
        {
          path: 'WerkletzteG482',
          type: 'option',
          option: 'Workshop',
        },
        {
          path: 'DatumletzteG482',
          type: 'date',
        },
        {
          path: 'WerkletzteG483',
          type: 'option',
          option: 'Workshop',
        },
        {
          path: 'DatumletzteG483',
          type: 'date',
        },
        {
          path: 'Revisionszyklus',
          type: 'option',
          option: 'Revisionszyklus',
          inputType: 'double',
          unit: 'y',
        },
        {
          path: 'Fristzyklus',
          type: 'option',
          option: 'ListingFristzyklus',
          inputType: 'double',
          unit: 'M',
        },
        {
          path: 'Revisionsfristverlaengerung',
          type: 'option',
          option: 'ListingRevisionsfristverlaengerung',
          inputType: 'double',
          unit: 'M',
        },
        {
          path: 'DatumLetzteFrist',
          type: 'date',
        },
      ],
    },
    loadLimitGrid: {
      chapters: {
        base: {
          data: [
            {
              path: 'Anschriften.ArtLadegutanschrift',
              type: 'option',
              option: 'ListingArtLadegutanschrift',
              callbackMutation: 'order/setCurrentLoadLimitGridType',
              showTranslatedText: true,
            },
            {
              path: 'Anschriften.KeinGefahrengutGemRID',
              type: 'boolean',
            },
            {
              path: 'Faehig120kmh',
              type: 'boolean',
            },
          ],
        },
        loadLimitGrid: {
          differentiator: 'Klapptafel',
          differentiatorOption: 'ListingKlapptafel',
          listPath: 'Anschriften.KlapptafelLadegutCollection.KlapptafelLadegut',
          uniformPaths: ['Lastgrenzraster.Lastgrenzrastertyp'],
          chapters: {
            loadLimitGrid: {
              data: [
                {
                  path: 'Lastgrenzraster.Lastgrenzrastertyp',
                  type: 'option',
                  option: 'Lastgrenzrastertyp',
                  showTranslatedText: true,
                  callbackMutation: 'order/updateLoadLimitType',
                },
                {
                  path: 'Klapptafel',
                  type: 'option',
                  option: 'ListingKlapptafel',
                  inputType: 'double',
                },
                {
                  name: 'Anschriften.Ladegut',
                  path: 'Ladegut',
                  type: 'option',
                  option: 'Ladegut',
                  showTranslatedText: true,
                },
                {
                  path: 'Lastgrenzraster.A100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.A120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.B100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.B120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.B1100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.B1120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.B2100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.B2120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.C100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.C120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.C2100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.C2120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.C3100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.C3120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.C4100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.C4120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.D100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.D120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.D2100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.D2120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.D3100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.D3120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.D4100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.D4120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.E100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.E4100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.E4120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.E5100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.E5120',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'Lastgrenzraster.Sterne',
                  type: 'option',
                  option: 'ListingLastgrenzrasterSterne',
                  showTranslatedText: true,
                },
              ],
            },
            additionalGrid: {
              listPath: 'ZusatzrasterCollection.Zusatzraster',
              differentiator: 'LfdNrLaendergruppe',
              differentiatorOption: 'ListingLfdNrLaendergruppe',
              differentiatorName: 'Position',
              data: [
                {
                  path: 'LfdNrLaendergruppe',
                  type: 'option',
                  option: 'ListingLfdNrLaendergruppe',
                  inputType: 'double',
                },
                {
                  path: 'LaendergruppeZusatzraster',
                  type: 'multioption',
                  option: 'Laenderkennung',
                  showTranslatedText: true,
                },
                {
                  path: 'CMD80',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'CMD100',
                  type: 'double',
                  unit: 't',
                },
                {
                  path: 'CM100',
                  type: 'double',
                  unit: 't',
                },
              ],
            },
          },
        },
      },
    },
    aggreementGrid: {
      data: [
        {
          path: 'Anschriften.Vereinbarungsraster.LaendergruppeVereinbarungsraster',
          type: 'multioption',
          option: 'Laenderkennung',
          showTranslatedText: true,
        },
        {
          path: 'Anschriften.Vereinbarungsraster.Ursprungsland',
          type: 'option',
          option: 'Laenderkennung',
          showTranslatedText: true,
        },
      ],
    },
    brake: {
      chapters: {
        brake: {
          data: [
            {
              path: 'Understructure.Brake.BrakeRevisionType',
              type: 'option',
              option: 'BrakeRevisionType',
              showTranslatedText: true,
            },
            {
              path: 'Understructure.Brake.BrakeRevisionDate',
              type: 'date',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'Understructure.Brake.BrakeType',
              type: 'option',
              option: 'BrakeType',
              showTranslatedText: true,
            },
            {
              path: 'Understructure.Brake.ChangeoverWeight',
              type: 'double',
              unit: 't',
            },
            {
              path: 'Understructure.Brake.BrakedWeightEmpty',
              type: 'double',
              unit: 't',
            },
            {
              path: 'Understructure.Brake.BrakedWeightLoaded',
              type: 'double',
              unit: 't',
            },
            {
              path: 'Understructure.Brake.MaximalBrakeLoad',
              type: 'double',
              unit: 't',
            },
            {
              path: 'Understructure.Brake.LoadProportionalBraking',
              type: 'option',
              option: 'LoadProportionalBraking',
            },
            {
              path: 'Understructure.Brake.DiskBrake',
              type: 'boolean',
            },
            {
              path: 'Understructure.Brake.TypeUIC5431',
              type: 'option',
              option: 'TypeUIC',
            },
          ],
        },
        valve: {
          listPath:
            'Understructure.Brake.PneumaticalComponents.DistributorUnitCollection.DistributorUnit',
          differentiator: 'Position',
          differentiatorOption: 'SteuerventilPosition',
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'SteuerventilPosition',
              inputType: 'double',
            },
            {
              path: 'productIdentifier',
              type: 'baseString',
            },
            {
              path: 'Type',
              name: 'SteuerventilArt',
              type: 'option',
              option: 'SteuerventilType',
              showTranslatedText: true,
            },
            {
              path: 'LetzteAufarbeitung',
              name: 'SteuerventilLetzteAufarbeitung',
              type: 'date',
            },
          ],
        },
        airReservoir: {
          listPath:
            'Understructure.Brake.PneumaticalComponents.AirReservoirCollection.AirReservoir',
          differentiator: 'Position',
          differentiatorOption: 'LuftbehaelterPosition',
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'LuftbehaelterPosition',
              inputType: 'double',
            },
            {
              path: 'YearOfManufacture',
              name: 'LuftbehaelterYearOfManufacture',
              type: 'year',
            },
            {
              path: 'LastInspectionDate',
              name: 'LuftbehaelterLastInspectionDate',
              type: 'date',
            },
            {
              path: 'Volume',
              name: 'LuftbehaelterVolume',
              type: 'option',
              option: 'Volume',
              inputType: 'double',
              unit: 'l',
            },
          ],
        },
        loadValve: {
          listPath:
            'Understructure.Brake.PneumaticalComponents.LoadProportionalValveCollection.LoadProportionalValve',
          differentiator: 'PositionLoadProportionalValve',
          differentiatorName: 'Position',
          differentiatorOption: 'ListingPositionLoadProportionalValve',
          data: [
            {
              path: 'PositionLoadProportionalValve',
              type: 'option',
              name: 'Position',
              option: 'ListingPositionLoadProportionalValve',
            },
            {
              path: 'Quantity',
              name: 'WiegeventilQuantity',
              type: 'option',
              option: 'WiegeventilQuantity',
              inputType: 'double',
            },
            {
              path: 'Type',
              name: 'WiegeventilType',
              type: 'option',
              option: 'WiegeventilType',
            },
          ],
        },
        relayValve: {
          data: [
            {
              path: 'Understructure.Brake.PneumaticalComponents.RelayValve.Type',
              name: 'RelaisventilType',
              type: 'option',
              option: 'RelaisventilType',
            },
          ],
        },
        cylinder: {
          listPath:
            'Understructure.Brake.MechanicalComponents.BrakeCylinderCollection.BrakeCylinder',
          differentiator: 'Position',
          differentiatorOption: 'BremszylinderPosition',
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'BremszylinderPosition',
              inputType: 'double',
            },
            {
              path: 'productIdentifier',
              type: 'baseString',
            },
            {
              path: 'Type',
              name: 'brakeCylinder',
              type: 'option',
              option: 'BremszylinderType',
              showTranslatedText: true,
            },
            {
              path: 'Dimension',
              name: 'brakeCylinderDimension',
              type: 'option',
              option: 'BremszylinderDimension',
              unit: 'mm',
            },
            {
              path: 'KolbenhubBeladenMaxNegTol',
              name: 'brakeCylinderKolbenhubBeladenMaxNegTol',
              type: 'double',
              unit: 'mm',
            },
            {
              path: 'KolbenhubBeladenMaxPosTol',
              name: 'brakeCylinderKolbenhubBeladenMaxPosTol',
              type: 'double',
              unit: 'mm',
            },
            {
              path: 'KolbenhubBeladenSoll',
              name: 'brakeCylinderKolbenhubBeladenSoll',
              type: 'double',
              unit: 'mm',
            },
          ],
        },
        rodAdjuster: {
          listPath:
            'Understructure.Brake.MechanicalComponents.BrakeRodAdjusterCollection.BrakeRodAdjuster',
          differentiator: 'BrakeRodAdjusterPosition',
          differentiatorOption: 'BremszylinderPosition',
          differentiatorName: 'Position',
          maxSize: 4,
          data: [
            {
              path: 'BrakeRodAdjusterPosition',
              type: 'option',
              name: 'Position',
              option: 'Position',
              inputType: 'double',
            },
            {
              path: 'productIdentifier',
              type: 'baseString',
            },
            {
              path: 'Type',
              name: 'rodAdjuster',
              type: 'option',
              option: 'BremsgestaengestellerType',
              showTranslatedText: true,
            },
            {
              path: 'AdjustmentLength',
              type: 'option',
              option: 'AdjustmentLength',
              inputType: 'double',
              unit: 'mm',
            },
          ],
        },
        brakeblockHolder: {
          data: [
            {
              path: 'Understructure.Brake.MechanicalComponents.BrakeblockHolder.Type',
              name: 'BrakeblockHolderType',
              type: 'option',
              option: 'BremsklotzschuhType',
            },
          ],
        },
        brakeblockShoe: {
          data: [
            {
              path: 'Understructure.Brake.MechanicalComponents.BrakeBlockShoe.AnzahlproWagen',
              name: 'BrakeBlockShoeAnzahlproWagen',
              type: 'double',
            },
            {
              path: 'Understructure.Brake.MechanicalComponents.BrakeBlockShoe.Dimension',
              name: 'BrakeBlockShoeDimension',
              type: 'option',
              option: 'BremssohleDimension',
              inputType: 'double',
              unit: 'mm',
            },
            {
              path: 'Understructure.Brake.MechanicalComponents.BrakeBlockShoe.BrakeBlockShoeDenomination',
              name: 'BrakeBlockShoeBrakeBlockShoeDenomination',
              type: 'option',
              option: 'BrakeBlockShoeDenomination',
            },
            {
              path: 'Understructure.Brake.MechanicalComponents.BrakeBlockShoe.Material',
              name: 'BrakeBlockShoeMaterial',
              type: 'option',
              option: 'BremssohleMaterial',
            },
          ],
        },
        handBrake: {
          listPath: 'Understructure.Brake.HandBrakeCollection.HandBrake',
          differentiator: 'HandBrakePosition',
          differentiatorName: 'Position',
          differentiatorOption: 'ListingHandBrakePosition',
          data: [
            {
              path: 'HandBrakePosition',
              type: 'option',
              name: 'Position',
              option: 'ListingHandBrakePosition',
              inputType: 'double',
            },
            {
              path: 'BrakePlatformCommand',
              name: 'HandbremseBrakePlatformCommand',
              type: 'boolean',
            },
            {
              path: 'BottomCommand',
              name: 'HandbremseBottomCommand',
              type: 'boolean',
            },
          ],
        },
        detektor: {
          data: [
            {
              path: 'Understructure.Brake.Entgleisungsdetektor.Hersteller',
              name: 'Entgleisungsdetektor.Hersteller',
              type: 'option',
              option: 'ListingHerstellerEntgleisungsdetektor',
              optionType: 'ListingHersteller',
            },
            {
              path: 'Understructure.Brake.Entgleisungsdetektor.Herstellerbezeichnung',
              name: 'Entgleisungsdetektor.Herstellerbezeichnung',
              type: 'option',
              option: 'ListingHerstellerbezeichnung',
            },
          ],
        },
      },
    },
    wheelsets: {
      chapters: {
        basedata: {
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'Position',
              ignoreProgress: true,
              inputType: 'double',
            },
            {
              path: 'Wheelsetnumber',
              type: 'string',
              ignoreProgress: true,
            },
            {
              path: 'DismountDate',
              type: 'date',
              ioType: ioTypes.INCOMING,
              halfWidth: true,
              activityType: 'ACTIVITY',
            },
            {
              path: 'MountDate',
              type: 'date',
              ioType: ioTypes.OUTGOING,
              halfWidth: true,
              activityType: 'ACTIVITY',
            },
            {
              path: 'GestempeltesEinbaudatum',
              type: 'monthAndYear',
            },
            {
              path: 'WheelsetType',
              type: 'option',
              option: 'WheelsetType',
              showTranslatedText: true,
            },
            {
              path: 'EigentumsmerkmalRadsatz',
              type: 'option',
              option: 'ListEigentumsmerkmal',
              showTranslatedText: true,
            },
            {
              path: 'Ausbaugrund',
              type: 'option',
              option: 'AusbaugrundRadsatz',
              showTranslatedText: true,
            },
            {
              path: 'OverhaulDate',
              type: 'date',
            },
            {
              path: 'IsMaintenanceLevel',
              type: 'option',
              optionType: 'MaintenanceLevel',
              option: 'MaintenanceLevelWheelset',
              showTranslatedText: true,
            },
            {
              path: 'WheelsetCaseType',
              type: 'option',
              option: 'WheelsetBearingHousingType',
              showTranslatedText: true,
            },
            {
              path: 'ArDimension',
              type: 'double',
              invoke: 'calculateSRMass',
              unit: 'mm',
            },
            {
              path: 'SrMass',
              type: 'formula',
              formula: 'calculateSRMass',
              decimalPlaces: 3,
              params: {
                ar: { path: 'ArDimension' },
                sdA: { path: 'WheelCollection.Wheel[0].FlangeThicknessSd' },
                sdB: { path: 'WheelCollection.Wheel[1].FlangeThicknessSd' },
              },
            },
          ],
        },
        wheelBasedata: {
          listPath: 'WheelCollection.Wheel',
          differentiator: 'Side',
          fixedSet: true,
          data: [
            {
              path: 'LfNrderSchmelzeRad',
              type: 'string',
            },
            {
              path: 'WheelBatchNumber',
              type: 'string',
            },
          ],
        },
        axleBasedata: {
          data: [
            {
              path: 'Axle.DefectClassMarking',
              name: 'Axle.DefectClassMarkingWagon',
              type: 'option',
              option: 'AxleDefectClass',
              showTranslatedText: true,
            },
            {
              path: 'Axle.UicDefectClass',
              type: 'option',
              option: 'AxleUicDefectClass',
              showTranslatedText: true,
            },
          ],
        },
        axlePaintwork: {
          listPath: 'Axle.CoatingAxleCollection.Paintwork',
          minSize: 0,
          maxSize: 1,
          differentiator: 'Art',
          data: [
            {
              path: 'Art',
              name: 'differentiator.Art',
              type: 'option',
              option: 'Art',
              showTranslatedText: true,
            },
            { path: 'AnstrichDurchgefeuhrt', type: 'boolean' },
          ],
        },
        wheelMeasures: {
          listPath: 'WheelCollection.Wheel',
          differentiator: 'Side',
          data: [
            {
              path: 'FlangeThicknessSd',
              type: 'double',
              invoke: 'calculateSRMass',
              unit: 'mm',
            },
            {
              path: 'FlangeHeigthSh',
              type: 'double',
              unit: 'mm',
            },
            {
              path: 'FlangeFaceQr',
              type: 'double',
              unit: 'mm',
            },
            {
              path: 'NominalTreadDiameter',
              type: 'double',
              unit: 'mm',
            },
            {
              path: 'RadialDeviation',
              type: 'double',
              unit: 'mm',
            },
          ],
        },
        audits: {
          data: [
            {
              path: 'WorkshopIS3',
              type: 'option',
              option: 'Workshop',
              showTranslatedText: true,
            },
            {
              path: 'DatumletzteIS3',
              type: 'date',
            },
            {
              path: 'WorkshopIS2',
              type: 'option',
              option: 'Workshop',
              showTranslatedText: true,
            },
            {
              path: 'DatumletzteIS2',
              type: 'date',
            },
            {
              path: 'WorkshopIS1',
              type: 'option',
              option: 'Workshop',
              showTranslatedText: true,
            },
            {
              path: 'DatumletzteIS1',
              type: 'date',
            },
            {
              path: 'WorkshopLastIL',
              type: 'option',
              option: 'Workshop',
              showTranslatedText: true,
            },
            {
              path: 'DatumletzteIL',
              type: 'date',
            },
            {
              path: 'State',
              type: 'option',
              option: 'WheelsetCondition',
              showTranslatedText: true,
            },
          ],
        },
        bearing: {
          listPath: 'BearingCollection.Bearing',
          differentiator: 'Position',
          differentiatorOption: 'BearingPosition',
          uniformPaths: ['Grease', 'BearingType', 'MaterialCase'],
          completeUniform: true,
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'BearingPosition',
              showTranslatedText: true,
            },
            {
              path: 'Grease',
              type: 'option',
              option: 'GreaseType',
              showTranslatedText: true,
            },
            {
              path: 'BearingType',
              type: 'option',
              option: 'BearingType',
              showTranslatedText: true,
            },
            {
              path: 'MaterialCase',
              type: 'option',
              option: 'ListingMaterial',
              showTranslatedText: true,
            },
          ],
        },
        bearingHousing: {
          listPath: 'BearingHousingCollection.BearingHousing',
          differentiator: 'Side',
          data: [
            {
              path: 'Side',
              type: 'option',
              name: 'side',
              option: 'Side',
              showTranslatedText: true,
            },
            {
              path: 'BearingHousingType',
              type: 'option',
              option: 'BearingHousingType',
              showTranslatedText: true,
            },
          ],
        },
      },
      differentiator: 'Position',
      listPath: 'Understructure.WheelsetCollection.Wheelset',
    },
    superstructure: {
      listPath: 'SuperstructureCollection.Superstructure',
      differentiator: 'Position',
      chapters: {
        basedata: {
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'Position',
            },
            {
              path: 'SuperstructureType',
              type: 'option',
              option: 'customList',
              optionAdd: [
                'all',
                'tank',
                'staubgut',
                'schuettgut',
                'flatwagon',
                'autoTransporter',
                'box',
              ],
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
          ],
        },
        tank: {
          data: [
            {
              path: 'Tank.TankNr',
              type: 'string',
            },
            {
              path: 'Tank.ZulassungsNr',
              type: 'string',
            },
            {
              path: 'Tank.Tankcode',
              type: 'option',
              option: 'TankcodeMineraloelChemie',
            },
            {
              path: 'Tank.Behaelterpruefdatum',
              type: 'monthAndYear',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'Tank.WerkLetzteAPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Tank.DatumLetzteAPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Tank.WerkLetzteLPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Tank.DatumLetzteLPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Tank.WerkLetztePPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Tank.DatumLetztePPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Tank.WerkLetzteDPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Tank.DatumLetzteDPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Tank.WerkLetzteKPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Tank.DatumLetzteKPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Tank.RechtsgebietTank',
              type: 'option',
              option: 'ListingRechtsgebiet',
            },
            {
              path: 'Tank.DatumNaechsteTankpruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Tank.AnzahlKammern',
              type: 'option',
              option: 'AnzahlKammern',
            },
            {
              path: 'Tank.ArtNaechsteTankpruefung',
              type: 'option',
              option: 'ListingArtBehaelterpruefung',
            },
            {
              path: 'Tank.AuskleidungVorhanden',
              type: 'boolean',
            },
            {
              path: 'Tank.Baujahr',
              type: 'year',
            },
            {
              path: 'Tank.Betriebsdruck',
              type: 'double',
              unit: 'MPa',
            },
            {
              path: 'Tank.Hersteller',
              type: 'option',
              option: 'ListingHerstellerTank',
              optionType: 'ListingHersteller',
            },
            {
              path: 'Tank.HerstellerSerialnummer',
              type: 'string',
            },
            {
              path: 'Tank.LetzteRundnahtpruefung',
              type: 'date',
            },
            {
              path: 'Tank.NiedrigstezulaessigeFuelltemperatur',
              type: 'double',
              unit: '°C',
            },
            {
              path: 'Tank.Pruefdruck',
              type: 'double',
              unit: 'MPa',
            },
            {
              path: 'Tank.Prueforganisation',
              type: 'option',
              option: 'ListingPrueforganisation',
            },
            {
              path: 'Tank.RealvolumengemaessRID',
              type: 'double',
              unit: 'l',
            },
            {
              path: 'Tank.Tankvolumen',
              type: 'double',
              unit: 'l',
            },
            {
              path: 'Tank.UIPReinheitsgrad',
              type: 'string',
            },
            {
              path: 'Tank.Waermeisoliert',
              type: 'boolean',
            },
            {
              path: 'Tank.WeisseBauchbinde',
              type: 'boolean',
            },
            {
              path: 'Tank.Heizung.Betriebsdruck',
              type: 'double',
              unit: 'MPa',
            },
            {
              path: 'Tank.Heizung.Pruefdruck',
              type: 'double',
              unit: 'MPa',
            },
            {
              path: 'Tank.Isolierung.WerkstoffgruppeIsolierabdeckung',
              type: 'option',
              option: 'ListingWerkstoffgruppe',
            },
          ],
        },
        staubgut: {
          data: [
            {
              path: 'Staubgutbehaelter.ConstructionYear',
              type: 'year',
            },
            {
              path: 'Staubgutbehaelter.DatumLetzteBehaelterpruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Staubgutbehaelter.VesselIdentification',
              type: 'string',
            },
            {
              path: 'Tank.ZulassungsNr',
              type: 'string',
            },
            {
              path: 'Tank.Tankcode',
              type: 'string',
              option: 'TankcodeMineraloelChemie',
            },
            {
              path: 'Staubgutbehaelter.Volume',
              type: 'double',
              unit: 'l',
            },
            {
              path: 'Staubgutbehaelter.MaximumOperatingPressure',
              type: 'double',
              unit: 'MPa',
            },
            {
              path: 'Staubgutbehaelter.TestingPressure',
              type: 'double',
              unit: 'MPa',
            },
            {
              path: 'Staubgutbehaelter.RealBoxCapacity',
              type: 'double',
              unit: 'l',
            },
            {
              path: 'Staubgutbehaelter.Reinheitsgrad',
              type: 'option',
              option: 'LisitingReinheitsgrad',
            },
            {
              path: 'Staubgutbehaelter.WerkLetzteAPruefung',
              name: 'Tank.WerkLetzteAPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Staubgutbehaelter.DatumLetzteAPruefung',
              name: 'Tank.DatumLetzteAPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Staubgutbehaelter.WerkLetzteLPruefung',
              name: 'Tank.WerkLetzteLPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Staubgutbehaelter.DatumLetzteLPruefung',
              name: 'Tank.DatumLetzteLPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Staubgutbehaelter.WerkLetztePPruefung',
              name: 'Tank.WerkLetztePPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Staubgutbehaelter.DatumLetztePPruefung',
              name: 'Tank.DatumLetztePPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Staubgutbehaelter.WerkLetzteDPruefung',
              name: 'Tank.WerkLetzteDPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Staubgutbehaelter.DatumLetzteDPruefung',
              name: 'Tank.DatumLetzteDPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Staubgutbehaelter.WerkLetzteKPruefung',
              name: 'Tank.WerkLetzteKPruefung',
              type: 'option',
              option: 'Workshop',
            },
            {
              path: 'Staubgutbehaelter.DatumLetzteKPruefung',
              name: 'Tank.DatumLetzteKPruefung',
              type: 'monthAndYear',
            },
            {
              path: 'Staubgutbehaelter.RechtsgebietStaubgutbehaelter',
              name: 'Tank.RechtsgebietTank',
              type: 'option',
              option: 'ListingRechtsgebiet',
            },
            {
              path: 'Staubgutbehaelter.DatumNaechsteBehaelterpruefung',
              type: 'monthAndYear',
            },
          ],
        },
        fluidisation: {
          listPath: 'EquipmentPowder.FluidisationCollection.Fluidisation',
          differentiator: 'Position',
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'Position',
            },
            {
              path: 'BauartAuflockerungseinrichtung',
              name: 'AuflockerungseinrichtungBauart',
              type: 'option',
              option: 'ListingBauartAuflockerungseinrichtung',
            },
            {
              path: 'Material2',
              name: 'AuflockerungseinrichtungMaterial',
              type: 'option',
              option: 'ListMaterialAuflockerungseinrichtung',
            },
          ],
        },
        schuettgut: {
          data: [
            {
              path: 'Schuettgutbehaelter.NominalCapacity',
              type: 'double',
              unit: 'm3',
              unitDisplayed: 'm³',
            },
          ],
        },
        flatwagon: {
          data: [
            {
              path: 'SuperstructureFlatwagon.SingleCargoArea',
              type: 'double',
              unit: 'm²',
            },
            {
              path: 'SuperstructureFlatwagon.CargoArea',
              type: 'double',
              unit: 'm²',
            },
          ],
        },
        box: {
          data: [
            {
              path: 'Box.Area',
              type: 'double',
              unit: 'm²',
            },
            {
              path: 'Box.Volume',
              type: 'double',
              unit: 'l',
            },
          ],
        },
        autoTransporter: {
          data: [
            {
              path: 'Autotransporter.MaxHeightLowerLevel',
              type: 'double',
              unit: 'm',
            },
            {
              path: 'Autotransporter.MaxLoadLowerLevel',
              type: 'double',
              unit: 't',
            },
            {
              path: 'Autotransporter.UpperLoadingLevel.MaxLoadUpperLevel',
              type: 'double',
              unit: 't',
            },
          ],
        },
        equipmentFlachwagen: {
          data: [
            {
              path: 'EquipmentFlachwagen.Jack.JackManufacturer',
              type: 'option',
              option: 'ListingHerstellerJack',
              optionType: 'ListingHersteller',
            },
            {
              path: 'EquipmentFlachwagen.Jack.JackType',
              type: 'option',
              option: 'ListingJackType',
            },
            {
              path: 'EquipmentFlachwagen.Jack.SerialNumber',
              type: 'string',
            },
            {
              path: 'EquipmentFlachwagen.Drehrahmen.DrehrahmenManufacturer',
              type: 'option',
              option: 'ListingHersteller',
            },
            {
              path: 'EquipmentFlachwagen.LocatingPinForContainers.Type',
              type: 'option',
              option: 'ContainerzapfenType',
            },
          ],
        },
        manhole: {
          data: [
            {
              //path actually expects a optionlist-value
              path: 'Tankausruestung.MannlochCollection.Manhole[0].BauartMannloch',
              name: 'BauartMannloch',
              type: 'option',
              option: 'ListingBauartMannloch',
            },
          ],
        },
      },
    },
    drawAndBufferingGear: {
      chapters: {
        climbingProtection: {
          data: [
            {
              //path actually expects a optionlist-value
              path: 'Understructure.DrawAndBufferingGear.ClimbingProtection.ClimbingProtectionManufacturer',
              name: 'AufkletterschutzVorhanden',
              type: 'boolean',
              option: 'ListingHersteller',
            },
          ],
        },
        buffer: {
          listPath:
            'Understructure.DrawAndBufferingGear.BufferCollection.Buffer',
          differentiator: 'Position',
          differentiatorOption: 'BufferPosition',
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'BufferPosition',
            },
            {
              path: 'BauartPuffer',
              name: 'BufferBauartPuffer',
              type: 'option',
              option: 'ListingBauartPuffer',
            },
            {
              path: 'LetzteAufarbeitung',
              name: 'BufferLetzteAufarbeitung',
              type: 'date',
            },
            {
              onlyForCollectionKey: '1L',
              isAbsolutePath: true,
              path: 'Understructure.ChassisCollection.Chassis[0].Z111L',
              name: 'Z111L',
              type: 'double',
              unit: 'mm',
            },
            {
              onlyForCollectionKey: '2L',
              isAbsolutePath: true,
              path: 'Understructure.ChassisCollection.Chassis[0].Z112L',
              name: 'Z112L',
              type: 'double',
              unit: 'mm',
            },
            {
              onlyForCollectionKey: '1R',
              isAbsolutePath: true,
              path: 'Understructure.ChassisCollection.Chassis[0].Z111R',
              name: 'Z111R',
              type: 'double',
              unit: 'mm',
            },
            {
              onlyForCollectionKey: '2R',
              isAbsolutePath: true,
              path: 'Understructure.ChassisCollection.Chassis[0].Z112R',
              name: 'Z112R',
              type: 'double',
              unit: 'mm',
            },
          ],
        },
        couplingRod: {
          listPath:
            'Understructure.DrawAndBufferingGear.CouplingRodCollection.CouplingRod',
          differentiator: 'Position',
          differentiatorOption: 'ZugeinrichtungPosition',
          uniformPaths: ['TypCouplingRod', 'CouplingRodManufracturer'],
          completeUniform: true,
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'ZugeinrichtungPosition',
            },
            {
              path: 'TypCouplingRod',
              name: 'BauartKuppelstange',
              type: 'string',
            },
            {
              path: 'CouplingRodManufracturer',
              name: 'HerstellerKuppelstange',
              type: 'string',
            },
          ],
        },
        coupling: {
          listPath:
            'Understructure.DrawAndBufferingGear.CouplingCollection.Coupling',
          differentiator: 'Position',
          differentiatorOption: 'ZugeinrichtungPosition',
          uniformPaths: ['Bauart', 'Bruchlast'],
          completeUniform: true,
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'ZugeinrichtungPosition',
            },
            {
              path: 'Bauart',
              name: 'KupplungBauart',
              type: 'option',
              option: 'KupplungBauart',
            },
            {
              path: 'Bruchlast',
              name: 'KupplungBruchlast',
              type: 'option',
              option: 'ListingBruchlast',
              inputType: 'double',
              unit: 'KN',
              unitDisplayed: 'kN',
            },
          ],
        },
        drawHook: {
          listPath:
            'Understructure.DrawAndBufferingGear.DrawHookCollection.DrawHook',
          differentiator: 'Position',
          differentiatorOption: 'ZughakenPosition',
          uniformPaths: ['Bauart', 'Zughakenlaenge', 'Bruchlast'],
          completeUniform: true,
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'ZughakenPosition',
            },
            {
              path: 'Bauart',
              name: 'ZughakenBauart',
              type: 'option',
              option: 'ZughakenBauart',
            },
            {
              path: 'Zughakenlaenge',
              type: 'option',
              option: 'ListingZughakenlaenge',
              inputType: 'double',
              unit: 'mm',
            },
            {
              path: 'Bruchlast',
              name: 'ZughakenBruchlast',
              type: 'option',
              option: 'ListingBruchlast',
              optionType: 'Bruchlast',
              inputType: 'double',
              unit: 'KN',
              unitDisplayed: 'kN',
            },
          ],
        },
        drawGearUniform: {
          listPath:
            'Understructure.DrawAndBufferingGear.DrawGearCollection.DrawGear',
          differentiator: 'Position',
          differentiatorOption: 'ZugeinrichtungPosition',
          uniformPaths: ['Bauart'],
          completeUniform: true,
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'ZugeinrichtungPosition',
            },
            {
              path: 'Bauart',
              name: 'ZugeinrichtungBauart',
              type: 'option',
              option: 'ZugeinrichtungBauart',
            },
          ],
        },
        drawGearSingle: {
          listPath:
            'Understructure.DrawAndBufferingGear.DrawGearCollection.DrawGear',
          differentiator: 'Position',
          differentiatorOption: 'ZugeinrichtungPosition',
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'ZugeinrichtungPosition',
            },
            {
              path: 'Bauart',
              name: 'ZugeinrichtungBauart',
              type: 'option',
              option: 'ZugeinrichtungBauart',
            },
          ],
        },
      },
    },
    mobileService: {
      chapters: {
        basedata: {
          data: [
            {
              path: 'DeadlineExtension',
              type: 'boolean',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'PerformedMaintenanceSteps',
              type: 'textarea',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'DeferredWork',
              type: 'textarea',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'ChangedComponents',
              type: 'textarea',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'MaintenancePerformedCorrectly',
              type: 'boolean',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'ReleaseToServiceGranted',
              type: 'boolean',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
            {
              path: 'IssuingDateCurrentReleaseToService',
              type: 'date',
              ioType: ioTypes.OUTGOING,
              activityType: 'ACTIVITY',
            },
          ],
        },
      },
    },
  },
  wheelset: {
    basedata: {
      data: [
        {
          path: 'Wheelsetnumber',
          type: 'string',
        },
        {
          path: 'DismountedFromWagon',
          type: 'string',
          halfWidth: true,
          useSelfCheckNumber: true,
          ioType: ioTypes.INCOMING,
          activityType: 'ACTIVITY',
        },
        {
          path: 'MountedInWagon',
          type: 'string',
          halfWidth: true,
          useSelfCheckNumber: true,
          ioType: ioTypes.OUTGOING,
          activityType: 'ACTIVITY',
        },
        {
          path: 'DateMounted',
          type: 'date',
          halfWidth: true,
          ioType: ioTypes.OUTGOING,
          activityType: 'ACTIVITY',
        },
        {
          path: 'NameMounted',
          type: 'string',
          halfWidth: true,
          ioType: ioTypes.OUTGOING,
          activityType: 'ACTIVITY',
        },
        {
          path: 'GestempeltesEinbaudatum',
          type: 'date',
        },
        {
          path: 'EigentumsmerkmalRadsatz',
          type: 'option',
          option: 'ListEigentumsmerkmal',
          showTranslatedText: true,
        },
        {
          path: 'OverhaulDate',
          type: 'date',
        },
        {
          path: 'IsMaintenanceLevel',
          type: 'option',
          optionType: 'MaintenanceLevel',
          option: 'MaintenanceLevelWheelset',
          showTranslatedText: true,
        },
        {
          path: 'Ausbaugrund',
          type: 'option',
          option: 'AusbaugrundRadsatz',
          showTranslatedText: true,
        },
        {
          path: 'WheelsetType',
          type: 'option',
          option: 'WheelsetType',
          showTranslatedText: true,
        },
        {
          path: 'WheelsetCaseType',
          type: 'option',
          option: 'WheelsetBearingHousingType',
          showTranslatedText: true,
        },
        {
          path: 'EhemaligeStaatsbahn',
          type: 'option',
          option: 'EhemaligeStaatsbahn',
          showTranslatedText: true,
        },
        {
          path: 'MaximumLoad',
          type: 'option',
          option: 'MaximalWheelsetLoad',
          inputType: 'double',
          unit: 't',
        },
        {
          path: 'TsiApproval',
          name: 'TSIApproval',
          type: 'boolean',
        },
        {
          path: 'TSIZulassungsnummer',
          name: 'TSIZulassungsnummer',
          type: 'string',
        },
        {
          path: 'TSIZulassungsstelleRadsatz',
          name: 'TSIZulassungsstelle',
          type: 'option',
          option: 'TSIZulassungsstelle',
          showTranslatedText: true,
        },
      ],
    },
    wheelBasedata: {
      listPath: 'WheelCollection.Wheel',
      differentiator: 'Side',
      fixedSet: true,
      data: [
        {
          path: 'WheelManufacturer',
          type: 'option',
          option: 'ListingHersteller',
          showTranslatedText: true,
        },
        {
          path: 'YearOfConstructionWheel',
          type: 'monthAndYear',
        },
        {
          path: 'LfNrderSchmelzeRad',
          type: 'string',
        },
        {
          path: 'WheelBatchNumber',
          type: 'string',
        },
        {
          path: 'MaterialWheel',
          type: 'option',
          option: 'Werkstoffe',
          showTranslatedText: true,
        },
        {
          path: 'WheelType',
          type: 'option',
          option: 'WheelType',
          showTranslatedText: true,
        },
        {
          path: 'WheelLoadCapacity',
          type: 'option',
          inputType: 'double',
          option: 'MaximalWheelsetLoad',
          unit: 't',
          showTranslatedText: true,
        },
        {
          path: 'TSIZulassungsstelleRad',
          name: 'TSIZulassungsstelle',
          type: 'option',
          option: 'TSIZulassungsstelle',
          showTranslatedText: true,
        },
        {
          path: 'TSIZulassungsnummerRad',
          name: 'TSIZulassungsnummer',
          type: 'string',
        },
      ],
    },
    measures: {
      data: [
        {
          path: 'Ar1',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'Ar2',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'ArDimension',
          type: 'double',
          invoke: 'calculateSRMass',
          unit: 'mm',
        },
        {
          path: 'SrMass',
          type: 'formula',
          formula: 'calculateSRMass',
          unit: 'mm',
          decimalPlaces: 3,
          params: {
            ar: { path: 'ArDimension' },
            sdA: { path: 'WheelCollection.Wheel[0].FlangeThicknessSd' },
            sdB: { path: 'WheelCollection.Wheel[1].FlangeThicknessSd' },
          },
        },
        {
          path: 'CToC',
          type: 'double',
        },
      ],
    },
    wheelMeasures: {
      listPath: 'WheelCollection.Wheel',
      differentiator: 'Side',
      fixedSet: true,
      data: [
        {
          path: 'FlangeThicknessSd',
          type: 'double',
          invoke: 'calculateSRMass',
          unit: 'mm',
        },
        {
          path: 'FlangeHeigthSh',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'FlangeFaceQr',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'RimWidth',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'NominalTreadDiameter',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'RadialDeviation',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'AxialDeviation',
          type: 'double',
          unit: 'mm',
        },
      ],
    },
    axleBasedata: {
      data: [
        {
          path: 'Axle.AxleType',
          type: 'option',
          option: 'AxleType',
          showTranslatedText: true,
        },
        {
          path: 'Axle.AxleNumberOld',
          type: 'string',
        },
        {
          path: 'Axle.AxleManufacturer',
          type: 'option',
          option: 'ListingHersteller',
          showTranslatedText: true,
        },
        {
          path: 'Axle.YearOfConstructionAxle',
          type: 'year',
        },
        {
          path: 'Axle.AxleMaterial',
          type: 'option',
          option: 'WelleType',
          optionType: 'Werkstoffe',
          showTranslatedText: true,
        },
        {
          path: 'Axle.EigentumsmerkmalWelle',
          type: 'option',
          option: 'ListEigentumsmerkmal',
          showTranslatedText: true,
        },
        {
          path: 'Axle.AxleBatchNumber',
          type: 'string',
        },
        {
          path: 'Axle.AxleLoadCapacity',
          type: 'option',
          option: 'MaximalWheelsetLoad',
          unit: 't',
          inputType: 'double',
        },
        {
          path: 'Axle.DefectClassMarking',
          type: 'option',
          option: 'AxleDefectClass',
          showTranslatedText: true,
        },
        {
          path: 'Axle.UicDefectClass',
          type: 'option',
          option: 'AxleUicDefectClass',
          showTranslatedText: true,
        },
        {
          path: 'Axle.RadsatznummerWelle',
          type: 'string',
        },
        {
          path: 'Axle.AxleEndCap',
          type: 'option',
          option: 'AxleEndCap',
        },
        {
          path: 'Axle.TSIZulassungsnummerWelle',
          name: 'TSIZulassungsnummer',
          type: 'string',
        },
        {
          path: 'Axle.TSIZulassungsstelleWelle',
          name: 'TSIZulassungsstelle',
          type: 'option',
          option: 'TSIZulassungsstelle',
        },
      ],
    },
    axleMeasures: {
      data: [
        {
          path: 'Axle.JournalRadialDeviationA',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'Axle.JournalRadialDeviationB',
          type: 'double',
          unit: 'mm',
        },
        {
          path: 'Axle.OuterAxleStubBoreA',
          type: 'double',
          invoke: 'calculateShrinkInterferenceFit',
          unit: 'mm',
        },
        {
          path: 'Axle.InnerAxleStubBoreA',
          type: 'double',
          invoke: 'calculateShrinkInterferenceFit',
          unit: 'mm',
        },
        {
          path: 'Axle.InnerAxleStubBoreB',
          type: 'double',
          invoke: 'calculateShrinkInterferenceFit',
          unit: 'mm',
        },
        {
          path: 'Axle.OuterAxleStubBoreB',
          type: 'double',
          invoke: 'calculateShrinkInterferenceFit',
          unit: 'mm',
        },
        {
          path: 'Axle.OuterShrinkInterferenceFitLeftA',
          type: 'formula',
          formula: 'calculateShrinkInterferenceFit',
          unit: 'µm',
          params: {
            axleStubBore: { path: 'Axle.OuterAxleStubBoreA' },
            diameterRing: {
              path: 'DiameterRing',
              collectionIdentifier: ['Vorne A', 'Innen A'],
              referencePath: 'wheelset.bearing.chapters.bearing',
            },
          },
        },
        {
          path: 'Axle.InnerShrinkInterferenceFitLeftA',
          type: 'formula',
          formula: 'calculateShrinkInterferenceFit',
          unit: 'µm',
          params: {
            axleStubBore: { path: 'Axle.InnerAxleStubBoreA' },
            diameterRing: {
              path: 'DiameterRing',
              collectionIdentifier: ['Hinten A', 'Außen A'],
              referencePath: 'wheelset.bearing.chapters.bearing',
            },
          },
        },
        {
          path: 'Axle.InnerShrinkInterferenceFitRightB',
          type: 'formula',
          formula: 'calculateShrinkInterferenceFit',
          unit: 'µm',
          params: {
            axleStubBore: { path: 'Axle.InnerAxleStubBoreB' },
            diameterRing: {
              path: 'DiameterRing',
              collectionIdentifier: ['Vorne B', 'Innen B'],
              referencePath: 'wheelset.bearing.chapters.bearing',
            },
          },
        },
        {
          path: 'Axle.OuterShrinkInterferenceFitRightB',
          type: 'formula',
          formula: 'calculateShrinkInterferenceFit',
          unit: 'µm',
          params: {
            axleStubBore: { path: 'Axle.OuterAxleStubBoreB' },
            diameterRing: {
              path: 'DiameterRing',
              collectionIdentifier: ['Hinten B', 'Außen B'],
              referencePath: 'wheelset.bearing.chapters.bearing',
            },
          },
        },
        {
          path: 'Axle.RundlaufabweichungWelle',
          type: 'double',
          invoke: 'calculateShrinkInterferenceFit',
          unit: 'mm',
        },
        {
          path: 'Axle.Reibring.ReibringWidth',
          type: 'double',
          unit: 'mm',
        },
      ],
    },
    bearing: {
      chapters: {
        bearing: {
          listPath: 'BearingCollection.Bearing',
          differentiator: 'Position',
          differentiatorOption: 'BearingPosition',
          uniformPaths: ['BearingType', 'MaterialCase'],
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'BearingPosition',
              showTranslatedText: true,
            },
            {
              path: 'DiameterRing',
              type: 'double',
              invoke: 'calculateShrinkInterferenceFit',
              unit: 'mm',
            },
            {
              path: 'ManufacturerBearing',
              type: 'option',
              option: 'ListingHerstellerLager',
              optionType: 'ListingHersteller',
              showTranslatedText: true,
            },
            {
              path: 'YearOfConstructionRollerBearing',
              type: 'year',
            },
            {
              path: 'WorkshopBearing',
              type: 'option',
              option: 'ListingWorkshopBearing',
              showTranslatedText: true,
            },
            {
              path: 'DatumderUmruestung',
              type: 'date',
            },
            {
              path: 'ManufactureRing',
              type: 'option',
              option: 'ListingHerstellerInnenring',
              optionType: 'ListingHersteller',
              showTranslatedText: true,
            },
            {
              path: 'YearOfConstructionRing',
              type: 'year',
            },
            {
              path: 'BearingType',
              type: 'option',
              option: 'BearingType',
              showTranslatedText: true,
            },
            {
              path: 'MaterialCase',
              type: 'option',
              option: 'ListingMaterial',
              showTranslatedText: true,
            },
          ],
        },
        bearingHousing: {
          listPath: 'BearingHousingCollection.BearingHousing',
          differentiator: 'Side',
          uniformPaths: ['BearingHousingType'],
          completeUniform: true,
          data: [
            {
              path: 'Side',
              type: 'option',
              name: 'side',
              option: 'Side',
              showTranslatedText: true,
            },
            {
              path: 'BearingHousingType',
              type: 'option',
              option: 'BearingHousingType',
              showTranslatedText: true,
            },
          ],
        },
      },
    },
    repairs: {
      chapters: {
        basedata: {
          data: [
            {
              path: 'Fertigungsstandard',
              type: 'option',
              option: 'ListEigentumsmerkmal',
              optionAdd: ['VPI-EMG'],
              optionType: 'AssemblyNorm',
            },
          ],
        },
        axle: {
          data: [
            {
              path: 'Axle.HasZfPA',
              type: 'boolean',
            },
            {
              path: 'Axle.MagneticPowderTestUnloadingHutches',
              type: 'option',
              option: 'TestResult',
              showTranslatedText: true,
            },
            {
              path: 'Axle.MtEntlastungsmuldenstempelung',
              type: 'year',
            },
            {
              path: 'Axle.MagneticPowderTestShaft',
              type: 'option',
              option: 'TestResult',
              showTranslatedText: true,
            },
            {
              path: 'Axle.MagneticPowderTestAxleStubA',
              type: 'option',
              option: 'TestResult',
              showTranslatedText: true,
            },
            {
              path: 'Axle.MagneticPowderTestAxleStubB',
              type: 'option',
              option: 'TestResult',
              showTranslatedText: true,
            },
            {
              path: 'Axle.MTPruefungsart',
              type: 'option',
              option: 'MTPruefungsart',
              showTranslatedText: true,
            },
            {
              path: 'Axle.DefectClassMarking',
              type: 'option',
              option: 'AxleDefectClass',
              showTranslatedText: true,
            },
            {
              path: 'Axle.UicDefectClass',
              type: 'option',
              option: 'AxleUicDefectClass',
              showTranslatedText: true,
            },
            {
              path: 'Axle.HasZfPS',
              type: 'boolean',
            },
            {
              path: 'Axle.UltraSonicTest',
              type: 'option',
              option: 'TestResult',
              showTranslatedText: true,
            },
          ],
        },
        paintwork: {
          listPath: 'Axle.CoatingAxleCollection.Paintwork',
          minSize: 0,
          maxSize: 1,
          differentiator: 'Art',
          data: [
            {
              path: 'Art',
              name: 'differentiator.Art',
              type: 'option',
              option: 'Art',
              showTranslatedText: true,
            },
            {
              path: 'AnstrichDurchgefeuhrt',
              type: 'boolean',
            },
            {
              path: 'Herstellerbezeichnung',
              type: 'option',
              option: 'ListingAnstrichHerstellerbezeichnung',
              showTranslatedText: true,
            },
            {
              path: 'Farbton',
              type: 'option',
              option: 'Farbton',
              showTranslatedText: true,
            },
          ],
        },
        wheel: {
          listPath: 'WheelCollection.Wheel',
          differentiator: 'Side',
          uniformPaths: [
            'UTRadsatzkranz',
            'Profilgedreht',
            'WheelProfileDenomination',
          ],
          fixedSet: true,
          data: [
            {
              path: 'UTRadsatzkranz',
              type: 'option',
              option: 'TestResult',
              showTranslatedText: true,
            },
            {
              path: 'MagneticPowderTestClumbEye',
              type: 'option',
              option: 'TestResult',
              showTranslatedText: true,
            },
            {
              path: 'InteralStressTest',
              type: 'double',
              negateable: true,
              unit: 'MPa',
            },
            {
              path: 'WheelMagneticParticleInspectionZfpE',
              type: 'option',
              option: 'TestResult',
              showTranslatedText: true,
            },
            {
              path: 'Profilgedreht',
              type: 'boolean',
            },
            {
              path: 'WheelProfileDenomination',
              type: 'option',
              option: 'WheelProfileDenomination',
              showTranslatedText: true,
            },
          ],
        },
        bearing: {
          listPath: 'BearingCollection.Bearing',
          differentiator: 'Position',
          differentiatorOption: 'BearingPosition',
          uniformPaths: [
            'LageruntersuchungDurchgefuehrt',
            'HasBearingAssembly',
            'Grease',
            'ChargeNumberGrease',
            'ManufacturingDateGrease',
          ],
          completeUniform: true,
          data: [
            {
              path: 'Position',
              type: 'option',
              option: 'BearingPosition',
              showTranslatedText: true,
            },
            {
              path: 'LageruntersuchungDurchgefuehrt',
              type: 'boolean',
            },
            {
              path: 'HasBearingAssembly',
              type: 'boolean',
            },
            {
              path: 'Grease',
              type: 'option',
              option: 'GreaseType',
              showTranslatedText: true,
            },
            {
              path: 'ChargeNumberGrease',
              type: 'string',
            },
            {
              path: 'ManufacturingDateGrease',
              type: 'date',
            },
          ],
        },
      },
    },
    audits: {
      data: [
        {
          path: 'WorkshopOfFirstInstallation',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DateWorkshopoffirstinstallation',
          type: 'date',
        },
        {
          path: 'WorkshopIS3',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteIS3',
          type: 'date',
        },
        {
          path: 'WorkshopIS2',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteIS2',
          type: 'date',
        },
        {
          path: 'WorkshopIS1',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteIS1',
          type: 'date',
        },
        {
          path: 'WorkshopLastD',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteD',
          type: 'date',
        },
        {
          path: 'WorkshopLastIL',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumletzteIL',
          type: 'date',
        },
        {
          path: 'WerkLetzte3L',
          type: 'option',
          option: 'Workshop',
          showTranslatedText: true,
        },
        {
          path: 'DatumLetzte3L',
          type: 'date',
        },
        {
          path: 'State',
          type: 'option',
          option: 'WheelsetCondition',
          showTranslatedText: true,
        },
      ],
    },
  },
};
export default values;
