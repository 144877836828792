const factors = [2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];

function removeSpacesAndHyphen(val) {
    val = val.trim();
    return val.replace(/\s+|-+/g, '');
}

export default {

    checkNumber(val) {
        if (val && val !== "") {
            val = removeSpacesAndHyphen(val);
            const input = val.split('').map(value => parseInt(value));
            if (input.length !== 12 || input.includes(NaN)) return false;
            const checkNumber = input.splice(11)[0];
            const calc = input.map(function (value, index) {
                let v = value * factors[index];
                return Math.floor(v / 10) + v % 10;
            });
            const calcCheckNumber = (10 - (calc.reduce((a, b) => a + b, 0) % 10)) % 10;
            if (checkNumber !== calcCheckNumber) return false;
        }

        return true;
    },

    formatCheckNumber(val) {
        if (val) {
            val = removeSpacesAndHyphen(val);
            val = val.replace(/(\d{2})(\d{2})(\d{4})(\d{3})(\d)$/, '$1 $2 $3 $4-$5')
        }

        return val;
    },
}