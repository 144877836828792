// https://vuex.vuejs.org/en/mutations.html
import userService from "../service/userService";
import {set} from '@/utils/vuex';

export default {
    onLanguageChanged(state, payload) {
        window.localStorage.setItem('vers-lang', payload.language);
        payload.$root.$i18n.locale = payload.language;
        state.language = payload.language;
        //this.axios.defaults.headers.common['Accept-Language'] = lang
        document.querySelector('html').setAttribute('lang', payload.language);
    },
    onTogglzUpdated(state, togglz) {
        window.localStorage.setItem('vers-togglz', JSON.stringify(togglz));
    },
    async login(state, loginData) {
        return await userService.login(state, loginData)
    },
    async logout(state) {
        await userService.logout(state)
        state.authenticated = false;
    },
    setProtocolMaintenance: set('protocolMaintenance'),
    setUser: set('user'),
    patchProtocolWorkshop(state, protocolWorkshop){
        state.protocolMaintenance = protocolWorkshop;
        state.user.workshop.addLastModifier = protocolWorkshop;
        window.localStorage.setItem('vers-user', JSON.stringify(state.user));
    },
    setAuthenticated: set('authenticated'),
    setDefaultOptions(state, defaultOptions){
        state.defaultOptions = defaultOptions;
        const contractors = defaultOptions.find(option => option.key === 'ListAuftraggeber')
        state.contractors = contractors ? contractors.values : [];
    },
    setLoading: set('loading'),
    onDefaultOptionsLoaded: set('defaultOptionsLoaded'),
    updateDefaultOptionOption(state, defaultOption) {
        const index = state.defaultOptions.findIndex(option => option.id === defaultOption.id)
        Object.assign(state.defaultOptions[index], defaultOption);
    }
}
