<template>
  <div>
    <v-alert v-model="show" :color="color" dense>
      <v-row align="center" dense>
        <v-col class="grow">
          {{ message }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="refresh"
                 :color='buttonColor'>
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
    export default {
      data () {
          return {
              show: false,
              color: 'warning',
              buttonColor: 'primary',
              buttonText: 'update',
              message: ''
          }
      },
      created: function () {
          this.$store.watch(state => state.alertbar.alert, () => {
              const msg = this.$store.state.alertbar.alert.message;
              if (msg !== '') {
                  this.show = true;
                  this.color= this.$store.state.alertbar.alert.color;
                  this.message = msg;
              }
          })
      },
      methods: {
          refresh() {
            window.location.reload()
          }
      }
    }
</script>