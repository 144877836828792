import inputValues from '../../../constants/order/inputValues';

function initTab(key) {
  return Object.keys(key).reduce((result, item) => {
    result[item] = 0;
    return result;
  }, {});
}

function fillDict(dict, listPath, tabName, data) {
  for (const line of data) {
    const path = listPath ? `${listPath}.${line.path}` : line.path;
    dict[path] = {
      name: line.name ? line.name : line.path,
      tabName,
    };
  }
  return dict;
}

function initDict(data) {
  let dict = {};
  for (const [key, value] of Object.entries(data)) {
    const tabName = key;
    if ('chapters' in value) {
      const outerListPath = value.listPath;
      for (const chapter of Object.values(value.chapters)) {
        const listPath =
          outerListPath && chapter.listPath
            ? `${outerListPath}.${chapter.listPath}`
            : outerListPath
            ? outerListPath
            : chapter.listPath;
        if ('chapters' in chapter) {
          for (const innerChapter of Object.values(chapter.chapters)) {
            const innerListPath =
              listPath && innerChapter.listPath
                ? `${listPath}.${innerChapter.listPath}`
                : listPath
                ? listPath
                : innerChapter.listPath;
            dict = fillDict(dict, innerListPath, tabName, innerChapter.data);
          }
        } else {
          dict = fillDict(dict, listPath, tabName, chapter.data);
        }
      }
    } else {
      const listPath = value.listPath;
      dict = fillDict(dict, listPath, tabName, value.data);
    }
  }
  return dict;
}

const initWagonTab = (() => {
  return initTab(inputValues.wagon);
})();

const initWheelsetTab = (() => {
  return initTab(inputValues.wheelset);
})();

const initWagonDict = (() => {
  return initDict(inputValues.wagon);
})();

const initWheelsetDict = (() => {
  return initDict(inputValues.wheelset);
})();

export const initNewOrder = () => ({
  created: new Date().toISOString().substr(0, 10),
  type: 'WAGON',
  number: null,
  wagonNumber: null,
  wheelsetNumber: null,
  contractor: null,
  inCreation: false,
  creationSuccess: false,
});
export const initWheelsetTemplates = () =>
  Array(4).fill({ number: null, file: null });
export const initTemplate = () => ({ file: null });

export default {
  orders: [],
  orderAssets: [],
  orderImages: {
    images: [],
  },
  currentTabIndex: 0,
  orderResults: [],
  order: null,
  orderResultSyncTime: null,
  orderUpdate: 0,
  lastOrderResult: null,
  previousOrderValue: null,
  loadlimitType: null,
  activeWheelsetsTab: 0,
  newOrder: initNewOrder,
  wagonTabs: initWagonTab,
  wheelsetTabs: initWheelsetTab,
  singleLimitGridKeys: [0],
  amountWheelsets: 4,
  wagonWheelsetTemplatesOutgoing: initWheelsetTemplates,
  editOrderWheelsetValidations: {},
  wagonTemplate: initTemplate,
  wheelsetTemplate: initTemplate,
  wagonPathNameDict: initWagonDict,
  wheelsetPathNameDict: initWheelsetDict,
};
