<template>
  <div>
    <edit-order-take-over-all-entries :inputLines="inputLines" />
    <edit-order-collection-input-container :inputLines="inputLines" :split-view="true" />
  </div>
</template>

<script>
    import inputValues from "../../constants/order/inputValues";

    export default {
        data() {
            return {
                inputLines: inputValues.wheelset.wheelMeasures
            }
        },
        name: "WheelsetWheelMeasures"
    }
</script>

<style scoped>

</style>