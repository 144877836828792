// https://vuex.vuejs.org/en/state.html
import userService from "../service/userService";
import LANGUAGES from "@/constants/languages";

const initLang = (() => {
  const storageLang = window.localStorage.getItem("vers-lang");
  if (storageLang) {
    return storageLang;
  }

  let navLang = window.navigator.language;
  if (navLang) {
    navLang = navLang.substring(0, 2).toLowerCase();

    if (LANGUAGES.includes[navLang]) return navLang;
  }

  return "de";
})();

export default {
  language: initLang,
  authenticated: userService.isAuthenticated(),
  logoutReason: undefined,
  roles: userService.getRolesFromToken(),
  loading: false,
  protocolMaintenance: false,
  user: {},
  contractors: [],
  defaultOptionsLoaded: 0,
  defaultOptions: [],
  frontVersionCurrent: "undefined",
  frontVersion: "undefined",
  backVersion: "undefined",
};
