<template>
  <v-app-bar
    id="core-toolbar"
    dense
    :color="this.$vuetify.theme.dark ? '#1e1e1e' : ''"
    flat
    app
    fixed
    v-if="$route.name !== 'Login'"
  >
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      @click.stop="toggleDrawer"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer />
    <v-toolbar-items floating>
      <div style="width: 200px">
        <core-scheduler />
        <div
          v-if="
            $vuetify.breakpoint.smAndUp &&
            orderResultSyncTime &&
            $route.name === 'EditOrder'
          "
          style="position: relative"
        >
          <span>{{ $t('app.currentState') }}</span>
          <span>
            {{ orderResultSyncTime | moment($t('format.datetime.simple')) }}
          </span>
          <span style="position: absolute; right: 0">
            <v-icon v-if="isNotInSync" color="yellow darken-2"
              >mdi-alert</v-icon
            >
            <v-icon v-else color="green darken-2">mdi-check-circle</v-icon>
          </span>
        </div>
      </div>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import CoreScheduler from './Scheduler';

export default {
  components: { CoreScheduler },
  data: () => ({
    online_status: 'online',
    title: null,
    responsive: false,
    responsiveInput: false,
  }),
  computed: {
    ...mapState('order', ['orderResultSyncTime']),
    isNotInSync() {
      const now = this.$moment();
      return now.diff(this.orderResultSyncTime, 'minutes');
    },
  },
  name: 'core-toolbar',
  watch: {
    $route(val) {
      let title = this.$t(`${val.name.toLowerCase()}.title`);
      if (this.$route.params.tabName) {
        title +=
          ' - ' +
          this.$t(
            `editorder.${this.$route.params.orderType}.tab.${this.$route.params.tabName}`
          );
      }
      this.title = title;
    },
  },
  methods: {
    ...mapMutations('app', ['toggleDrawer']),
  },
};
</script>
