<template>
      <div>
            <edit-order-take-over-all-entries :chapters="chapters"/>
            <edit-order-simple-input-container :inputLines="chapters.brake"/>
            <edit-order-chapter-frame chapter-name="brakeblockHolder">
                  <edit-order-simple-input-container :inputLines="chapters.brakeblockHolder"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="brakeblockShoe">
                  <edit-order-simple-input-container :inputLines="chapters.brakeblockShoe"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="brakeCylinders">
                  <edit-order-collection-input-container :inputLines="chapters.cylinder"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="airReservoir">
                  <edit-order-collection-input-container :inputLines="chapters.airReservoir"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="loadValve">
                  <edit-order-collection-input-container :inputLines="chapters.loadValve"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="relayValve">
                  <edit-order-simple-input-container :inputLines="chapters.relayValve"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="brakeValves">
                  <edit-order-collection-input-container :inputLines="chapters.valve"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="brakeRodAdjuster">
                  <edit-order-collection-input-container :inputLines="chapters.rodAdjuster"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="handBrake">
                  <edit-order-collection-input-container :inputLines="chapters.handBrake"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="detektor">
                  <edit-order-simple-input-container :inputLines="chapters.detektor"/>
            </edit-order-chapter-frame>
      </div>
</template>

<script>
import inputValues from "../../constants/order/inputValues";

export default {
      data() {
            return {
                  chapters: inputValues.wagon.brake.chapters
            }
      },
      name: "WagonBrake"
}
</script>

<style scoped>

</style>