<template>
  <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close" >
    <v-form v-model="valid" ref="form" @submit.prevent="save">

      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                    v-model="emailAddressObject.address"
                    :label="$t('emailAddress')"
                    :rules="[rules.required, rules.emailAddress]"
                    autofocus
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                  <helper-language-selection
                    :languageCode="emailAddressObject.languageCode"
                    @input="changeLanguageCode"
                  ></helper-language-selection>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>


        <v-card-actions>
          <v-spacer/>
          <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
          <v-btn color="blue darken-1" text type="submit" :disabled="invalid">{{ $t('app.btn.save') }}</v-btn>
        </v-card-actions>
      </v-card>

    </v-form>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import EMAIL_ADDRESS_CONSTRAINTS from "@/constants/email-address/constraints"

export default {
  name: "EditDialog",
  data() {
    return {
      dialog: false,
      newEmailObject: false,
      emailAddressObject: {
        address: null,
        languageCode: null
      },
      defaultEmailAddressObject: {
        address: null,
        languageCode: this.$store.state.language,
      },
      valid: false,
      rules: {
        required: value => !!value || this.$t('emailAddress.editDialog.rules.required'),
        emailAddress: value => EMAIL_ADDRESS_CONSTRAINTS.PATTERN.test(value) || this.$t('emailAddress.editDialog.rules.address'),
      }
    }
  },
  computed: {
    formTitle() {
      return this.newEmailObject ? this.$t('emailAddress.editDialog.new') : this.$t('emailAddress.editDialog.edit');
    },
    invalid() {
      return !this.emailAddressObject || !this.valid;
    },
  },
  methods: {
    changeLanguageCode(val) {
      this.emailAddressObject.languageCode = val;
    },
    open(emailAddressObject) {
      this.newEmailObject = (emailAddressObject == null);

      if (this.newEmailObject) {
        Object.assign(this.emailAddressObject, this.defaultEmailAddressObject);

      } else {
        Object.assign(this.emailAddressObject, emailAddressObject);
      }

      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        Object.assign(this.emailAddressObject, this.defaultEmailAddressObject);
      });
    },
    save() {
      this.$refs.form.validate();
      if (this.invalid) return;

      let address = this.emailAddressObject.address;

      // create new email
      if (this.newEmailObject) {
        this.createEmailAddress(this.emailAddressObject).then(success => {
          if (success) {
            this.onNotification({message: this.$t('emailAddress.editDialog.success.create.user', [address]), color: 'success'});

          } else {
            this.onNotification({message: this.$t('emailAddress.editDialog.error.create.user', [address]), color: 'error'});
          }
        });

        // update password
      } else {
        this.updateEmailAddress(this.emailAddressObject).then(success => {
          if (success) {
            this.onNotification({message: this.$t('emailAddress.editDialog.success.update.user', [address]), color: 'success'});

          } else {
            this.onNotification({message: this.$t('emailAddress.editDialog.error.update.user', [address]), color: 'error'});
          }
        });
      }

      this.close();
    },
    ...mapMutations({
      onNotification: 'snackbar/onNotification'
    }),
    ...mapActions({
      createEmailAddress: 'email-address/createEmailAddress',
      updateEmailAddress: 'email-address/updateEmailAddress',
    })
  }
}
</script>

<style scoped>

</style>