import api from './api';
import apiEndpoints from '@/constants/api/apiEndpoints';
import userService from '@/service/userService';

export default {
  async getWorkshopFromApi(workshopId) {
    return api()
      .get(`${apiEndpoints.workshop}/${workshopId}`)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  async getWorkshopIcon(workshopId) {
    return api()
      .get(`${apiEndpoints.workshop}/${workshopId}/icon`)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  async updateWorkshop(workshop) {
    return api()
      .put(`${apiEndpoints.workshop}/${workshop.id}`, workshop)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  async updateWorkshopIcon(workshopId, base64Image) {
    const cleanBase64 = base64Image.replace(/.*,/, '');
    return api()
      .put(`${apiEndpoints.workshop}/${workshopId}/icon`, cleanBase64, {
        headers: { 'Content-Type': 'text/plain' },
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  getCurrentWorkshopId() {
    const user = userService.getUser();
    if (user && user.workshop) {
      return user.workshop.id;
    }
    return null;
  },
  protocolMaintenance() {
    const user = userService.getUser();
    if (user && user.workshop) {
      return user.workshop.addLastModifier;
    }
    return false;
  },
};
