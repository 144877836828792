<template>
  <div>
    <v-row
        v-for="(item,index) in wheelsetTemplates"
        :key="index"
        no-gutters
        align="start"
    >
      <v-col cols="auto">
        <div class="grey--text mr-2 text--darken-3 v-text-field">{{ index + 1 }}.</div>
      </v-col>

      <v-col>
        <v-text-field
            :value="item.number"
            @change="updateNumberForTemplate(index, $event)"
            :loading="item.file && !item.validation"
            :error-messages="getErrorMessage(item)"
            :append-icon="getValidationIcon(item)"
            :label="$t('editorder.Wheelsetnumber')"
            :rules="[rules.required ]"
            filled
        >
        </v-text-field>
      </v-col>

      <v-col cols="auto">
        <v-file-input
            @change="validateImportFile({file: $event, type:'WHEELSET', templateStr, index, updateCallback: 'updateAddOrderWagonWheelsetTemplate'})"
            hide-input accept="application/xml" class="ml-2" />
      </v-col>

    </v-row>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
      name: "fileInput",
      props: {
            templateStr: String,
      },
      data() {
        return {
          rules: {
            required: value => !!value || this.$t('validation.required')
          }
        }
      },
      computed: {
            wheelsetTemplates() {
                  return this.$store.state.order[this.templateStr]
            },
      },
      methods: {
            getErrorMessage(template) {
                  if (template.validation && template.validation.validationStatus === 'INVALID') {
                        return this.$t(template.validation.messageKey)
                  }
                  return undefined
            },
            updateNumberForTemplate(index, number) {
                  this.wheelsetTemplates[index] = {number}
            },
            getValidationIcon(template) {
                  if (template.validation) {
                        switch (template.validation.validationStatus) {
                              case 'INVALID':
                                    return 'mdi-alert-circle';
                              case 'VALID':
                                    return 'mdi-check-circle';
                        }
                  }
                  return undefined
            },
            ...mapActions({
                  validateImportFile: 'order/validateImportFile'
            })
      }
}
</script>

<style scoped>

</style>