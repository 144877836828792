import jsonpath from 'jsonpath';

export default {
  getJsonPathKey(
    orderType,
    path,
    type,
    collectionPath,
    collectionIndex,
    prePath,
    isAbsolutePath
  ) {
    const jsonPathPrefix =
      orderType === 'wheelset'
        ? `$.Body.Radsatz.technicalWheelset${type}`
        : `$.Body.Wagen.technicalWaggon${type}`;
    if (isAbsolutePath) return `${jsonPathPrefix}.${path}`; // special case, where a field under a collection have an external path

    const collectionPrefix =
      collectionPath !== undefined
        ? `.${collectionPath}[${collectionIndex}]`
        : '';
    const pathPrefix = prePath !== undefined ? `.${prePath}` : '';

    return `${jsonPathPrefix}${pathPrefix}${collectionPrefix}.${path}`;
  },
  createObjectFromPath(jsonPathStr) {
    return jsonpath.parse(jsonPathStr).reduceRight((res, entry) => {
      if (entry.operation === 'member') {
        return { [entry.expression.value]: res };
      } else if (entry.operation === 'subscript') {
        const arr = [];
        arr[entry.expression.value] = res;
        return arr;
      }
      return res;
    }, {});
  },
};
