<template>
  <div>
    <edit-order-take-over-all-entries :chapters="chapters" />
    <edit-order-simple-input-container
      :inputLines="chapters.basedata"
      :full-width="true"
    />
  </div>
</template>

<script>
import inputValues from '../../constants/order/inputValues';

export default {
  data() {
    return {
      chapters: inputValues.wagon.mobileService.chapters,
    };
  },
  name: 'WagonMobileService',
};
</script>

<style scoped></style>
