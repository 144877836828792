<template>
  <v-row no-gutters>
    <v-col>
      <v-text-field
          :label="label"
          v-if="type === 'WHEELSET'"
          v-model="newOrder.wheelsetNumber"
          :loading="orderTemplate.file && !orderTemplate.validation"
          :error-messages="errorMessage"
          :key="orderTemplate.assetId"
          :append-icon="validationIcon"
          :rules="[rules.required]"
          filled
      />

      <v-text-field
          class="hint-text-style"
          ref="wagonNumberField"
          :label="label"
          v-if="type === 'WAGON'"
          v-model="newOrder.wagonNumber"
          :loading="orderTemplate.file && !orderTemplate.validation"
          :error-messages="errorMessage"
          :key="orderTemplate.assetId"
          :append-icon="validationIcon"
          :rules="[rules.required]"
          :hint="hintStatusText"
          :persistent-hint="true"
          @blur="tryCheckNumber"
          filled
      />
    </v-col>

    <v-col cols="auto">
      <v-file-input hide-input
                    ref="fileInput"
                    @change="validateImportFile({file: $event, type, templateStr: template, updateCallback: 'updateAddOrderTemplate'})"
                    accept="application/xml"
                    class="ml-2" />
    </v-col>
  </v-row>
</template>

<script>

import {mapActions, mapState} from "vuex";
import checkNumberService from "@/service/checkNumberService";

export default {
  name: "fileInput",
  props: {
    template: String,
    label: String,
    type: String,
    file: File
  },
  data() {
    return {
      rules: {
        required: value => !!value || this.$t('validation.required'),
      },
      incorrect: false
    }
  },
  computed: {
    ...mapState('order', ['newOrder']),
    errorMessage() {
      if (this.orderTemplate.validation && this.orderTemplate.validation.validationStatus === 'INVALID') {
        this.resetImportFile();
        return this.$t(this.orderTemplate.validation.messageKey)
      }
      return undefined
    },
    hintStatusText() {
      if(this.incorrect) {
        return this.$t('checksum.incorrect')
      } else {
        return ""
      }
    },
    validationIcon() {
      if (this.orderTemplate.validation) {
        switch (this.orderTemplate.validation.validationStatus) {
          case 'INVALID':
            return 'mdi-alert-circle';
          case 'VALID':
            return 'mdi-check-circle';
        }
      }
      return undefined
    },
    orderTemplate() {
      return this.$store.state.order[this.template];
    },
  },
  methods: {
      ...mapActions({
          validateImportFile: 'order/validateImportFile'
      }),
      tryCheckNumber() {
          this.incorrect = !checkNumberService.checkNumber(this.newOrder.wagonNumber);
          if (!this.incorrect) this.newOrder.wagonNumber = checkNumberService.formatCheckNumber(this.newOrder.wagonNumber);
      },
      resetImportFile() {
          this.$refs.fileInput.value = null;
      },
  },
  watch: {
    orderTemplate(template) {
       if(template.validation && template.validation.validationStatus === 'VALID') {
         this.tryCheckNumber();
      }
    }
  }
}
</script>

<style scoped>
  .hint-text-style >>> .v-messages__message {
    color: red
  }
</style>