<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('editorder.status.title') }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <p>
            {{ $t('editorder.status.current.label') }}
            {{ $t('editorder.status.label.' + order.status.toLowerCase()) }}
          </p>
          <v-radio-group v-model="radios">
            <v-radio
              v-for="radio in radioButtons"
              :key="radio"
              :label="$t('editorder.status.label.' + radio.toLowerCase())"
              :value="radio"
            ></v-radio>
          </v-radio-group>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="!radios">
          {{ $t('app.btn.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ORDER_STATUS,
  UPDATEABLE_ORDERSTATUS,
} from '@/constants/order/orderStatus'
import { mapActions, mapMutations, mapState } from 'vuex'
import ROLES from '@/constants/user/roles'

export default {
  computed: {
    ...mapState('order', ['order']),
  },
  data: () => ({
    dialog: false,
    radioButtons: [],
    radios: null,
    fab: false,
  }),
  methods: {
    ...mapActions({
      updateOrderStatus: 'order/updateOrderStatus',
    }),
    ...mapMutations({
      onNotification: 'snackbar/onNotification',
    }),
    openDialog() {
      this.updateStatusRadios()
      this.dialog = true
    },
    save() {
      this.updateOrderStatus({
        orderId: this.order.id,
        status: this.radios,
      }).then((success) => {
        if (success) {
          this.closeDialog()
          this.onNotification({
            message: this.$t('editorder.status.save.success'),
            color: 'success',
          })
        } else {
          this.onNotification({
            message: this.$t('editorder.status.save.error'),
            color: 'error',
          })
        }
      })
    },
    closeDialog() {
      this.dialog = false
    },
    updateStatusRadios() {
      this.radioButtons = UPDATEABLE_ORDERSTATUS.filter(
        (status) => status !== this.order.status
      )
      if (
        !(
          this.$store.getters.hasRole(ROLES.ROLE_WORKSHOP_WAGON_QA) &&
          this.order.type === 'WAGON'
        ) &&
        !(
          this.$store.getters.hasRole(ROLES.ROLE_WORKSHOP_WHEELSET_QA) &&
          this.order.type === 'WHEELSET'
        )
      ) {
        this.radioButtons = this.radioButtons.filter(
          (status) => status !== ORDER_STATUS.DONE_ARCHIVED
        )
      }
      if (
        !(
          this.order.type === 'WAGON' &&
          this.$store.getters.hasAnyRole([
            ROLES.ROLE_WORKSHOP_ADMIN,
            ROLES.ROLE_WORKSHOP_WAGON_QA,
          ])
        ) &&
        !(
          this.order.type === 'WHEELSET' &&
          this.$store.getters.hasAnyRole([
            ROLES.ROLE_WORKSHOP_ADMIN,
            ROLES.ROLE_WORKSHOP_WHEELSET_QA,
          ])
        )
      ) {
        this.radioButtons = [ORDER_STATUS.IN_QA]
      }
      this.radios = this.radioButtons.length > 0 ? this.radioButtons[0] : null
    },
  },
}
</script>
