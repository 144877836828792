import orderService from "../../../service/orderService";
import Vue from "vue";
import {set} from '@/utils/vuex';
import {initNewOrder, initTemplate, initWheelsetTemplates} from "./state";

export default {
    async getOrdersFromDb(state) {
        state.orders = [];
        const orders = await orderService.getOrdersFromDb();
        orders.forEach(c => {
            state.orders.push(c);
        });
    },
    setOrderImages: set('orderImages'),
    changeCurrentTabIndex: set('currentTabIndex'),
    onOrderResultsChanged: set('orderResults'),
    onPreviousOrderValue: set('previousOrderValue'),
    onLastOrderResult: set('lastOrderResult'),
    setOrderAssets: set('orderAssets'),
    addOrderAssets(state, assets) {
        state.orderAssets.push(...assets);
    },
    replaceOrderAssets(state, assets) {
        for (const asset of assets) {
            const index = state.orderAssets.findIndex(a => a.id === asset.id);
            state.orderAssets.splice(index, 1, asset);
        }
    },
    replaceOrderAssetsWithSameName(state, assets) {
        for (const asset of assets) {
            const index = state.orderAssets.findIndex(a => a.filename === asset.filename);
            if (index >= 0) {
                state.orderAssets.splice(index, 1, asset);
            } else {
                state.orderAssets.push(asset)
            }
        }
    },
    getLastOrderResult(state, key) {
        return state.orderResults.filter(result => result.key === key).slice(-1)[0]
    },
    incrementAddOrderWheelsets(state) {
        if (state.amountWheelsets < 8) {
            state.amountWheelsets = ++state.amountWheelsets;
            state.wagonWheelsetTemplatesOutgoing.push({number: null, file: null})
        }
    },
    decrementAddOrderWheelsets(state) {
        if (state.amountWheelsets > 2) {
            state.amountWheelsets = --state.amountWheelsets;
            state.wagonWheelsetTemplatesOutgoing.pop()
        }
    },
    updateAddOrderTemplate(state, template) {
        const valid = template.validation && template.validation.validationStatus === 'VALID';
        state[template.templateStr] = template;
        if (valid) {
            if (template.templateStr === 'wagonTemplate') {
                const wheelsetTemplates = template.validation.subNumbers.map(number => ({
                    number,
                    file: null
                }));
                state.wagonWheelsetTemplatesOutgoing = wheelsetTemplates
                state.amountWheelsets = wheelsetTemplates.length
                state.newOrder.wagonNumber = template.validation.number;
            } else {
                state.newOrder.wheelsetNumber = template.validation.number;
            }
        }
    },
    updateAddOrderWagonWheelsetTemplate(state, template) {
        state[template.templateStr] = state[template.templateStr]
            .map((item, idx) => {
                if (template.index === idx) {
                    if (template.validation) {
                        template.number = template.validation.number;
                    }
                    return template
                }
                return item;
            });
    },

    initNewOrder(state) {
        state.newOrder = initNewOrder()
        state.wagonWheelsetTemplatesOutgoing = initWheelsetTemplates()
        state.amountWheelsets = 4;
        state.wagonTemplate = initTemplate()
        state.wheelsetTemplate = initTemplate()
    },
    setCurrentLoadLimitGridType(state, val) {
        state.singleLimitGridKeys = val === 'Klapptafeln' ? undefined : [0];
    },
    updateLoadLimitType: set('loadlimitType'),
    updateEditWagonWheelset(state, template) {
        Vue.set(state.editOrderWheelsetValidations, `${template.wagonType}${template.index}`, template)
    },
    async onInitEditOrder(state, {orderId, orderType}) {
        await orderService.updateOrderProgress(state, {orderType, orderId})
    },
    async onSendOrderResults(state, {orderId}) {
        state.orderResults = await orderService.getOrderResultsByOrderIdFromDb(orderId);
    },
    orderResultSyncTime: set('orderResultSyncTime'),
    onOrderUpdate(state, {order, reloadView = false}) {
        state.order = order;
        if(reloadView) {
            state.orderUpdate += 1;
        }
    },
    setActiveWheelsetsTab: set('activeWheelsetsTab'),
    updateNewOrder: set('order'),
    updateOrders: set('orders'),
    setOrderStatus(state, status) {
        state.order.status = status;
    }
}
