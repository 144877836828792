<template>
  <v-footer app style="z-index: 0" inset v-if="order" outlined>
    <div class="justify-end" style="vertical-align:bottom">
      <span class="mr-2">
        <icons-base style="vertical-align:bottom" icon-name="wagon" v-if="order.type === 'WAGON'" iconColor="#757575" ><icons-wagon-icon-compressed/></icons-base>
        <icons-base style="vertical-align:bottom" icon-name="wheelset" v-if="order.type === 'WHEELSET'" iconColor="#757575" ><icons-wheelset-icon/></icons-base>
      </span>
    <span class="fill-height mr-2" style="vertical-align:bottom">
    </span>
      <span class="mr-2 grey--text text--darken-2">{{
          order.maintenanceNumber
        }}, {{ $t('editorder.status.current.label') }} {{
          $t('editorder.status.label.' + order.status.toLowerCase())
        }}</span>
      <span>
        <v-icon v-if="
order.status === ORDER_STATUS.IN_WORK" color="grey darken-1">mdi-wrench-outline</v-icon>
        <v-icon
            v-if="order.status === ORDER_STATUS.IN_QA" color="grey darken-1">mdi-account-multiple-check-outline</v-icon>
        <v-icon
            v-if="order.status === ORDER_STATUS.DONE || order.status === ORDER_STATUS.DONE_ARCHIVED" color="grey darken-1">mdi-shield-check-outline</v-icon>
      </span>
    </div>
  </v-footer>
</template>

<script>
// Utilities
import {ORDER_STATUS} from '@/constants/order/orderStatus';

export default {
  props: ['order'],
  data() {
    return {
      ORDER_STATUS: ORDER_STATUS
    }
  }
}
</script>