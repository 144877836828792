<template>
  <div>
    <edit-order-take-over-all-entries :inputLines="inputLines" />
    <edit-order-simple-input-container :inputLines="inputLines" />
  </div>
</template>

<script>
    import inputValues from "../../constants/order/inputValues";

    export default {
        data() {
            return {
                inputLines: inputValues.wheelset.axleMeasures
            }
        },
        name: "WheelsetAxleMeasures"
    }
</script>

<style scoped>

</style>