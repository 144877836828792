<template>
      <div>
            <edit-order-take-over-all-entries :chapters="chapters"/>
            <edit-order-simple-input-container :inputLines="chapters.basedata"/>
            <edit-order-simple-input-container :inputLines="chapters.additional" :full-width="true"/>
            <edit-order-collection-input-container v-if="!moreThanTwoAxes" :inputLines="chapters.baseBogies" />
            <edit-order-chapter-frame v-if="moreThanTwoAxes" chapter-name="bogies">
                  <edit-order-collection-input-container :inputLines="chapters.bogies" />
            </edit-order-chapter-frame>
      </div>
</template>

<script>
import inputValues from "../../constants/order/inputValues";
import {mapGetters} from "vuex";

export default {
      data() {
            return {
                  chapters: inputValues.wagon.basedata.chapters
            }
      },
      name: "WagonBasedata",
      computed: {
        ...mapGetters('order', ['getAxesNumber']),
        moreThanTwoAxes() {
          return this.getAxesNumber > 2
        }
      },
}
</script>

<style scoped>

</style>