// The mirror to the OrderStatus.kt of the backend
const ORDER_STATUS = {
    IN_CREATION: 'IN_CREATION',
    CREATED: 'CREATED',
    IN_WORK: 'IN_WORK',
    IN_QA: 'IN_QA',
    DONE: 'DONE',
    DONE_ARCHIVED: 'DONE_ARCHIVED',

};
const UPDATEABLE_ORDERSTATUS = [ORDER_STATUS.IN_WORK, ORDER_STATUS.IN_QA, ORDER_STATUS.DONE, ORDER_STATUS.DONE_ARCHIVED]
export {ORDER_STATUS, UPDATEABLE_ORDERSTATUS};
