<template>
  <div>
    <edit-order-take-over-all-entries :chapters="chapters" />
    <edit-order-chapter-frame chapter-name="climbingProtection">
      <edit-order-simple-input-container
        :inputLines="chapters.climbingProtection"
      />
    </edit-order-chapter-frame>
    <edit-order-chapter-frame chapter-name="buffer">
      <edit-order-collection-input-container :inputLines="chapters.buffer" />
    </edit-order-chapter-frame>
    <edit-order-chapter-frame chapter-name="couplingRod">
      <edit-order-collection-input-container
        :inputLines="chapters.couplingRod"
      />
    </edit-order-chapter-frame>
    <edit-order-chapter-frame chapter-name="coupling">
      <edit-order-collection-input-container :inputLines="chapters.coupling" />
    </edit-order-chapter-frame>
    <edit-order-chapter-frame chapter-name="drawHook">
      <edit-order-collection-input-container :inputLines="chapters.drawHook" />
    </edit-order-chapter-frame>
    <edit-order-chapter-frame chapter-name="drawGear">
      <edit-order-collection-input-container
        :inputLines="chapters.drawGearUniform"
      />
      <edit-order-collection-input-container
        :inputLines="chapters.drawGearSingle"
      />
    </edit-order-chapter-frame>
  </div>
</template>

<script>
import inputValues from '../../constants/order/inputValues';

export default {
  data() {
    return {
      chapters: inputValues.wagon.drawAndBufferingGear.chapters,
    };
  },
  name: 'WagonDrawAndBufferingGear',
};
</script>

<style scoped></style>
