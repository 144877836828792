<template>
      <div>
        <edit-order-take-over-all-entries :chapters="{ chapters: { ...supertructures } }"/>
          <v-col>
            <v-row>
              <v-tabs v-model="activeTab">
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                    v-for="(collectionKey, collectionIndex) in collectionKeys"
                    :key="'#'+collectionKey+'-'+collectionIndex"
                >
                  {{ getCardTitle(collectionKey) }}
                </v-tab>
                <core-add-differentiator-list-dialog
                    :in-use="collectionKeys"
                    :values="defaultOptionValues"
                    :disabled="disabled"
                    :diff-name="getDifferentiator()"
                    :max-size="supertructures.maxSize"
                    @add="addEntry"
                />
                <core-add-differentiator-list-dialog
                    :in-use="collectionKeys"
                    :values="defaultOptionValues"
                    :disabled="disabled"
                    :diff-name="getDifferentiator()"
                    :deletion="true"
                    :min-size="supertructures.minSize"
                    @add="removeEntry"
                />
              </v-tabs>
              <v-col md="12" class="mx-0 px-0 mb-4">
                <v-tabs-items v-model="activeTab">
                  <v-tab-item
                      v-for="(collectionKey, index) in collectionKeys"
                      :transition="false"
                      :reverse-transition="false"
                      :key="'#wheelset-'+index"
                  >
                  <v-col class="my-1 px-1 pb-2 pt-0">
                      <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.basedata"
                            :pathPrefix="getPathPrefix(index)"/>

                      <edit-order-chapter-frame chapter-name='tank' v-if="inSelection([$t('editorder.tank')], index)">
                        <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.tank"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                      <edit-order-chapter-frame chapter-name='staubgut' v-if="inSelection([$t('editorder.staubgut')], index)">
                        <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.staubgut"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                      <edit-order-chapter-frame chapter-name='fluidisation' v-if="inSelection([$t('editorder.staubgut')], index)">
                        <edit-order-collection-input-container
                            :inputLines="supertructures.chapters.fluidisation"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                      <edit-order-chapter-frame chapter-name='schuettgut' v-if="inSelection([$t('editorder.schuettgut')], index)">
                        <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.schuettgut"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                      <edit-order-chapter-frame chapter-name='flatwagon' v-if="inSelection([$t('editorder.flatwagon')], index)">
                        <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.flatwagon"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                      <edit-order-chapter-frame chapter-name='equipmentFlachwagen' v-if="inSelection([$t('editorder.flatwagon')], index)">
                        <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.equipmentFlachwagen"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                      <edit-order-chapter-frame chapter-name='autoTransporter' v-if="inSelection([$t('editorder.autoTransporter')], index)">
                        <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.autoTransporter"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                      <edit-order-chapter-frame chapter-name='box' v-if="inSelection([$t('editorder.box')], index)">
                        <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.box"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                      <edit-order-chapter-frame chapter-name='manhole' v-if="inSelection([$t('editorder.tank'), $t('editorder.staubgut')], index)">
                        <edit-order-simple-input-container
                            :inputLines="supertructures.chapters.manhole"
                            :pathPrefix="getPathPrefix(index)"/>
                      </edit-order-chapter-frame>
                  </v-col>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-col>
      </div>
</template>

<script>
    import inputValues from "../../constants/order/inputValues";
    import {mapActions, mapState} from "vuex";
    import {ORDER_STATUS} from "@/constants/order/orderStatus";
    import orderService from "@/service/orderService";

    export default {
        computed: {
          ...mapState('order', ['order', 'orderUpdate']),
          disabled() {
            return this.order.status === ORDER_STATUS.DONE
          },
          defaultOptionValues() {
            if (this.supertructures.differentiator) {
              const optionType = this.supertructures.differentiatorOption || this.supertructures.differentiator;
              const defaultOption = this.$store.getters.getDefaultOptionByKey(optionType);
              if (defaultOption) {
                return defaultOption.values;
              }
            }
            return [];
          },
        },
        methods: {
          inSelection(chapterName, index) {
            return this.superstructureType[index] === this.groupAll || chapterName.includes(this.superstructureType[index]);
          },
          addEntry(selected) {
            const params = {
              order: this.order,
              path: this.supertructures.listPath,
              index: this.collectionKeys.length,
              differentiator: this.supertructures.differentiator,
              prePath: this.pathPrefix,
              differentiatorOption: this.supertructures.differentiatorOption || this.supertructures.differentiator,
              value: selected.value,
              uniformPaths: this.supertructures.uniformPaths,
              dependentPaths: this.supertructures.dependentPaths,
              reloadView: true
            }
            this.addDifferentiatorEntry(params).then(() => {
              this.resolveKeys();
            })
          },
          removeEntry(selected) {
            //"inital values" are Integer, "added values" are Strings
            const index = this.collectionKeys.indexOf(selected.value) != -1
                ? this.collectionKeys.indexOf(selected.value)
                : this.collectionKeys.indexOf(parseInt(selected.value));
            const params = {
              order: this.order,
              path: this.supertructures.listPath,
              index: index,
              differentiator: this.supertructures.differentiator,
              prePath: this.pathPrefix,
              differentiatorOption: this.supertructures.differentiatorOption || this.supertructures.differentiator,
              value: "",
              dependentPaths: this.supertructures.dependentPaths,
              collectionSize: this.collectionKeys.length,
              reloadView: true
            }
            this.removeDifferentiatorEntry(params).then(() => {
              this.resolveKeys();
            })
          },
          getPathPrefix(index) {
            return `${this.supertructures.listPath}[${index}]`
          },
          getDifferentiator() {
            const diffName = this.supertructures.differentiatorName || this.supertructures.differentiator;
            return this.$t('editorder.differentiator.' + diffName)
          },
          getCardTitle(collectionName) {
            return this.getDifferentiator() + ' ' + collectionName
          },
          resolveKeys() {
            const params = {
              order: this.order,
              path: this.supertructures.listPath,
              type: 'Incoming',
              prePath: this.pathPrefix,
              differentiator: this.supertructures.differentiator
            }
            this.collectionKeys = orderService.resolveDifferentiatorValues(params);
          },
          ...mapActions({
                addDifferentiatorEntry: 'order/addDifferentiatorEntry',
                removeDifferentiatorEntry: 'order/removeDifferentiatorEntry'
              }
          ),
          getSuperstructureType() {
            const structures = this.order?.Body?.Wagen?.technicalWaggonOutgoing?.SuperstructureCollection?.Superstructure
            if(structures) {
              return structures.map(s => s.SuperstructureType?.value)
            }
            return [this.$t('editorder.all')]
          }
        },
        watch: {
          'orderUpdate': function() {
            this.resolveKeys();
            this.superstructureType = this.getSuperstructureType()
          }
        },
        data() {
            return {
              supertructures: inputValues.wagon.superstructure,
              collectionKeys: [],
              activeTab: 0,
              groupAll: this.$t('editorder.all'),
              groupSelection: this.$t('editorder.all'),
              superstructureType: [],
            }
        },
        mounted() {
          this.resolveKeys();
          this.superstructureType = this.getSuperstructureType()
        },
        name: "WagonTank"
    }
</script>

<style scoped>

</style>