<template>
  <v-dialog
      ref="diffDialog"
      v-model="show"
      v-if="visible"
      width="400px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary"
             v-bind="attrs"
             v-on="on"
             :disabled="disabled"
             class="my-2 mx-2"
      >
        <v-icon class="mr-0 pr-0" v-if="deletion">mdi-delete</v-icon>
        <v-icon class="mr-0 pr-0" v-else>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t('app.selection', [diffName]) }}</v-card-title>
      <v-card-text style="height: 300px;" class="ma-0 pa-0">
        <v-responsive
            class="overflow-y-auto"
            max-height="300">
          <v-data-table
              :headers="headers"
              :items="options"
              item-key="value"
              :single-select="true"
              v-model="selected"
              @click:row="changeSelectedValue"
              disable-pagination
              hide-default-header
              :item-class= "row_classes"
              sort-by="text"
              mobile-breakpoint="0"
              hide-default-footer
              class="elevation-1 ma-0 pa-0"
          >
          </v-data-table>
        </v-responsive>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="cancel">{{ $t('app.btn.cancel') }}</v-btn>
        <v-btn color="blue darken-1" text @click="handleOk">{{ deletion ? $t('app.btn.delete') : $t('app.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    values: {
      type: Array,
    },
    diffName: {
      type: String
    },
    inUse: {
      type: Array
    },
    disabled: {
      type: Boolean
    },
    deletion: {
      type: Boolean,
      default: false
    },
    minSize: {
      type: Number,
      default: 0
    },
    maxSize: {
      type: Number,
      default: Number.MAX_VALUE
    }
  },
  computed:{
    options(){
      if(this.deletion) {
        return this.values.filter(val => this.inUse.find(v => v == val.value));
      } else {
        return this.values.filter(val => !this.inUse.find(v => v == val.value));
      }
    },
    visible() {
      if(this.deletion) {
        return this.inUse.length > 0 && this.inUse.length > this.minSize ;
      } else {
        return this.inUse.length < this.values.length && this.inUse.length < this.maxSize;
      }
    }
  },
  methods: {
    cancel() {
      this.show = false;
    },
    row_classes(item) {
      if (this.selected.includes(item.value)) {
        return "primary"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    changeSelectedValue(item) {
      this.selectedValue = item
      this.selected = [item.value];
    },
    handleOk() {
      this.show = false;
      if (this.selectedValue) {
        this.$emit('add', this.selectedValue)
      }
    }
  },
  data() {
    return {
      selected: [],
      selectedValue: null,
      headers: [{value: 'text'}],
      show: false,
    }
  },
}
</script>