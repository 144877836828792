import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n)

function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    });
    return messages;
}

function getLocale() {
    let localStorageLanguage = window.localStorage.getItem('vers-lang');
    const navLang = window.navigator.language;
    return localStorageLanguage || navLang.substring(0,2) || process.env.VUE_APP_I18N_LOCALE || 'de';
}

export default new VueI18n({
    locale: getLocale(),
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    fallbackLocale: 'de',
    messages: loadLocaleMessages()
})