const {A, B, B1, B2, C, C2, C3, C4, D, D2, D3, D4, E, E4, E5} = {
    A: 'A',
    B: 'B',
    B1: 'B1',
    B2: 'B2',
    C: 'C',
    C2: 'C2',
    C3: 'C3',
    C4: 'C4',
    D: 'D',
    D2: 'D2',
    D3: 'D3',
    D4: 'D4',
    E: 'E',
    E4: 'E4',
    E5: 'E5',
}
const loadLimitGridTypes = {
    1: {
        100: [[A], [B], [C], [D]],
        120: [[A, B, C, D]],
    },
    2: {
        100: [[A], [B], [C], [D]],
        120: [[A], [B], [C, D]],
    },
    3: {
        100: [[A], [B1], [B2], [C]],
        120: [[A, B1, B2, C]],
    },
    4: {
        100: [[A], [B], [C]],
        120: [[A, B, C]],
    },
    5: {
        100: [[A, B, C]],
        120: [[A, B, C]],
    },
    6: {
        100: [[A, B, C]],
        120: [[A, B, C]],
    },
    7: {
        100: [[A, B1], [B2, C]],
        120: [[A, B1], [B2, C]],
    },
    8: {
        100: [[A], [B1], [B2], [C2], [C3, C4]],
        120: [[A, B1, B2, C2, C3, C4]],
    },
    9: {
        100: [[A], [B1], [B2], [C], [D2], [D3, D4]],
        120: [[A, B1, B2, C, D2, D3, D4]],
    },
    10: {
        100: [[A, B1], [B2], [C2], [C3, C4], [D2], [D3], [D4]],
        120: [[A, B1, B2, C2, C3, C4, D2, D3, D4]],
    },
    11: {
        100: [[A], [B1], [B2], [C], [D2], [D3, D4]],
        120: [[A], [B1], [B2], [C, D2, D3, D4]],
    },
    12: {
        100: [[A], [B, C]],
        120: [[A, B, C]],
    },
    13: {
        100: [[A], [B, C]],
        120: [[A], [B, C]],
    },
    14: {
        100: [[A, B1], [B2], [C2], [C3, C4], [D2], [D3], [D4]],
        120: [[A, B1], [B2], [C2], [C3, C4], [D2], [D3, D4]],
    },
    15: {
        100: [[A], [B], [C]],
        120: [[A], [B], [C]],
    },
    16: {
        100: [[A, B1], [B2], [C2, D2], [C3, C4], [D3, D4]],
        120: [[A, B1], [B2], [C2, D2, C3, C4, D3, D4]],
    },
    17: {
        100: [[A], [B1], [B2], [C2, D2], [C3, C4], [D3, D4]],
        120: [[A], [B1], [B2], [C2, D2, C3, C4, D3, D4]],
    },
    18: {
        100: [[A, B1], [B2], [C2, D2], [C3, C4], [D3], [D4]],
        120: [[A, B1], [B2], [C2, D2], [C3, C4, D3, D4]],
    },
    19: {
        100: [[A, B1], [B2], [C2, D2], [C3, D3], [C4], [D4]],
        120: [[A, B1], [B2], [C2, D2], [C3, D3, C4, D4]],
    },
    20: {
        100: [[A, B1], [B2, C2], [C3, C4], [D2], [D3], [D4]],
        120: [[A, B1], [B2, C2], [C3, C4, D2, D3, D4]],
    },
    21: {
        100: [[A, B1], [B2, C2], [C3, D3], [C4], [D2], [D4]],
        120: [[A, B1], [B2, C2], [C3, D3], [C4, D2, D4]],
    },
    22: {
        100: [[A, B1], [B2], [C2, D2], [C3], [C4], [D3], [D4]],
        120: [[A, B1], [B2], [C2, D2], [C3, C4, D3, D4]],
    },
    23: {
        100: [[A, B1], [B2, C2], [C3], [C4], [D2], [D3], [D4]],
        120: [[A, B1], [B2, C2], [C3, C4, D2, D3, D4]],
    },
    24: {
        100: [[A], [B], [C], [D], [E]],
        120: [[A], [B], [C, D, E]],
    },
    25: {
        100: [[A], [B1], [B2], [C], [D], [E]],
        120: [[A], [B1], [B2], [C, D, E]],
    },
    26: {
        100: [[A, B1], [B2], [C2, D2], [C3, C4], [D3, D4], [E]],
        120: [[A, B1], [B2], [C2, D2, C3, C4, D3, D4, E]],
    },
    27: {
        100: [[A], [B1], [B2], [C2, D2], [C3, C4], [D3, D4], [E]],
        120: [[A], [B1], [B2], [C2, D2, C3, C4, D3, D4], [E]],
    },
    28: {
        100: [[A, B1], [B2], [C], [D2], [D3, D4]],
        120: [[A, B1], [B2], [C, D2, D3, D4]],
    },
    29: {
        100: [[A, B1], [B2, C2], [C3, D3], [C4], [D2], [D4, E4], [E5]],
        120: [[A, B1], [B2, C2], [C3, D3], [C4, D2, D4, E4, E5]],
    },
    30: {
        100: [[A, B1], [B2], [C2, D2], [C3, D3], [C4], [D4], [E4], [E5]],
        120: [[A, B1], [B2], [C2, D2], [C3, D3, C4, D4, E4, E5]],
    },
    31: {
        100: [[A, B1], [B2], [C2, D2], [C3], [C4], [D3], [D4], [E4], [E5]],
        120: [[A, B1], [B2], [C2, D2], [C3, C4, D3, D4, E4, E5]],
    },
    32: {
        100: [[A, B1], [B2, C2], [C3], [C4], [D2], [D3], [D4], [E4], [E5]],
        120: [[A, B1], [B2, C2], [C3, C4, D2, D3, D4, E4, E5]],
    },
    33: {
        100: [[A], [B1], [B2, C]],
        120: [[A], [B1], [B2, C]],
    },
    34: {
        100: [[A, B1], [B2], [C]],
        120: [[A, B1], [B2], [C]],
    },
    35: {
        100: [[A, B1], [B2, C2], [C3, C4]],
        120: [[A, B1], [B2, C2], [C3, C4]],
    },
    36: {
        100: [[A], [B1], [B2], [C]],
        120: [[A], [B1], [B2], [C]],
    },
    37: {
        100: [[A, B1], [B2], [C2], [C3, C4]],
        120: [[A, B1], [B2], [C2], [C3, C4]],
    },
    38: {
        100: [[A, B1], [B2, C2], [C3], [C4]],
        120: [[A, B1], [B2, C2], [C3], [C4]],
    },
    39: {
        100: [[A], [B1], [B2], [C], [D]],
        120: [[A], [B1], [B2], [C, D]],
    },
};
export default loadLimitGridTypes;
