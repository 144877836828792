<template>
  <div>
    <v-text-field
        ref="textInput"
        class="ma-0 pb-0"
        @blur="sendValue"
        hide-details
        v-model="currentValue"
        :label="$t('editorder.data'+type)"
        validate-on-blur
        type="text"
        :disabled="conf.type === 'formula' || disabled"
        @click:append="negateInput"
        v-if="conf.type === 'string' || conf.type === 'baseString' || conf.type === 'formula'"
        :clearable="!isDifferentiator"
        @click:clear="removeValue"
        :error="incorrect"
    ></v-text-field>
    <v-text-field
        ref="numberInput"
        class="ma-0 pb-0"
        @blur="sendValue"
        hide-details
        v-model="currentValue"
        :label="$t('editorder.data'+type)"
        validate-on-blur
        :disabled="disabled"
        @keypress="preventNonNumericalInput"
        :append-icon="conf.negateable ? 'mdi-plus-minus-variant' : ''"
        @click:append="negateInput"
        v-if="conf.type === 'double'"
        :clearable="!isDifferentiator"
        @click:clear="removeValue"
    ></v-text-field>
    <v-combobox
        ref="combobox"
        hide-details
        class="ma-0 pb-0"
        :auto-select-first="true"
        :multiple="conf.type === 'multioption'"
        v-if="conf.type === 'option' || conf.type === 'multioption'"
        :label="$t('editorder.data'+type)"
        @update:search-input="updateSearchInput"
        @change="sendValueComboSingle"
        @blur="sendValueComboMulti"
        @keypress="preventComboNonNumericalInput"
        v-model="currentValue"
        @keydown.tab="handleComboTab"
        :items="defaultOptionValues"
        :item-value="conf.type !== 'multioption' ? 'id' : 'value'"
        validate-on-blur
        :hide-selected="conf.type !== 'multioption'"
        :disabled="disabled"
        :clearable="!isDifferentiator"
        @click:clear="lateBlurCombobox"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('editorder.list.nodata') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
    <v-dialog
        ref="dateDialog"
        v-model="dateMenuOpen"
        v-if="conf.type === 'date'"
        :disabled="disabled"
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            ref="dateInput"
            :value="dateFormatted"
            hide-details
            :label="$t('editorder.data'+type)"
            readonly
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
            :clearable="!isDifferentiator"
            @click:clear="removeDate"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="currentValue"
          no-title
          :locale="language"
          ref="datePicker"
          @input="selectDate"
          :disabled="disabled"
      ></v-date-picker>
    </v-dialog>
    <v-dialog
        ref="monthDialog"
        v-model="monthMenuOpen"
        v-if="conf.type === 'monthAndYear'"
        :disabled="disabled"
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            ref="monthInput"
            :value="monthFormatted"
            hide-details
            :label="$t('editorder.data'+type)"
            readonly
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
            :clearable="!isDifferentiator"
            @click:clear="removeMonth"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="currentValue"
          no-title
          :locale="language"
          ref="monthPicker"
          type="month"
          @input="selectMonth"
          :disabled="disabled"
      ></v-date-picker>
    </v-dialog>
    <v-dialog
        ref="yearDialog"
        v-model="yearMenuOpen"
        v-if="conf.type === 'year'"
        :disabled="disabled"
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            ref="yearInput"
            :value="yearFormatted"
            :label="$t('editorder.data'+type)"
            readonly
            hide-details
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
            :clearable="!isDifferentiator"
            @click:clear="removeYear"
        ></v-text-field>
      </template>
      <v-date-picker
          ref="yearPicker"
          v-model="currentValue"
          reactive
          no-title
          @click:year="selectYear"
          :disabled="disabled"
      ></v-date-picker>
    </v-dialog>
    <div v-if="conf.type === 'boolean'" class="ma-0">
      <v-checkbox
          @change="sendValue"
          v-model="currentValue"
          hide-details
          :label="booleanLabel"
          :disabled="disabled"
      >
      </v-checkbox>
    </div>
    <v-textarea
        v-if="conf.type === 'textarea'"
        @blur="sendValue"
        v-model="currentValue"
        hide-details
        filled
        :disabled="disabled"
    ></v-textarea>
    <div v-if="captureDate" class="text-left text-caption font-weight-light">
      <div class="last-modified mr-2">{{ captureDate | moment($t('format.datetime.simple')) }}</div>
      <div class="last-modifier">{{ lastModifier }}</div>
    </div>

  </div>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import checkNumberService from "@/service/checkNumberService"

export default {
  name: "InputComponent",
  props: {
    conf: Object,
    isDifferentiator: Boolean,
    useSelfCheckNumber: Boolean,
    type: String,
    disabled: Boolean,
    data: {
      type: [Object, Array, String],
    }
  },
  data: () => ({
    dateMenuOpen: false,
    monthMenuOpen: false,
    yearMenuOpen: false,
    currentValue: null,
    previousValue: null,
    comboSearchInput: null,
    dateFormatted: null,
    monthFormatted: null,
    yearFormatted: null,
    incorrect: false
  }),
  methods: {
    formatDate(date) {
      return (date !== "") ? this.$moment(date).format(this.$t('format.date.simple')) : ""
    },
    formatMonth(date) {
      return (date !== "") ? this.$moment(date).format('MM.YYYY') : "";
    },
    formatYear(date) {
      return (date !== "") ? this.$moment(date).format('YYYY') : "";
    },
    handleComboTab() {
      this.$refs.combobox.blur()
    },
    negateInput() {
      this.currentValue = this.currentValue * -1
      this.sendValue()
    },
    updateSearchInput(data) {
      this.comboSearchInput = data;
    },
    getCurrentComboValue() {
      if (this.currentValue !== this.previousValue) {
        if(this.currentValue) return this.currentValue;
        return "";
      } else {
        if (this.comboSearchInput) return this.comboSearchInput
        return this.$refs.combobox.value;
      }
    },
    lateBlurCombobox() {
      setTimeout(() => {
        this.$refs.combobox.blur();
      })
    },
    sendValueComboSingle() {
      if(this.conf.type === 'option') this.sendValueCombo();
    },
    sendValueComboMulti() {
      if(this.conf.type === 'multioption') this.sendValueCombo();
    },
    sendValueCombo() {
      let val = this.getCurrentComboValue();
      if (val !== this.previousValue) {
        if (this.isDifferentiator) {
          //should not be deleted
          if (val === undefined || val === "") {
            this.$nextTick(() => {
              this.currentValue = this.previousValue;
            })
            return;
          }
        }
        if(this.conf.inputType === 'double' && val && val !== "") {
          if(val.value) val = val.value;
          val = parseFloat(this.formatNumber(val));
          if(!val && val !== 0) {
            this.$nextTick(() => {
              this.currentValue = this.previousValue;
            })
            return;
          }
        }
        if (Array.isArray(val)) {
          const eventList = val.map(entry => ({
            value: entry.value ? entry.value : entry,
            type: this.conf.optionType || this.conf.option,
            CaptureDate: this.$moment().utc().toISOString()
          }));
          this.$emit('input', eventList);
        } else {
          const type = this.conf.optionType || this.conf.option;
          const resultValue = {
            value: val && val.value ? val.value : val,
            type,
            CaptureDate: this.$moment().utc().toISOString()
          }
          if (this.conf.unit) {
            resultValue.unit = this.conf.unit
          }
          this.$emit('input', resultValue);
          this.lateBlurCombobox();
        }
      }
    },
    removeValue() {
      this.currentValue = "";
      this.sendValue();
      if(this.conf.type === 'double') this.$refs.numberInput.blur();
      else this.$refs.textInput.blur();
    },
    sendValue() {
      if (this.currentValue !== this.previousValue) {
        if(this.currentValue == null) this.currentValue = "";
        if(this.conf.type === 'double' && this.currentValue !== "") this.currentValue = this.formatNumber(this.currentValue);
        if(this.useSelfCheckNumber) {
          this.incorrect = !checkNumberService.checkNumber(this.currentValue);
          if(!this.incorrect) this.currentValue = checkNumberService.formatCheckNumber(this.currentValue);
        }
        if(this.conf.type === 'baseString') {
          this.$emit('input', this.currentValue)
        } else {
          const unit = this.conf.unit;
          const resultValue = {
            value: this.currentValue,
            unit,
            CaptureDate: this.$moment().utc().toISOString()
          };
          if(this.conf.option) resultValue.type = this.conf.option;
          this.$emit('input', resultValue);
        }
      }
    },
    formatNumber(val) {
      val = val.toString().replace("," ,".");
      if(val.startsWith(".")) val = "0" + val;
      if(val.endsWith(".")) val = val.substr(0, val.length-1);
      return val;
    },
    removeDate() {
      this.$refs.dateInput.blur();
      this.$emit('input', {value: "", CaptureDate: this.$moment().utc().toISOString()});
    },
    removeMonth() {
      this.$refs.monthInput.blur();
      this.$emit('input', {value: "", CaptureDate: this.$moment().utc().toISOString()});
    },
    removeYear() {
      this.$refs.yearInput.blur();
      this.$emit('input', {value: "", CaptureDate: this.$moment().utc().toISOString()});
    },
    selectDate(val) {
      this.dateMenuOpen = false;
      this.$emit('input', {value: val, CaptureDate: this.$moment().utc().toISOString()});
    },
    selectMonth(val) {
      let date = this.$moment.utc(val);
      this.monthMenuOpen = false;
      this.currentValue = date.toISOString();
      this.$emit('input', {value: date.toISOString(), CaptureDate: this.$moment().utc().toISOString()});
    },
    selectYear(val) {
      let date = this.$moment.utc([val,0,1]);
      this.$refs.yearDialog.save(date);
      this.$refs.yearPicker.activePicker = 'YEAR'
      this.yearMenuOpen = false;
      this.currentValue = date.toISOString();
      this.$emit('input', {value: date.toISOString(), CaptureDate: this.$moment().utc().toISOString()});
    },
    preventComboNonNumericalInput(e) {
      if(this.conf.inputType !== 'double') return;
      this.preventNonNumericalInput(e);
    },
    preventNonNumericalInput(e) {
      const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
      const charStr = String.fromCharCode(charCode);

      if(!charStr.match(/^[0-9,.]+$/)) e.preventDefault(); //only numbers and comma allowed
      if(this.conf.inputType == 'double') return //combobox: cant check actual input-string
      if([',', '.'].includes(charStr)) {
        if(this.currentValue && (this.currentValue.toString().includes('.') || this.currentValue.toString().includes(','))) {
          e.preventDefault();
        }
      }
      if(this.conf.negateable && charStr === '-' && !this.currentValue.toString().includes('-')) {
        this.currentValue = "-" + this.currentValue;
      }
    },
    showTranslatedText() {
      if(this.conf.showTranslatedText) {
        const optionValue = this.defaultOptionValues.find(v => v.value === this.currentValue);
        if(optionValue) this.currentValue = optionValue.text;
      }
    },
    ...mapMutations({
      resolveOrderAttribute: 'order/resolveOrderAttribute'
    })
  },
  watch: {
    data(val) {
      if (val) {
        this.$nextTick(() => {
          if (Array.isArray(val)) {
            this.currentValue = val.map(v => v.value);
          } else if(val.value || val.value === 0){
              val.value = val.value == null ? "" : val.value;
              this.currentValue = val.value !== undefined ? (""+val.value).trim() : val;
              if(this.conf.type === 'boolean')  this.currentValue = (this.currentValue === 'true');
          } else if(this.conf.type === 'baseString'){
            this.currentValue = val;
          }
          this.showTranslatedText();
          if (this.conf.type === 'date') {
            this.dateFormatted = this.formatDate(val.value);
          } else if (this.conf.type === 'monthAndYear') {
            this.monthFormatted = this.formatMonth(val.value);
          } else if (this.conf.type === 'year') {
            this.yearFormatted = this.formatYear(val.value);
          }
          if (this.conf.callbackMutation && this.type === 'Outgoing') {
            this.$store.commit(this.conf.callbackMutation, val.value);
          }
          if (this.useSelfCheckNumber) {
            this.incorrect = !checkNumberService.checkNumber(this.currentValue);
          }
          this.previousValue = this.currentValue;
        });
      }
    },
    yearMenuOpen(val) {
      val && this.$nextTick(() => (this.$refs.yearPicker.activePicker = 'YEAR'))
    },
    defaultOptionsLoaded() {
      this.showTranslatedText();
    }
  },
  computed: {
    captureDate() {
      return this.data && (this.data.CaptureDate || (this.data[0] && this.data[0].CaptureDate))
    },
    lastModifier() {
      return this.protocolMaintenance ? (this.data && (this.data.LastModifier || (this.data[0] && this.data[0].LastModifier))) : ''
    },
    booleanLabel() {
      return this.currentValue ? this.$t('app.yes') : this.$t('app.no');
    },
    ...mapState(['language', 'protocolMaintenance', 'defaultOptionsLoaded']),
    defaultOptionValues() {
      if (this.conf.option) {
        const defaultOption = this.$store.getters.getDefaultOptionByKey(this.conf.option);
        const allOptions = defaultOption ? JSON.parse(JSON.stringify(defaultOption.values)) : []
        if(this.conf.optionAdd){
          for(const option of this.conf.optionAdd) {
            allOptions.push({text: this.$t(`editorder.${option}`), value: this.$t(`editorder.${option}`)})
          }
        }
        return allOptions;
      }
      return []
    }
  }
}
</script>

<style scoped>
.last-modified {
  display: inline-block;
  vertical-align: top;
}

.last-modifier {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 130px;
  white-space: nowrap;
  display: inline-block;
}
</style>
