import loadLimitGridTypes from "@/constants/order/loadLimitGrids";

export default {
    getLoadlimitGridFieldsByType(loadlimitGridType) {
        const grid = loadLimitGridTypes[loadlimitGridType];
        if (grid) {
            return Object.entries(grid).map(entry => entry[1].map(fields => fields[0] + entry[0])).flat()
        }
        return []
    },
    getLoadlimitGridMapByType(loadlimitGridType) {
        const grid = loadLimitGridTypes[loadlimitGridType];
        if (grid) {
            return Object.entries(grid).reduce((result, item) => {
                item[1].forEach(fields => {
                    const firstEntry = fields[0]+item[0]
                    result[firstEntry] = fields.filter(i => i !== fields[0]).map(entry => entry+item[0]);
                })
                return result;
            }, {});
        }
        return {}
    },
}
