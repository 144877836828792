<template>
  <div>
    <edit-order-take-over-all-entries :inputLines="inputLines" />
    <edit-order-collection-input-container
      :inputLines="inputLines"
      :split-view="true"
    />
  </div>
</template>

<script>
import inputValues from '../../constants/order/inputValues'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('order', ['order']),
  },
  data() {
    return {
      inputLines: inputValues.wheelset.wheelBasedata,
    }
  },
  name: 'WheelsetWheelBasedata',
}
</script>

<style scoped></style>
