import api from '@/service/api';
import apiEndpoints from '@/constants/api/apiEndpoints';

export default {
  getRolesFromApi() {
    return api()
      .get(`${apiEndpoints.users}/roles`)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  getUsersFromApi() {
    return api()
      .get(`${apiEndpoints.users}/current`)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  createUser({ username, firstName, lastName, roles }) {
    return api()
      .post(apiEndpoints.users, { username, firstName, lastName, roles })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  updateUser({ id, firstName, lastName, roles, enabled }) {
    return api()
      .put(`${apiEndpoints.users}/${id}`, {
        firstName,
        lastName,
        roles,
        enabled,
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  changePassword(id, password) {
    // we need to set the Content-Type explicit, so its not interpreted as JSON-String with additional quotes
    // e.g. '"Test1234"' instead of 'Test1234'
    const config = {
      headers: { 'Content-Type': 'text/plain' },
    };
    return api()
      .put(`${apiEndpoints.users}/${id}/changePassword`, password, config)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  deleteUser(id) {
    return api()
      .delete(`${apiEndpoints.users}/${id}`)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
};
