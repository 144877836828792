export default {
    /**
     * Simple object check.
     * @param item
     * @returns {boolean}
     */
    isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    },

    /**
     * Deep merge two objects.
     * @param target
     * @param ...sources
     */
    mergeDeep(target, ...sources) {
        if (!sources.length) return target;
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) Object.assign(target, {[key]: {}});
                    this.mergeDeep(target[key], source[key]);
                } else if (Array.isArray(source[key]) && target[key]) {
                    source[key].forEach((entry, idx) => {
                        let targetElement = target[key][idx];
                        if(!targetElement) {
                            target[key][idx] = entry;
                        }
                        this.mergeDeep(target[key][idx], entry);
                    });
                } else {
                    Object.assign(target, {[key]: source[key]});
                }
            }
        }

        return this.mergeDeep(target, ...sources);
    },

    removeUndefined(obj) {
        if(Array.isArray(obj)) {
            return obj.map(entry => this.removeUndefined(entry))
        }
        return Object.entries(obj).reduce((a,[k,v]) => (v === undefined ? a : (a[k]=v, a)), {})
    }
}