// https://vuex.vuejs.org/en/actions.html

import orderService from '../../../service/orderService';
import assetService from '../../../service/assetService';
import calculationService from '../../../service/calculationService';
import jsonPathHelper from '@/service/jsonPathHelper';
import Vue from 'vue';
import i18n from '@/i18n';

function returnValidTranslation(param, formulaConf) {
  const fullKey = formulaConf.params[param].path;

  if (fullKey) {
    const keyToTry = `editorder.${fullKey}`;
    const translation = i18n.t(keyToTry);

    if (translation !== keyToTry) {
      return translation;
    }

    if (fullKey.indexOf('.') >= 0) {
      const keyToTry = `editorder.${fullKey.split('.').slice(-1)[0]}`;
      const translation = i18n.t(keyToTry);
      if (translation !== keyToTry) {
        return translation;
      }
    }

    if (translation !== `editorder.${fullKey}`) {
      return translation;
    }

    return fullKey;
  }
  return param;
}

function getParent(key) {
  return key.split('.').slice(0, -1).join('.');
}

function counterSortFunction(a, b) {
  if (b.counter > a.counter) return 1;
  if (b.counter < a.counter) return -1;
  if (b.counterLocal > a.counterLocal) return 1;
  if (b.counterLocal < a.counterLocal) return -1;
  return 0;
}

const actions = {
  initEditOrderPage({ commit }, { orderType, orderId }) {
    commit('onInitEditOrder', { orderType, orderId });
  },
  async updateOrder({ commit, state }) {
    await orderService.updateOrderProgress(state, {
      orderType: state.order.type.toLowerCase(),
      orderId: state.order.id,
    });
    commit('onOrderUpdate', { order: state.order, reloadView: true });
  },
  async getOrderById({ commit, dispatch }, { orderId, isOffline }) {
    const order = await orderService.getOrderByIdfromDb(orderId);
    let updateOrderFromDb = () => {
      commit('onOrderUpdate', { order, reloadView: true });
      dispatch('saveOrder', order);
    };

    if (!isOffline && order.sent == 1) {
      //order need to been sent
      const orderResponse = await orderService.getOrderByIdFromApi(orderId);
      if (orderResponse && orderResponse.status === 200) {
        commit('onOrderUpdate', {
          order: orderResponse.data,
          reloadView: true,
        });
        dispatch('getOrderResults', { orderId, isOffline });
      } else {
        updateOrderFromDb();
      }
    } else {
      updateOrderFromDb();
    }
  },
  async deleteOrderById({ commit, state }, orderId) {
    let orderResponse;
    try {
      orderResponse = await orderService.deleteOrderById(orderId);
    } catch (e) {
      console.error(e);
    }
    if (orderResponse && orderResponse.status === 204) {
      const orders = state.orders.filter((ord) => ord.id !== orderId);
      const orderResults = state.orderResults.filter(
        (ord) => ord.orderId !== orderId
      );
      commit('updateOrders', orders);
      commit('onOrderResultsChanged', orderResults);
      await orderService.deleteOrderFromDb(orderId);
      return true;
    }
    return false;
  },
  async saveOrder(state, order) {
    await orderService.saveOrder(order);
  },
  async saveOrderResult(
    { state, rootState, commit },
    {
      orderId,
      orderType,
      key,
      resultValue,
      activityType,
      reloadView = false,
      skipProgressUpdate = false,
    }
  ) {
    if (activityType === 'XML_DELETE') key = getParent(key);
    const previousValue = orderService.plainResolveOrderAttribute(
      state.order,
      key
    );
    commit('onPreviousOrderValue', previousValue);
    const lastOrderResult = await orderService.saveOrderResult(
      orderId,
      key,
      resultValue,
      activityType
    );
    if (!skipProgressUpdate)
      await orderService.updateOrderProgress(state, { orderType, orderId });
    orderService.enrichLocalLastModifierIfAllowed(rootState, resultValue);
    const order =
      activityType === 'XML_DELETE'
        ? await orderService.deleteOrderByKeyAndSave(state.order, orderId, key)
        : await orderService.patchOrderByKeyAndSave(
            state.order,
            orderId,
            key,
            resultValue
          );
    commit('onOrderUpdate', { order, reloadView });
    commit('onLastOrderResult', lastOrderResult);
  },

  async revertLastOrderResult({ commit, state }) {
    const orderResult = await orderService.getOrderResultByIdfromDb(
      state.lastOrderResult
    );
    //if(orderResult.activityType === 'XML_DELETE') ... //TODO "Delete-" and "Add-Differentiator" is currently not reversible

    //remove or revert last order result
    if (orderResult.sent === 0) {
      await orderService.deleteOrderResultFromDb(orderResult.id);
    } else {
      orderResult.reverted = true;
      orderResult.sent = 0;
      await orderService.updateOrderResult(orderResult);
    }

    //revert last order state
    const orderId = state.order.id;
    const orderType = state.order.type.toLowerCase();
    const order = await orderService.patchOrderByKeyAndSave(
      state.order,
      orderId,
      orderResult.key,
      state.previousOrderValue
    );
    commit('onOrderUpdate', { order, reloadView: true });
    await orderService.updateOrderProgress(state, { orderType, orderId });

    //cleanup
    commit('onPreviousOrderValue', null);
    commit('onLastOrderResult', null);
  },
  calculateMatchingOrderResult(
    { state, dispatch, commit },
    {
      orderId,
      orderType,
      conf,
      incomingOrOutgoing,
      collectionPath,
      collectionIndex,
      pathPrefix,
      CaptureDate,
    }
  ) {
    try {
      const calculationResults = calculationService.invokeCalculation({
        orderType,
        conf,
        incomingOrOutgoing,
        collectionPath,
        collectionIndex,
        pathPrefix,
        order: state.order,
      });
      let iterations = calculationResults.length;
      for (const calculationResult of calculationResults) {
        const resultValue = {
          value: calculationResult.calculatedValue,
          unit: conf.unit,
          CaptureDate,
        };
        dispatch('saveOrderResult', {
          orderId,
          orderType,
          activityType: 'XML_MODIFICATION',
          key: calculationResult.key,
          resultValue,
          reloadView: !--iterations,
        });
      }
    } catch (e) {
      const fieldName = i18n.t(`editorder.${e.conf.path}`);
      const translatedValues = e.values.map((param) =>
        returnValidTranslation(param, e.conf)
      );
      commit(
        'snackbar/onNotification',
        {
          message: i18n.t('editorder.errors.' + e.error, [
            translatedValues,
            fieldName,
          ]),
          color: 'warning',
        },
        { root: true }
      );
    }
  },
  async getOrderResults({ commit, dispatch }, { orderId, isOffline }) {
    const orderResultsFromDb =
      await orderService.getOrderResultsByOrderIdFromDb(orderId);
    if (!isOffline) {
      try {
        const orderResultsResponse =
          await orderService.getOrderResultsByIdFromApi(orderId);
        if (orderResultsResponse.status === 200) {
          const mergedOrderResults = orderService.mergeOrderResults(
            orderResultsResponse.data,
            orderResultsFromDb
          );
          commit('onOrderResultsChanged', mergedOrderResults);
          await orderService.saveOrderResults(mergedOrderResults);
          dispatch('applyOrderResults', {
            orderResults: mergedOrderResults,
            orderId,
            reloadView: true,
          });
        }
      } catch (e) {
        commit('onOrderResultsChanged', orderResultsFromDb);
      }
    } else {
      commit('onOrderResultsChanged', orderResultsFromDb);
    }
  },

  async applyOrderResults(
    { commit, state, dispatch },
    { orderId, orderResults, reloadView }
  ) {
    const order = orderResults
      .filter(
        (result) =>
          !result.reverted &&
          result.activityType !== 'ORDER_STATUS_MODIFICATION'
      )
      .sort((a, b) => counterSortFunction(a, b))
      .reduceRight((order, entry) => {
        if (entry.lastModifier && entry.outputValue.lastModifier) {
          entry.outputValue.LastModifier = entry.lastModifier;
        }
        return entry.activityType === 'XML_DELETE'
          ? orderService.deleteOrderByKey(order, orderId, entry.key)
          : orderService.patchOrderByKey(
              order,
              orderId,
              entry.key,
              entry.outputValue
            );
      }, state.order);

    //set latest order status modification
    const statusModifications = orderResults
      .filter((result) => result.activityType === 'ORDER_STATUS_MODIFICATION')
      .sort((a, b) => counterSortFunction(a, b));
    if (statusModifications.length) {
      let status = statusModifications[0].outputValue;
      //map DONE_ARCHIVE_REQUESTED to DONE
      if (status === 'DONE_ARCHIVE_REQUESTED') status = 'DONE';
      order.status = status;
      commit('setOrderStatus', status);
    }

    dispatch('saveOrder', order);
    commit('onOrderUpdate', { order, reloadView });
    await orderService.updateOrderProgress(state, {
      orderId,
      orderType: order.type.toLowerCase(),
    });
  },
  async synchronizeOrderResults({ commit, dispatch }, { orderId, isOffline }) {
    if (!isOffline) {
      const activitySummary = await orderService.getActivitySummary(orderId);
      const orderResultsFromDb =
        await orderService.getOrderResultsByOrderIdFromDb(orderId);
      if (orderResultsFromDb.every((result) => result.sent === 1)) {
        const unknownOrderResults = await orderService.getUnknownOrderResults(
          orderId,
          orderResultsFromDb,
          activitySummary
        );
        if (unknownOrderResults.length > 0) {
          const mergedOrderResults = orderService.mergeOrderResults(
            unknownOrderResults,
            orderResultsFromDb
          );
          commit('onOrderResultsChanged', mergedOrderResults);
          await orderService.saveOrderResults(mergedOrderResults);
          dispatch('applyOrderResults', {
            orderId,
            orderResults: mergedOrderResults,
            reloadView: true,
          });
        }
        commit('orderResultSyncTime', new Date());
      }
    }
  },

  async sendUnsentOrders({ commit }, { isOffline }) {
    if (!isOffline) {
      const unsentOrders = await orderService.getUnsentOrders();
      let ordersChanged = false;

      for (const order of unsentOrders) {
        try {
          const orderResultResponse = await orderService.sendUnsentOrder(order);
          if (orderResultResponse.status === 200) {
            const newOrder = orderResultResponse.data;
            newOrder.sent = 1;

            await orderService.deleteOrderFromDb(order.id);
            await orderService.saveOrder(newOrder);
            ordersChanged = true;
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (ordersChanged) {
        const orders = await orderService.getOrdersFromDb();
        commit('updateOrders', orders);
      }
    }
  },

  async sendOrderResults({ commit }, { orderId, isOffline }) {
    if (!isOffline) {
      const unsentOrderResults = await orderService.getUnsentOrderResults();
      for (const orderResult of unsentOrderResults) {
        try {
          const orderResultResponse = await orderService.sendOrderResult(
            orderResult
          );
          if (orderResultResponse.status === 200) {
            const sentOrderResult = Object.assign({}, orderResult);
            sentOrderResult.sent = 1;
            sentOrderResult.lastModified = orderResultResponse.data.lm;
            sentOrderResult.counter = orderResultResponse.data.co;
            sentOrderResult.lastModifier = orderResultResponse.data.mod;
            await orderService.updateOrderResult(sentOrderResult);
          } else if (orderResultResponse.status === 404) {
            orderResult.sent = 2;
            console.warn(
              'order unknown in backend, will skip further requests '
            );
            await orderService.updateOrderResult(orderResult);
          }
        } catch (e) {
          console.error(e);
          if (e.status === 404) {
            orderResult.sent = 2;
            console.warn(
              'order unknown in backend, will skip further requests '
            );
            await orderService.updateOrderResult(orderResult);
          }
        }
      }
      commit('onSendOrderResults', orderId);
    }
  },
  async sendOrderImages({ commit }, { orderId, isOffline }) {
    if (!isOffline) {
      const unsentOrderImages = await orderService.getUnsentOrderImages();
      for (const image of unsentOrderImages) {
        const result = await assetService.saveOrderImageToAPI(image);
        if (result.sent === 1) await assetService.saveOrderImageToDB(result);
      }
      const orderImages = await assetService.getOrderImagesFromDB(orderId);
      commit('setOrderImages', orderImages);
    }
  },
  async deleteOrderImages({ commit }, { orderId, isOffline }) {
    if (!isOffline) {
      const OrderImagesToDelete = await orderService.getOrderImagesToDelete();
      for (const image of OrderImagesToDelete) {
        const result = await assetService.deleteAssetFromApi(image.imageId);
        if (result) await assetService.deleteOrderImageFromDB(image);
      }
      const orderImages = await assetService.getOrderImagesFromDB(orderId);
      commit('setOrderImages', orderImages);
    }
  },
  async validateImportFile(
    { commit, dispatch },
    {
      file,
      type,
      templateStr,
      index,
      updateCallback,
      validActionCallback,
      wagonType,
    }
  ) {
    //check if file is emptied again
    if (file && file.size) {
      try {
        const base64 = await assetService.getBase64StrFromFile(file);
        const assetId = await assetService.uploadXmlImport(
          base64,
          file.name,
          file.size
        );

        const template = {
          assetId,
          file,
          templateStr,
          index,
          wagonType,
          updateCallback,
          validActionCallback,
        };
        assetService.checkValidationStatusPeriodically(
          {
            commit,
            dispatch,
          },
          template,
          type
        );
        commit(updateCallback, template);
      } catch (e) {
        const template = {
          file,
          templateStr,
          index,
          validation: {
            messageKey: 'error.noNetwork',
            validationStatus: 'INVALID',
          },
        };
        commit('updateAddOrderTemplate', template);
      }
    }
  },
  async addDifferentiatorEntry(
    { commit, dispatch },
    {
      order,
      path,
      index,
      differentiator,
      differentiatorOption,
      value,
      prePath,
      uniformPaths,
      dependentPaths,
      reloadView,
    }
  ) {
    const orderType = order.type.toLowerCase();
    const keyIn = jsonPathHelper.getJsonPathKey(
      orderType,
      differentiator,
      'Incoming',
      path,
      index,
      prePath
    );
    const keyOut = jsonPathHelper.getJsonPathKey(
      orderType,
      differentiator,
      'Outgoing',
      path,
      index,
      prePath
    );
    const resultValue = {
      value,
      type: differentiatorOption,
      CaptureDate: Vue.moment().utc().toISOString(),
    };
    await dispatch('saveOrderResult', {
      orderType,
      orderId: order.id,
      key: keyIn,
      resultValue,
      activityType: 'XML_MODIFICATION',
      reloadView: false,
    });
    await dispatch('saveOrderResult', {
      orderType,
      orderId: order.id,
      key: keyOut,
      resultValue,
      activityType: 'XML_MODIFICATION',
      reloadView: false,
    });
    if (uniformPaths) {
      for (let uniPath of uniformPaths) {
        const keyInFirst = jsonPathHelper.getJsonPathKey(
          orderType,
          uniPath,
          'Incoming',
          path,
          0,
          prePath
        );
        const valueIn = orderService.plainResolveOrderAttribute(
          order,
          keyInFirst
        );
        if (valueIn) {
          const keyIn = jsonPathHelper.getJsonPathKey(
            orderType,
            uniPath,
            'Incoming',
            path,
            index,
            prePath
          );
          await dispatch('saveOrderResult', {
            orderType,
            orderId: order.id,
            key: keyIn,
            resultValue: valueIn,
            activityType: 'XML_MODIFICATION',
            reloadView: false,
          });
        }
        const keyOutFirst = jsonPathHelper.getJsonPathKey(
          orderType,
          uniPath,
          'Outgoing',
          path,
          0,
          prePath
        );
        const valueOut = orderService.plainResolveOrderAttribute(
          order,
          keyOutFirst
        );
        if (valueOut) {
          const keyOut = jsonPathHelper.getJsonPathKey(
            orderType,
            uniPath,
            'Outgoing',
            path,
            index,
            prePath
          );
          await dispatch('saveOrderResult', {
            orderType,
            orderId: order.id,
            key: keyOut,
            resultValue: valueOut,
            activityType: 'XML_MODIFICATION',
            reloadView: false,
          });
        }
      }
    }
    if (dependentPaths) {
      for (const dependency of dependentPaths) {
        if (dependency.quantity === index + 1) {
          const depValue = {
            value: dependency.upperValue,
            type: dependency.option,
            CaptureDate: Vue.moment().utc().toISOString(),
          };
          const keyOut = jsonPathHelper.getJsonPathKey(
            orderType,
            dependency.path,
            'Outgoing'
          );
          await dispatch('saveOrderResult', {
            orderType,
            orderId: order.id,
            key: keyOut,
            resultValue: depValue,
            activityType: 'XML_MODIFICATION',
            reloadView: false,
          });
        }
      }
    }
    commit('onOrderUpdate', { order, reloadView: reloadView });
    commit('onLastOrderResult', null); //TODO (because revert not working)
  },
  async removeDifferentiatorEntry(
    { commit, dispatch },
    {
      order,
      path,
      index,
      differentiator,
      differentiatorOption,
      value,
      prePath,
      dependentPaths,
      collectionSize,
      reloadView,
    }
  ) {
    if (index === -1) {
      // Index fix
      console.error(
        'Error at removeDifferentiatorEntry: Trying to remove an non-existent entry at position -1.'
      );
      return;
    }
    const orderType = order.type.toLowerCase();
    const keyIn = jsonPathHelper.getJsonPathKey(
      orderType,
      differentiator,
      'Incoming',
      path,
      index,
      prePath
    );
    const keyOut = jsonPathHelper.getJsonPathKey(
      orderType,
      differentiator,
      'Outgoing',
      path,
      index,
      prePath
    );
    const resultValue = {
      value: value,
      type: differentiatorOption,
      CaptureDate: Vue.moment().utc().toISOString(),
    };

    await dispatch('saveOrderResult', {
      orderType,
      orderId: order.id,
      key: keyIn,
      resultValue,
      activityType: 'XML_DELETE',
      reloadView: false,
    });
    await dispatch('saveOrderResult', {
      orderType,
      orderId: order.id,
      key: keyOut,
      resultValue,
      activityType: 'XML_DELETE',
      reloadView: false,
    });
    if (dependentPaths) {
      for (const dependency of dependentPaths) {
        if (dependency.quantity === collectionSize) {
          const depValue = {
            value: dependency.lowerValue,
            type: dependency.option,
            CaptureDate: Vue.moment().utc().toISOString(),
          };
          const keyOut = jsonPathHelper.getJsonPathKey(
            orderType,
            dependency.path,
            'Outgoing'
          );
          await dispatch('saveOrderResult', {
            orderType,
            orderId: order.id,
            key: keyOut,
            resultValue: depValue,
            activityType: 'XML_MODIFICATION',
            reloadView: false,
          });
        }
      }
    }
    commit('onOrderUpdate', { order, reloadView: reloadView });
    commit('onLastOrderResult', null); //TODO (because revert not working)
  },
  async removeOrderResultById({ commit, state }, orderId) {
    const orderResult = await orderService.getOrderResultByIdfromDb(orderId);

    //remove or revert last order result
    if (orderResult.sent === 0) {
      await orderService.deleteOrderResultFromDb(orderResult.id);
    } else {
      orderResult.reverted = true;
      orderResult.sent = 0;
      await orderService.updateOrderResult(orderResult);
    }

    //revert last order state
    orderId = state.order.id;
    const orderType = state.order.type.toLowerCase();
    const order = await orderService.patchOrderByKeyAndSave(
      state.order,
      orderId,
      orderResult.key,
      state.previousOrderValue
    );
    commit('onOrderUpdate', { order, reloadView: true });
    await orderService.updateOrderProgress(state, { orderType, orderId });

    //cleanup
    commit('onPreviousOrderValue', null);
    commit('onLastOrderResult', null);
  },

  async patchWheelsetToWagon({ commit, state }, { template }) {
    const updateCommand = {
      position: template.index,
      wheelset: { assetId: template.assetId, number: '' },
      direction: template.wagonType.toUpperCase(),
    };

    const wagonPatchResult = await orderService.patchWagonWheelset(
      state.order.id,
      updateCommand
    );

    if (wagonPatchResult.status === 200) {
      let order = state.order;
      order.Body.Wagen[
        `technicalWaggon${template.wagonType}`
      ].Understructure.WheelsetCollection.Wheelset[template.index] =
        wagonPatchResult.data;
      commit('onOrderUpdate', { order, reloadView: true });
      commit(template.updateCallback, template);
      await orderService.saveOrder(order);
    }
  },
  async patchWheelsetnumber(
    { dispatch, state },
    { index, value, incomingOrOutgoing }
  ) {
    let order = state.order;

    const collectionPath = 'Understructure.WheelsetCollection.Wheelset';
    const keyPath = jsonPathHelper.getJsonPathKey(
      order.type,
      'Wheelsetnumber',
      incomingOrOutgoing,
      collectionPath,
      index,
      undefined
    );

    const resultValueNum = {
      value: value,
      CaptureDate: Vue.moment().utc().toISOString(),
    };

    await dispatch('saveOrderResult', {
      orderType: order.type,
      orderId: order.id,
      key: keyPath,
      resultValue: resultValueNum,
      activityType: 'XML_MODIFICATION',
      reloadView: true,
    });
  },
  async incrementEditOrderWheelsets(
    { commit, dispatch, state },
    { index, pos }
  ) {
    let order = state.order;

    const collectionPath = 'Understructure.WheelsetCollection.Wheelset';
    const keyPathIn = jsonPathHelper.getJsonPathKey(
      order.type,
      'Position',
      'Incoming',
      collectionPath,
      index,
      undefined
    );
    const keyPathOut = jsonPathHelper.getJsonPathKey(
      order.type,
      'Position',
      'Outgoing',
      collectionPath,
      index,
      undefined
    );

    const resultValuePos = {
      value: pos,
      type: 'Position',
      CaptureDate: Vue.moment().utc().toISOString(),
    };

    state.order.Body.Wagen.technicalWaggonIncoming.Understructure.WheelsetCollection.Wheelset.push(
      { Wheelsetnumber: { value: '' }, Position: resultValuePos }
    );
    state.order.Body.Wagen.technicalWaggonOutgoing.Understructure.WheelsetCollection.Wheelset.push(
      { Wheelsetnumber: { value: '' }, Position: resultValuePos }
    );

    commit('onOrderUpdate', { order });
    await orderService.saveOrder(order);
    await orderService.updateOrderProgress(state, {
      orderId: order.id,
      orderType: order.type,
    });

    await dispatch('saveOrderResult', {
      orderType: order.type,
      orderId: order.id,
      key: keyPathIn,
      resultValue: resultValuePos,
      activityType: 'XML_MODIFICATION',
    });
    await dispatch('saveOrderResult', {
      orderType: order.type,
      orderId: order.id,
      key: keyPathOut,
      resultValue: resultValuePos,
      activityType: 'XML_MODIFICATION',
      reloadView: true,
    });
  },
  async decrementEditOrderWheelsets({ commit, dispatch, state }, { index }) {
    let order = state.order;

    const collectionPath = 'Understructure.WheelsetCollection.Wheelset';
    const keyPathIn = jsonPathHelper.getJsonPathKey(
      order.type,
      'Position',
      'Incoming',
      collectionPath,
      index,
      undefined
    );
    const keyPathOut = jsonPathHelper.getJsonPathKey(
      order.type,
      'Position',
      'Outgoing',
      collectionPath,
      index,
      undefined
    );

    const resultValuePos = {
      value: '',
      type: 'Position',
      CaptureDate: Vue.moment().utc().toISOString(),
    };

    state.order.Body.Wagen.technicalWaggonIncoming.Understructure.WheelsetCollection.Wheelset.pop();
    state.order.Body.Wagen.technicalWaggonOutgoing.Understructure.WheelsetCollection.Wheelset.pop();

    commit('onOrderUpdate', { order });
    await orderService.saveOrder(order);
    await orderService.updateOrderProgress(state, {
      orderId: order.id,
      orderType: order.type,
    });

    await dispatch('saveOrderResult', {
      orderType: order.type,
      orderId: order.id,
      key: keyPathIn,
      resultValue: resultValuePos,
      activityType: 'XML_DELETE',
    });
    await dispatch('saveOrderResult', {
      orderType: order.type,
      orderId: order.id,
      key: keyPathOut,
      resultValue: resultValuePos,
      activityType: 'XML_DELETE',
      reloadView: true,
    });
  },
  async refreshOrderImages({ commit }, orderId) {
    const response = await assetService.getAssetDTOsFromApi(orderId);
    if (response) {
      const imagesFromApi = response.data.filter(
        (asset) => asset.type === 'PICTURE'
      );

      if (imagesFromApi) {
        const imagesFromDB = await assetService.getOrderImagesFromDB(orderId);
        const apiIds = imagesFromApi.map((dto) => dto.id);
        const dbIds = imagesFromDB.map((img) => img.imageId);

        // delete images that that are not in the backend anymore
        const imagesToDelete = imagesFromDB.filter(
          (image) => image.imageId != null && !apiIds.includes(image.imageId)
        );
        for (const item of imagesToDelete) {
          await assetService.deleteOrderImageFromDB(item);
        }

        // create missing image objects and push into indexDB
        const imagesToCreate = imagesFromApi.filter(
          (dto) => !dbIds.includes(dto.id)
        );
        for (const item of imagesToCreate) {
          let image = {
            orderId: item.orderId,
            imageId: item.id,
            capturedDate: Date.parse(item.created),
            data: null,
            name: item.filename,
            sent: 1,
            toBeDeleted: 0,
          };
          await assetService.saveOrderImageToDB(image);
        }
      }
    }
    // update state
    const orderImages = await assetService.getOrderImagesFromDB(orderId);
    commit('setOrderImages', orderImages);
  },
  async loadMissingImageData({ commit }, orderId) {
    const imagesFromDB = await assetService.getOrderImagesFromDB(orderId);
    imagesFromDB.forEach((image) => {
      if (image.data == null) {
        assetService.getAssetFromApi(image.imageId).then((response) => {
          image.data = `data:image/png;base64,${response.data.base64}`;
          assetService.saveOrderImageToDB(image);
          commit('setOrderImages', imagesFromDB);
        });
      }
    });
  },
  async deleteOrderImage({ commit }, image) {
    // if the image has a imageId it is, or at least was, in th backend and needs to be deleted there as well.
    let isInBackend = !!image.imageId;
    // if the image is in the backend try to delete it there. If successful, it won't be in the backend anymore.
    if (isInBackend)
      isInBackend = !(await assetService.deleteAssetFromApi(image.imageId));
    // if the image is still assumed to be in the backend, remember and the scheduler will try again later.
    if (isInBackend) {
      image.toBeDeleted = 1;
      await assetService.saveOrderImageToDB(image);
    } else {
      // if the image ist not in the backend, delete it from indexDB.
      await assetService.deleteOrderImageFromDB(image);
    }
    // update state
    const orderImages = await assetService.getOrderImagesFromDB(image.orderId);
    commit('setOrderImages', orderImages);
  },
  async getOrderAssets({ commit }, orderId) {
    const response = await assetService.getAssetDTOsFromApi(orderId);
    if (response !== undefined && response.status === 200) {
      await commit('setOrderAssets', response.data);
      return true;
    } else {
      console.error('Error while fetching assets from api. Reponse:');
      console.error(response);
      commit('setOrderAssets', []);
      return false;
    }
  },
  async requestReportGeneration({ getters, commit }, generationParams) {
    const response = await assetService.generateReportDocuments(
      generationParams
    );
    if (response !== undefined && response.status === 200) {
      await commit('replaceOrderAssetsWithSameName', response.data);
      return true;
    } else if (response === undefined || response.status !== 403) {
      //other error than forbidden
      if (response.status === 400 && response?.data?.message) {
        try {
          const msgJson = JSON.parse(response.data.message);
          let trimmedPath = msgJson.path.replace(/.value$/, '');
          trimmedPath = trimmedPath.replace(/.type$/, '');
          let dict;
          if (generationParams.orderType === 'wagon') {
            trimmedPath = trimmedPath.replace(
              /^Body.Wagen.technicalWaggonOutgoing./,
              ''
            );
            trimmedPath = trimmedPath.replace(
              /^Body.Wagen.technicalWheelsetIncoming./,
              ''
            );
            dict = getters.getWagonPathNameDict;
          } else {
            trimmedPath = trimmedPath.replace(
              /^Body.Radsatz.technicalWheelsetOutgoing./,
              ''
            );
            trimmedPath = trimmedPath.replace(
              /^Body.Radsatz.technicalWheelsetIncoming./,
              ''
            );
            dict = getters.getWheelsetPathNameDict;
          }
          const fieldName = i18n.t(`editorder.${dict[trimmedPath].name}`);
          const tabName = i18n.t(
            `editorder.${generationParams.orderType}.tab.${dict[trimmedPath].tabName}`
          );
          commit(
            'snackbar/onNotification',
            {
              //some field have an incompatible type
              message: i18n.t('orderasstes.error.create.invalidType', [
                fieldName,
                tabName,
              ]),
              color: 'error',
            },
            { root: true }
          );
        } catch (e) {
          commit(
            'snackbar/onNotification',
            {
              message: i18n.t('orderassets.error.create.assets'),
              color: 'error',
            },
            { root: true }
          );
        }
      } else {
        commit(
          'snackbar/onNotification',
          {
            message: i18n.t('orderassets.error.create.assets'),
            color: 'error',
          },
          { root: true }
        );
      }
      return false;
    }
  },
  async updateAssetAvailability({ commit }, assetIds) {
    const response = await assetService.getAssetsByIdFromApi(assetIds);
    if (response !== undefined && response.status === 200) {
      const availableAssets = response.data.filter(
        (asset) => asset.status === 'AVAILABLE'
      );
      await commit('replaceOrderAssets', availableAssets);
    }
  },
  async findOrdersByOrderNumber({ commit }, orderNumber) {
    const ordersResponse = await orderService.getOrdersFromApi(orderNumber);
    if (ordersResponse !== undefined && ordersResponse.status === 200) {
      const orders = ordersResponse.data;
      const mergedOrders = await orderService.mergeOrdersFromApiToDb(orders);
      commit('updateOrders', mergedOrders);
      return mergedOrders;
    } else {
      console.error('Error while fetching orders by number. Reponse:');
      console.error(ordersResponse);
      return null;
    }
  },
  async updateOrderStatus({ commit }, { orderId, status }) {
    const response = await orderService.patchOrderStatus(orderId, status);
    if (response !== undefined && response.status === 200) {
      const sentOrderResult = {
        created: response.data.c,
        lastModified: response.data.lm,
        key: response.data.k,
        orderId: response.data.oI,
        counter: response.data.co,
        reverted: response.data.re,
        activityType: response.data.t,
        outputValue: response.data.oV,
        lastModifier: response.data.mod,
        sent: 1,
      };
      await orderService.updateOrderResult(sentOrderResult);

      const order = await orderService.getOrderByIdfromDb(orderId);
      order.status = status;
      await orderService.saveOrder(order);
      commit('setOrderStatus', status);
      return true;
    } else {
      console.error('Error while updating status. Reponse:');
      console.error(response);
      return false;
    }
  },
};

export default actions;
