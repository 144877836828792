<template>
  <div>
    <edit-order-take-over-all-entries
      :chapters="{ chapters: { ...wheelsets } }"
    />
    <v-tabs class="fixed-tabs-bar" v-model="activeWheelsetsTab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab key="summary">{{ $t('editorder.summary') }}</v-tab>
      <v-tab
        v-for="(item, index) in incomingWheelsets"
        :key="'#wheelset-' + index"
      >
        {{ $t('editorder.wheelset') }} {{ index + 1 }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeWheelsetsTab">
      <v-tab-item key="summary" :transition="false" :reverse-transition="false">
        <v-container fluid>
          <v-row justify="end">
            <v-col lg="12" md="6" sm="12" xs="12">
              <v-btn
                color="primary"
                class="mr-2"
                @click="incrementWheelsets"
                absolute
                right
                style="right: 80px"
                :disabled="incomingWheelsets.length === 8 || disabled"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                @click="decrementWheelsets"
                absolute
                right
                :disabled="incomingWheelsets.length === 2 || disabled"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6" md="6" sm="12" xs="12">
              <edit-order-wagon-wheelset-input
                wagonType="Incoming"
                :key="'Incoming' + orderUpdate"
                :disabled="disabled"
                :label="$t('editorder.wheelsetsIncoming')"
              />
            </v-col>
            <v-col lg="6" md="6" sm="12" xs="12">
              <edit-order-wagon-wheelset-input
                wagonType="Outgoing"
                :key="'Outgoing' + orderUpdate"
                :disabled="disabled"
                :label="$t('editorder.wheelsetsOutgoing')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item
        v-for="(item, index) in incomingWheelsets"
        :transition="false"
        :reverse-transition="false"
        :key="'#wheelset-' + index"
      >
        <v-col class="px-1">
          <edit-order-chapter-frame chapter-name="wheelset.tab.basedata">
            <edit-order-simple-input-container
              :inputLines="wheelsets.chapters.basedata"
              :differentiator="wheelsets.differentiator"
              :pathPrefix="getPathPrefix(index)"
            />
          </edit-order-chapter-frame>
          <edit-order-chapter-frame chapter-name="wheelset.tab.wheelBasedata">
            <edit-order-collection-input-container
              :split-view="true"
              :inputLines="wheelsets.chapters.wheelBasedata"
              :pathPrefix="getPathPrefix(index)"
            />
          </edit-order-chapter-frame>
          <edit-order-chapter-frame chapter-name="wheelset.tab.audits">
            <edit-order-simple-input-container
              :inputLines="wheelsets.chapters.audits"
              :pathPrefix="getPathPrefix(index)"
            />
          </edit-order-chapter-frame>
          <edit-order-chapter-frame chapter-name="wheelset.tab.axleBasedata">
            <edit-order-simple-input-container
              :inputLines="wheelsets.chapters.axleBasedata"
              :pathPrefix="getPathPrefix(index)"
            />
          </edit-order-chapter-frame>
          <edit-order-chapter-frame chapter-name="paintworkAxle">
            <edit-order-collection-input-container
              :inputLines="wheelsets.chapters.axlePaintwork"
              :pathPrefix="getPathPrefix(index)"
            />
          </edit-order-chapter-frame>
          <edit-order-chapter-frame chapter-name="wheelset.tab.bearing">
            <edit-order-collection-input-container
              :inputLines="wheelsets.chapters.bearing"
              :pathPrefix="getPathPrefix(index)"
            />
          </edit-order-chapter-frame>
          <edit-order-chapter-frame chapter-name="wheelset.tab.bearingHousing">
            <edit-order-collection-input-container
              :split-view="true"
              :inputLines="wheelsets.chapters.bearingHousing"
              :pathPrefix="getPathPrefix(index)"
            />
          </edit-order-chapter-frame>
          <edit-order-chapter-frame chapter-name="wheelset.tab.wheelMeasures">
            <edit-order-collection-input-container
              :split-view="true"
              :inputLines="wheelsets.chapters.wheelMeasures"
              :pathPrefix="getPathPrefix(index)"
            />
          </edit-order-chapter-frame>
        </v-col>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import inputValues from '../../constants/order/inputValues';
import { mapActions, mapMutations, mapState } from 'vuex';
import { ORDER_STATUS } from '@/constants/order/orderStatus';

export default {
  computed: {
    disabled() {
      return (
        this.order.status === ORDER_STATUS.DONE ||
        this.order.status === ORDER_STATUS.DONE_ARCHIVED
      );
    },
    ...mapState('order', ['order', 'orderUpdate']),
    incomingWheelsets() {
      return this.$store.state.order.order.Body
        ? this.$store.state.order.order.Body.Wagen.technicalWaggonIncoming
            .Understructure.WheelsetCollection.Wheelset
        : {};
    },
    activeWheelsetsTab: {
      get() {
        return this.$store.state.order.activeWheelsetsTab;
      },
      set(val) {
        this.setActiveWheelsetsTab(val);
      },
    },
  },
  methods: {
    getPathPrefix(index) {
      return `${this.wheelsets.listPath}[${index}]`;
    },
    async incrementWheelsets() {
      //get the first free position
      let positions = this.incomingWheelsets.map((v) => v.Position.value);
      let pos = 1;
      while (positions.includes(pos)) pos++;
      await this.incrementEditOrderWheelsets({
        index: this.incomingWheelsets.length,
        pos: pos,
      });
    },
    async decrementWheelsets() {
      const index = this.incomingWheelsets.length - 1;
      await this.decrementEditOrderWheelsets({ index: index });
    },
    ...mapActions({
      incrementEditOrderWheelsets: 'order/incrementEditOrderWheelsets',
      decrementEditOrderWheelsets: 'order/decrementEditOrderWheelsets',
    }),
    ...mapMutations({
      setActiveWheelsetsTab: 'order/setActiveWheelsetsTab',
    }),
  },
  data() {
    return {
      wheelsets: inputValues.wagon.wheelsets,
    };
  },
  name: 'WagonWheelsets',
};
</script>

<style lang="css">
.fixed-tabs-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
  z-index: 2;
}
</style>
