import { set } from '@/utils/vuex'

export default {
    setUsers: set('users'),
    setRoles: set('roles'),
    addUser(state, user) {
      state.users.push(user);
    },
    replaceUser(state, user){
        const index = state.users.findIndex(a => a.id === user.id);
        state.users.splice(index, 1, user);
    },
    removeUser(state, user) {
        const index = state.users.findIndex(a => a.id === user.id);
        state.users.splice(index, 1);
    },
    clearState(state) {
        state.users = [];
        state.roles = [];
    },
}
