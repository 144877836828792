import api from './api';
import idb from '../store/idb';
import inputValues from '../constants/order/inputValues';
import { v4 as uuidv4 } from 'uuid';
import jsonpath from 'jsonpath';
import jsonPathHelper from './jsonPathHelper';
import objectHelper from './objectHelper';
import apiEndpoints from '@/constants/api/apiEndpoints';

function getIndexFromPath(jsonPathStr) {
  const regx = /\[(\d+)\]$/;
  const index = regx.exec(jsonPathStr)[1];
  return index;
}

function deleteBraces(jsonPath) {
  const regx = /\[(\d+)\]$/;
  jsonPath = jsonPath.replace(regx, '');
  return jsonPath;
}

function getRandomString() {
  return Math.random().toString(36).substring(2, 15);
}

export default {
  async checkOnlineStatus() {
    try {
      // avoid CORS errors with a request to your own origin
      const url = new URL(window.location.origin);
      // random value to prevent cached responses
      url.searchParams.set('rand', getRandomString());
      const online = await fetch(url.toString());
      return online.status < 200 || online.status >= 300; // either true or false
    } catch (err) {
      return true; // definitely offline
    }
  },
  async getOrdersFromDb() {
    return await idb.getOrders();
  },
  async getOrderByIdfromDb(orderId) {
    return await idb.getOrderById(orderId);
  },
  async getOrderResultByIdfromDb(orderResultId) {
    return await idb.getOrderResultById(orderResultId);
  },
  async getOrderByIdFromApi(orderId) {
    return await api().get(`${apiEndpoints.orders}/${orderId}`);
  },
  async patchWagonWheelset(orderId, updateCommand) {
    return await api().patch(
      `${apiEndpoints.orders}/${orderId}`,
      updateCommand
    );
  },
  async patchOrderByKeyAndSave(order, orderId, jsonPathStr, resultValue) {
    const appliedOrder = this.patchOrderByKey(
      order,
      orderId,
      jsonPathStr,
      resultValue
    );
    await this.saveOrder(appliedOrder);
    return appliedOrder;
  },
  patchOrderByKey(order, orderId, jsonPathStr, resultValue) {
    const nodes = jsonpath.nodes(order, jsonPathStr);
    if (nodes.length === 0) {
      order = objectHelper.mergeDeep(
        order,
        jsonPathHelper.createObjectFromPath(jsonPathStr)
      );
    }
    jsonpath.apply(order, jsonPathStr, () => resultValue);
    return order;
  },
  async deleteOrderByKeyAndSave(order, orderId, jsonPathStr) {
    const appliedOrder = this.deleteOrderByKey(order, orderId, jsonPathStr);
    await this.saveOrder(appliedOrder);
    return appliedOrder;
  },
  deleteOrderByKey(order, orderId, jsonPathStr) {
    if (jsonPathStr.endsWith('[-1]')) {
      // Index fix
      console.error(
        'Error at deleteOrderByKey: Trying to remove an non-existent entry at position -1.'
      );
      return order;
    }
    const nodes = jsonpath.nodes(order, jsonPathStr);
    if (nodes.length === 0) {
      order = objectHelper.mergeDeep(
        order,
        jsonPathHelper.createObjectFromPath(jsonPathStr)
      );
    }
    const parentPath = jsonpath.value(order, deleteBraces(jsonPathStr));

    const index = getIndexFromPath(jsonPathStr);
    parentPath.splice(index, 1);

    //TODO may better delete using apply function
    /*jsonpath.apply(order, parentPath, function(value) {
            console.log(value);
            return value;
        });*/
    return order;
  },
  async getOrdersFromApi(orderNumber) {
    return await api().get(apiEndpoints.orders, {
      params: {
        orderNumber,
      },
    });
  },
  async deleteOrderById(orderId) {
    return await api().delete(`${apiEndpoints.orders}/${orderId}`);
  },
  async getOrderResultsByIdFromApi(orderId) {
    return await api().get(`${apiEndpoints.activity}/${orderId}`);
  },
  mergeOrderResults(orderResultsFromApi, orderResultsFromDb) {
    const uncompressed = orderResultsFromApi
      .filter((order) => !order.k.endsWith('[-1]')) // Index fix
      .map((order) => {
        let outputValue;
        if (order.t === 'ORDER_STATUS_MODIFICATION') outputValue = order.oV;
        else {
          try {
            outputValue = JSON.parse(order.oV);
          } catch {
            //case type==='baseString'
            if (typeof order.oV === 'string') outputValue = order.oV;
          }
        }
        return {
          created: order.c,
          lastModified: order.lm,
          key: order.k,
          orderId: order.oI,
          counter: order.co,
          reverted: order.re,
          activityType: order.t,
          outputValue: outputValue,
          lastModifier: order.mod,
          sent: 1,
        };
      });
    return orderResultsFromDb.concat(uncompressed).reduce((result, obj) => {
      if (
        result.find(
          (res) => res.key === obj.key && res.counter === obj.counter
        ) === undefined ||
        obj.sent == 0
      )
        result.push(obj);
      return result;
    }, []);
  },
  async getUnsentOrders() {
    return await idb.getUnsentOrders();
  },
  async mergeOrdersFromApiToDb(ordersFromApi) {
    const ordersFromDb = await idb.getAll('orders');

    //delete relics in local indexDB
    const abandonedOrders = ordersFromDb.filter(
      (o1) =>
        !ordersFromApi.find((o2) => o2.id === o1.id) &&
        (!o1.sent || o1.sent == 1)
    );
    for (let id of abandonedOrders.map((o) => o.id)) {
      await idb.deleteOrder(id);
    }
    //last part: map DONE_ARCHIVE_REQUESTED to DONE
    const mergedOrders = ordersFromApi.map((o1) => ({
      ...o1,
      ...ordersFromDb.find((o2) => o2.id === o1.id),
      sent: 1,
      status: o1.status === 'DONE_ARCHIVE_REQUESTED' ? 'DONE' : o1.status,
    }));

    //not send orders
    const offlineOrders = ordersFromDb.filter((o1) => o1.sent == 0);
    const resultOrders = mergedOrders.concat(offlineOrders);
    await idb.saveOrders(resultOrders);
    return resultOrders;
  },
  async saveOrderResult(orderId, key, value, activityType) {
    const now = new Date();
    const unsentOrderResults = await this.getUnsentOrderResultsById(orderId);
    const counterL =
      unsentOrderResults.length > 0
        ? Math.max(...unsentOrderResults.map((result) => result.counterLocal)) +
          1
        : 0;
    const orderResult = {
      orderId,
      key,
      outputValue: value,
      sent: 0,
      counter: Number.MAX_VALUE,
      counterLocal: counterL,
      lastModified: now.toISOString(),
      activityType,
      created: now.toISOString(),
    };
    //boolean status has to be numeric as idb index can not be plain boolean
    return await idb.saveOrderResult(orderResult);
  },
  async updateOrderResult(orderResult) {
    await idb.saveOrderResult(orderResult);
  },
  async saveOrderResults(orderResults) {
    await idb.saveOrderResults(orderResults);
  },
  async sendOrderResult(orderResult) {
    const smallOrderResult = {
      oI: orderResult.orderId,
      k: orderResult.key,
      t: orderResult.activityType,
      oV:
        typeof orderResult.outputValue === 'string'
          ? orderResult.outputValue
          : JSON.stringify(orderResult.outputValue),
      c: orderResult.created,
    };

    //expect that an event has already been sent if reverted === true
    if (
      orderResult.reverted &&
      orderResult.counter !== undefined &&
      orderResult.counter !== Number.MAX_VALUE
    ) {
      return await api().patch(
        `${apiEndpoints.activity}/${orderResult.orderId}/${orderResult.counter}/reverted`
      );
    } else if (!orderResult.reverted) {
      return await api().post(apiEndpoints.activity, smallOrderResult);
    }
  },
  async getOrderResultsByOrderIdFromDb(orderId) {
    const orderResults = await idb.getOrderResults(orderId);
    const wrongStateResults = orderResults.filter((r) =>
      r.key.endsWith('[-1]')
    ); // Index fix
    for (const result of wrongStateResults) {
      await idb.deleteOrderResult(result.id);
    }
    return orderResults.filter((r) => !r.key.endsWith('[-1]')); // Index fix
  },
  async getUnsentOrderResults() {
    return await idb.getUnsentOrderResults();
  },
  async getUnsentOrderResultsById(orderId) {
    return await idb.getUnsentOrderResultsById(orderId);
  },
  async getUnsentOrderImages() {
    return await idb.getUnsentOrderImages();
  },
  async getOrderImagesToDelete() {
    return await idb.getOrderImagesToDelete();
  },
  async sendUnsentOrder(order) {
    const orderToSend = this.createOrderDto(null, order);
    return await this.createOrderOverApi(orderToSend);
  },
  async createOrderOverApi(orderTemplate) {
    return await api().post(apiEndpoints.orders, orderTemplate);
  },
  async addOrderToDb(order) {
    order.id = uuidv4();
    await this.saveOrder(order);
  },
  resolveDifferentiatorValues({
    order,
    path,
    type,
    collectionIndex,
    collectionPath,
    prePath,
    differentiator,
  }) {
    const resolvedValue = this.resolveOrderAttribute(
      order,
      path,
      type,
      collectionIndex,
      collectionPath,
      prePath,
      false
    );
    if (resolvedValue) {
      return resolvedValue.reduce(function (filtered, value) {
        if (value[differentiator]) {
          filtered.push(value[differentiator].value);
        }
        return filtered;
      }, []);
    }
    return [];
  },
  resolveOrderAttribute(
    order,
    path,
    type,
    collectionIndex,
    collectionPath,
    prePath,
    isAbsolutePath
  ) {
    const orderType = order.type.toLowerCase();
    const jsonPath = jsonPathHelper.getJsonPathKey(
      orderType,
      path,
      type,
      collectionPath,
      collectionIndex,
      prePath,
      isAbsolutePath
    );
    return this.plainResolveOrderAttribute(order, jsonPath);
  },
  plainResolveOrderAttribute(order, jsonPath) {
    return jsonpath.value(order, jsonPath);
  },
  async saveOrder(order) {
    return await idb.saveOrder(order);
  },
  async deleteOrderFromDb(orderId) {
    await idb.deleteOrderResultsForOrderId(orderId);
    await idb.deleteOrderImage(orderId);
    return await idb.deleteOrder(orderId);
  },
  async deleteOrderResultFromDb(orderResultId) {
    return await idb.deleteOrderResult(orderResultId);
  },
  getPossibleValues(state, conf, orderType, prePath) {
    const indices = this.getPossibleIndices(state, conf, orderType, prePath);

    return indices
      .map((idx) => {
        if (conf.data) {
          return conf.data
            .filter((entry) => !entry.ignoreProgress)
            .map((d) =>
              jsonPathHelper.getJsonPathKey(
                orderType,
                d.path,
                'Outgoing',
                conf.listPath,
                idx,
                prePath
              )
            );
        } else if (conf.chapters) {
          return Object.keys(conf.chapters).flatMap((chapterName) =>
            this.getPossibleValues(
              state,
              conf.chapters[chapterName],
              orderType,
              conf.listPath ? `${conf.listPath}[${idx}]` : undefined
            )
          );
        }
      })
      .flat();
  },
  async updateOrderProgress(state, { orderId, orderType }) {
    const currentTabs =
      orderType.toLowerCase() === 'wagon'
        ? state.wagonTabs
        : state.wheelsetTabs;
    const orderResults = await idb.getOrderResults(orderId);
    const unrevertedOrderResults = orderResults.filter(
      (result) => !result.reverted
    );
    const typedConf = inputValues[orderType.toLowerCase()];
    state.orderResults = unrevertedOrderResults;

    Object.keys(currentTabs).forEach((key) => {
      const tabConf = typedConf[key];
      const possibleValues = this.getPossibleValues(state, tabConf, orderType);
      const currentValues = possibleValues
        .map((v) => this.plainResolveOrderAttribute(state.order, v))
        .filter((v) => !!v && v.value !== '');
      currentTabs[key] = (100 * currentValues.length) / possibleValues.length;
    });
  },
  //patch local lastModifier
  enrichLocalLastModifierIfAllowed(rootState, resultValue) {
    if (rootState.protocolMaintenance) {
      if (Array.isArray(resultValue)) {
        if (resultValue.length > 0)
          resultValue[0].LastModifier = rootState.user.lastName;
      } else if (typeof resultValue === 'object' && resultValue !== null) {
        resultValue.LastModifier = rootState.user.lastName;
      }
    }
  },
  createOrderDto(state, order) {
    const contractor = order.contractor.value
      ? order.contractor.value
      : order.contractor;
    const orderDto = {
      contractor: contractor,
      type: order.type,
      number: order.number,
      externalOrderNumber: order.externalOrderNumber,
    };
    if (!state) {
      orderDto.id = order.id;
      orderDto.maintenanceNumber = order.maintenanceNumber;
    }
    if (orderDto.type === 'WAGON') {
      if (state) orderDto.maintenanceNumber = order.wagonNumber;
      orderDto.wagon = {
        assetId: state ? state.wagonTemplate.assetId : undefined,
        number: state ? order.wagonNumber : order.maintenanceNumber,
        wheelsetsOutgoing: state
          ? state.wagonWheelsetTemplatesOutgoing.map((temp) => ({
              number: temp.number,
              assetId: temp.assetId,
            }))
          : order.Body.Wagen.technicalWaggonOutgoing.Understructure.WheelsetCollection.Wheelset.map(
              (temp) => ({
                number: temp.Wheelsetnumber.value,
                assetId: undefined,
              })
            ),
      };
    } else if (orderDto.type === 'WHEELSET') {
      if (state) orderDto.maintenanceNumber = order.wheelsetNumber;
      orderDto.wheelset = {
        number: state ? order.wheelsetNumber : order.maintenanceNumber,
        assetId: state ? state.wheelsetTemplate.assetId : undefined,
      };
    }
    return orderDto;
  },
  async addOrder(state, isOffline) {
    const orderDto = this.createOrderDto(state, state.newOrder);
    if (!isOffline) {
      try {
        const orderCreateResponse = await this.createOrderOverApi(orderDto);
        if (orderCreateResponse.status === 200) {
          let order = orderCreateResponse.data;
          order.sent = 1;
          await this.saveOrder(order);
          return true;
        }
      } catch (e) {
        if (e.status === 500) {
          const emptyOrder = this.createEmptyOrder(orderDto, state);
          await this.addOrderToDb(emptyOrder);
          return true;
        }
        return false;
      }
    } else {
      const emptyOrder = this.createEmptyOrder(orderDto, state);
      await this.addOrderToDb(emptyOrder);
      return true;
    }

    return false;
  },
  createEmptyOrder(newOrder, state) {
    if (newOrder.type === 'WAGON') {
      return {
        sent: 0,
        number: newOrder.number,
        created: newOrder.created || new Date(),
        maintenanceNumber: newOrder.maintenanceNumber,
        contractor: newOrder.contractor,
        externalOrderNumber: newOrder.externalOrderNumber,
        type: 'WAGON',
        status: 'CREATED',
        Body: {
          Wagen: {
            technicalWaggonIncoming: this.createWagon(
              newOrder.maintenanceNumber,
              state.wagonWheelsetTemplatesOutgoing
            ),
            technicalWaggonOutgoing: this.createWagon(
              newOrder.maintenanceNumber,
              state.wagonWheelsetTemplatesOutgoing
            ),
          },
        },
      };
    } else if (newOrder.type === 'WHEELSET') {
      return {
        sent: 0,
        number: newOrder.number,
        created: newOrder.created || new Date(),
        maintenanceNumber: newOrder.maintenanceNumber,
        externalOrderNumber: newOrder.externalOrderNumber,
        contractor: newOrder.contractor,
        type: 'WHEELSET',
        status: 'CREATED',
        Body: {
          Radsatz: {
            technicalWheelsetIncoming: this.createWheelset(
              newOrder.maintenanceNumber
            ),
            technicalWheelsetOutgoing: this.createWheelset(
              newOrder.maintenanceNumber
            ),
          },
        },
      };
    }
  },
  createWagon(number, wagonWheelsets) {
    return {
      Waggonnummer: {
        value: number,
      },
      Understructure: {
        WheelsetCollection: {
          Wheelset: wagonWheelsets.map((wheelset) =>
            this.createWheelset(wheelset.number)
          ),
        },
      },
    };
  },
  createWheelset(number) {
    return {
      Wheelsetnumber: {
        value: number,
      },
    };
  },
  getPossibleIndices(state, conf, orderType, prePath) {
    if (
      (conf.chapters || conf.differentiator) &&
      state.order &&
      conf.listPath
    ) {
      const jsonPathKey = jsonPathHelper.getJsonPathKey(
        orderType,
        conf.listPath,
        'Incoming',
        undefined,
        undefined,
        prePath
      );
      const chapters = jsonpath.value(state.order, jsonPathKey) || [];
      return chapters.map((val, idx) => idx);
    }

    return [0];
  },
  async getActivitySummary(orderId) {
    return await api().get(`${apiEndpoints.activity}/${orderId}/last`);
  },
  async getUnknownOrderResults(orderId, orderResultsFromDb, activitySummary) {
    // check if all order results are sent as only then we should have counter data
    if (
      orderResultsFromDb.every(
        (result) =>
          result.counter !== undefined && result.counter !== Number.MAX_VALUE
      )
    ) {
      if (activitySummary.data.last > 0) {
        const expectedCounters = Array(activitySummary.data.last)
          .fill()
          .map((_, idx) => 1 + idx);
        const existingCounters = orderResultsFromDb.map(
          (result) => result.counter
        );
        const unknownCounters = expectedCounters.filter(
          (x) => !existingCounters.includes(x)
        );

        const unknownOrderResults = [];
        for (const counter of unknownCounters) {
          const orderResultResponse = await api().get(
            `${apiEndpoints.activity}/${orderId}/${counter}`
          );
          if (orderResultResponse.status === 200) {
            unknownOrderResults.push(orderResultResponse.data);
          }
        }
        return unknownOrderResults;
      }
    }
    return [];
  },
  patchOrderStatus(orderId, status) {
    return api()
      .post(`${apiEndpoints.orders}/${orderId}/status`, { status: status })
      .catch((err) => console.error(err));
  },
};
