/* eslint-disable no-console */

import {register} from 'register-service-worker';
import api from './service/api';
import store from "./store";
import i18n from "./i18n";

async function getCurrentFrontendVersion() {
    const response = await api().get('/COMMIT').catch(err => console.error(err));
    const hash = response && response.status === 200 ? response.data : "undefined"
    localStorage.setItem('frontVersion', hash)
    window.dispatchEvent(new CustomEvent('frontVersion-changed', {
        detail: {
            frontVersion: localStorage.getItem('frontVersion')
        }
    }));
    return hash;
}

(async() => {
    let HASH = await getCurrentFrontendVersion();
    register(`${process.env.BASE_URL}service-worker.js?hash=${HASH}`, {
        ready() {
            console.info(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            );
            localStorage.setItem('frontVersionCurrent', HASH)
            window.dispatchEvent(new CustomEvent('frontVersionCurrent-changed', {
                detail: {
                    frontVersionCurrent: localStorage.getItem('frontVersionCurrent')
                }
            }));
        },
        registered(reg) {
            console.info('Service worker has been registered.');
            setInterval(() => {
                getCurrentFrontendVersion().then(currentHASH => {
                    if(currentHASH && currentHASH !== "undefined" && HASH !== currentHASH) { // only if HASH is defined (e.g. it isn't if we're offline)
                        console.info("New frontend version on server detected.")
                        HASH = currentHASH;
                        reg.update().then(result => {
                            console.info("Updating service worker registration successful: " + result);
                            store.commit('alertbar/onAlert', {
                                message: i18n.t('app.update.refresh'),
                                color: 'warning'
                            }, {root: true});
                        }).catch(error => {
                            console.error("Updating service worker registration failed: " + error);
                        })
                    }
                });

            }, 1000 * 20 * 1); // e.g. minutely checks
        },
        cached() {
            console.info('Content has been cached for offline use.');
        },
        updatefound() {
            console.info('New content is downloading.');
        },
        updated () {
            console.info('Updating service worker...')
        },
        offline() {
            console.info('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });
}) ();

