<template>
    <v-navigation-drawer
            id="app-drawer"
            app
            right
            permanent
            :mini-variant="mini"
            mini-variant-width="100"
            mobile-breakpoint="391"
            style="z-index: 1"
            :width="mini ? 100 : 260"
    >
        <v-list>
            <v-list-item>
                <v-list-item-title class="title" v-if="!mini"> {{$t('editorder.'+orderType+'.title')}}
                </v-list-item-title>
                <v-list-item-avatar v-if="mini">
                    <v-icon v-if="orderType === 'wagon'">mdi-train</v-icon>
                    <v-icon v-if="orderType === 'wheelset'">mdi-dumbbell</v-icon>
                </v-list-item-avatar>
            </v-list-item>
            <v-divider></v-divider>
        </v-list>
        <v-list>
            <v-list-item v-for="(tab, index) in Object.entries(currentTabs)" :key="tab[0]" two-line
                         :class="index === currentTabIndex ? 'v-list-item--active' : ''"
                         @click="routeToTab(tab[0], index)"
            >
                <v-list-item-content>
                    <v-list-item-title>{{ $t('editorder.'+orderType+'.tab.'+tab[0]) }}</v-list-item-title>
                    <v-list-item-subtitle>
                        <v-progress-linear
                                :color="determineProgressColor(tab[1])"
                                height="3"
                                :value="tab[1]">
                        </v-progress-linear>
                    </v-list-item-subtitle>

                </v-list-item-content>

            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    // Utilities

    import {mapMutations, mapState} from "vuex";

    const PROGRESS_COLORS = {
        20: 'red',
        60: 'orange',
        100: 'green',
    };

    export default {
        computed: {
            mini() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            orderType() {
                return this.$route.params.orderType
            },
          ...mapState('order',['order','currentTabIndex']),
            currentTabs() {
                switch (this.$route.params.orderType) {
                    case 'wagon':
                        return this.$store.state.order.wagonTabs;
                    case 'wheelset':
                        return this.$store.state.order.wheelsetTabs;
                }
                return [];
            }
        }
        ,
        methods: {
            routeToTab(tab, index) {
                this.changeCurrentTabIndex(index);
                this.$router.push('/orders/' + this.order.type.toLowerCase() + '/' + this.order.id + '/' + tab).catch(()=>{});
            },
            determineProgressColor(progressValue) {
                const foundKey = Object.keys(PROGRESS_COLORS).find(key => progressValue <= key);
                return PROGRESS_COLORS[foundKey];
            },
            ...mapMutations({
                changeCurrentTabIndex: 'order/changeCurrentTabIndex',
            }),
        }
    }
</script>