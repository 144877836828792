<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="options"
      :items-per-page="10"
      dense
      :search="search"
      :no-data-text="$t('vuetify.dataTable.noDataText')"
      :no-results-text="$t('vuetify.dataTable.noResultsText')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 20, -1],
        itemsPerPageAllText: $t('vuetify.dataTable.footer.itemsPerPageAllText'),
        itemsPerPageText: $t('vuetify.dataTable.footer.itemsPerPageText'),
        pageText: $t('vuetify.dataTable.footer.pageText'),
      }"
    >
      <template v-slot:top>
        <v-toolbar flat :color="!$vuetify.theme.dark && 'white'">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('app.search')"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                >{{ $t('defaultoptions.value.new') }}</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.value"
                        :label="$t('defaultoptions.field.key')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.text"
                        :label="$t('defaultoptions.field.text')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">{{
                  $t('app.btn.cancel')
                }}</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="!editedItem.text || !editedItem.value"
                  >{{ $t('app.btn.save') }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        <v-icon small v-if="item.id === null"> new </v-icon>
        <v-icon small v-if="item.modified && !item.delete && item.id !== null">
          modified
        </v-icon>
        <v-icon small v-if="item.delete"> delete </v-icon>
      </template>
      <template v-slot:no-data> No values </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'default-option-value-list',
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.options = this.values;
  },
  data() {
    return {
      options: [],
      dialog: false,
      search: '',
      headers: [
        { text: this.$t('defaultoptions.field.key'), value: 'value' },
        { text: this.$t('defaultoptions.field.text'), value: 'text' },
        { text: this.$t('app.actions'), value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        text: null,
        value: null,
        modified: true,
        delete: false,
        global: false,
      },
      defaultItem: {
        id: null,
        text: null,
        value: null,
        modified: true,
        delete: false,
        global: false,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t('defaultoptions.value.new')
        : this.$t('defaultoptions.value.edit');
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.options.indexOf(item);
      this.editedItem = Object.assign({ modified: true }, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.options.indexOf(item);
      if (item.id === null) {
        confirm('Are you sure you want to delete this item?') &&
          this.options.splice(index, 1);
      } else {
        item.delete = !item.delete;
        this.options.splice(index, 1, item); // needs to be splice, Object.assign doesn't trigger the vue refresh
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.options[this.editedIndex], this.editedItem);
      } else {
        this.options.push(this.editedItem);
      }
      this.close();
    },
  },
  watch: {
    values(values) {
      this.$emit('values-changed', values);
    },
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
