<template>
  <v-col class="px-0">
    <v-container fluid class="ma-0 pt-0 pb-2">
      <v-lazy>
        <v-card tile flat>
          <v-card-title class="px-0 ma-0 py-2">
            {{ $t('editorder.' + chapterName) }}
          </v-card-title>
          <v-row>
            <v-col class="my-1 px-0 pb-1 pt-2">
              <slot></slot>
            </v-col>
          </v-row>
        </v-card>
      </v-lazy>
    </v-container>
  </v-col>
</template>

<script>
export default {
  name: 'WheelsetChapterFrame',
  props: {
    chapterName: String,
  },
}
</script>

<style scoped></style>
