<template>
  <v-col class="text-center pb-0">
    <v-btn
      outlined
      @click="openConfirmDialog = true"
      :loading="isLoading"
      :disabled="isLoading"
    >
      {{ $t('editorder.takeOverAllEntries.title') }}
      <v-icon>mdi-chevron-double-right</v-icon>
    </v-btn>
    <v-dialog v-model="openConfirmDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('editorder.takeOverAllEntries.title') }}
          </span>
        </v-card-title>

        <v-card-text>
          {{ $t('editorder.takeOverAllEntries.description') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :disabled="isLoading"
            @click="openConfirmDialog = false"
          >
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="isLoading"
            :disabled="isLoading"
            @click="takeAllInputValues"
          >
            {{ $t('app.btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import orderService from '@/service/orderService';
import ioTypes from '@/constants/order/ioTypes';
import jsonPathHelper from '@/service/jsonPathHelper';
import objectHelper from '@/service/objectHelper';

export default {
  name: 'TakeOverAllEntries',
  props: {
    chapters: Object,
    inputLines: Object,
  },
  data() {
    return {
      isLoading: false,
      openConfirmDialog: false,
    };
  },
  computed: {
    ...mapState('order', ['order', 'orderUpdate']),
  },
  methods: {
    ...mapActions({
      saveOrderResult: 'order/saveOrderResult',
      calculateMatchingOrderResult: 'order/calculateMatchingOrderResult',
      updateOrder: 'order/updateOrder',
    }),
    async takeAllInputValues() {
      this.isLoading = true;
      if (this.inputLines) {
        // some pages only have input Fields
        let keys;
        if (this.inputLines.listPath) {
          keys = this.resolveKeys(
            this.inputLines.listPath,
            this.inputLines.differentiator,
            undefined
          );
        } else {
          keys = [0];
        }
        for (const index in keys) {
          for (const conf of this.inputLines.data) {
            if (
              conf.ioType !== ioTypes.INCOMING &&
              conf.ioType !== ioTypes.OUTGOING
            ) {
              const incomingAttribute = orderService.resolveOrderAttribute(
                this.order,
                conf.path,
                'Incoming',
                index,
                this.inputLines.listPath,
                undefined,
                conf.isAbsolutePath
              );
              if (incomingAttribute) {
                await this.takeInputValue(
                  incomingAttribute,
                  conf,
                  this.inputLines,
                  index,
                  undefined
                );
              }
            }
          }
        }
      } else if (this.chapters) {
        // some pages have multiple chapters
        let keys;
        for (const chapter of Object.values(this.chapters)) {
          if (chapter.listPath) {
            // it is a collection
            keys = this.resolveKeys(
              chapter.listPath,
              chapter.differentiator,
              undefined
            );
            if (!keys.length) keys = [0]; // if cant resolve keys, e.g. Lastgrenzraster
          } else {
            keys = [0];
          }
          for (const index in keys) {
            const pathPrefix = chapter.listPath
              ? `${chapter.listPath}[${index}]`
              : undefined;
            if ('chapters' in chapter) {
              // iterate above inner structure
              let innerKeys;
              for (const innerChapter of Object.values(chapter.chapters)) {
                if (innerChapter.listPath) {
                  innerKeys = this.resolveKeys(
                    innerChapter.listPath,
                    innerChapter.differentiator,
                    pathPrefix
                  );
                } else {
                  innerKeys = [0];
                }
                for (const innerIndex in innerKeys) {
                  for (const conf of innerChapter.data) {
                    if (
                      conf.ioType !== ioTypes.INCOMING &&
                      conf.ioType !== ioTypes.OUTGOING
                    ) {
                      const incomingAttribute =
                        orderService.resolveOrderAttribute(
                          this.order,
                          conf.path,
                          'Incoming',
                          innerIndex,
                          innerChapter.listPath,
                          pathPrefix,
                          conf.isAbsolutePath
                        );
                      if (incomingAttribute) {
                        await this.takeInputValue(
                          incomingAttribute,
                          conf,
                          innerChapter,
                          innerIndex,
                          pathPrefix
                        );
                      }
                    }
                  }
                }
              }
            } else {
              // there is no inner structure
              for (const conf of chapter.data) {
                if (
                  conf.ioType !== ioTypes.INCOMING &&
                  conf.ioType !== ioTypes.OUTGOING
                ) {
                  const incomingAttribute = orderService.resolveOrderAttribute(
                    this.order,
                    conf.path,
                    'Incoming',
                    index,
                    chapter.listPath,
                    undefined,
                    conf.isAbsolutePath
                  );
                  if (incomingAttribute) {
                    await this.takeInputValue(
                      incomingAttribute,
                      conf,
                      chapter,
                      index,
                      undefined
                    );
                  }
                }
              }
            }
          }
        }
      }
      await this.updateOrder();
      this.isLoading = false;
      this.openConfirmDialog = false;
    },
    async takeInputValue(
      incomingAttribute,
      conf,
      chapter,
      collectionIndex,
      pathPrefix
    ) {
      if (Array.isArray(incomingAttribute)) {
        const valueList = incomingAttribute.map((entry) => ({
          value: entry.value ? entry.value : entry,
          type: conf.optionType || conf.option,
          CaptureDate: this.$moment().utc().toISOString(),
        }));
        await this.changeResultValue(
          valueList,
          'Outgoing',
          conf,
          chapter,
          collectionIndex,
          pathPrefix
        );
        orderService.enrichLocalLastModifierIfAllowed(
          this.$store.state,
          valueList
        );
        this.outgoingAttribute = valueList;
      } else {
        const val =
          conf.type === 'baseString'
            ? this.incomingAttribute
            : {
                value: incomingAttribute.value,
                unit: conf.unit,
                type: conf.optionType || conf.option,
                CaptureDate: this.$moment().utc().toISOString(),
              };
        await this.changeResultValue(
          val,
          'Outgoing',
          conf,
          chapter,
          collectionIndex,
          pathPrefix
        );
        orderService.enrichLocalLastModifierIfAllowed(this.$store.state, val);
        this.outgoingAttribute = val;
      }
    },
    async changeResultValue(
      resultValue,
      incomingOrOutgoing,
      conf,
      chapter,
      collectionIndex,
      pathPrefix
    ) {
      const orderType = this.order.type.toLowerCase();
      const orderId = this.order.id;
      const path = conf.path;
      const key = jsonPathHelper.getJsonPathKey(
        orderType,
        path,
        incomingOrOutgoing,
        chapter.listPath,
        collectionIndex,
        pathPrefix
      );
      const activityType = conf.activityType || 'XML_MODIFICATION';
      const sanitizedResultValue =
        conf.type === 'baseString'
          ? resultValue
          : objectHelper.removeUndefined(resultValue);
      await this.saveOrderResult({
        orderType,
        orderId,
        key,
        resultValue: sanitizedResultValue,
        activityType,
        reloadView: false,
        skipProgressUpdate: true,
      });
      if (conf.invoke) {
        //TODO
        this.calculateMatchingOrderResult({
          orderType,
          orderId,
          conf,
          incomingOrOutgoing,
          collectionPath: chapter.listPath,
          collectionIndex: collectionIndex,
          pathPrefix: pathPrefix,
          CaptureDate: resultValue.CaptureDate,
        });
      }
    },
    resolveKeys(listPath, differentiator, pathPrefix) {
      const params = {
        order: this.order,
        path: listPath,
        prePath: pathPrefix,
        type: 'Incoming',
        differentiator: differentiator,
      };
      return orderService.resolveDifferentiatorValues(params);
    },
  },
};
</script>

<style scoped></style>
