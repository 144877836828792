/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import ROLES from '@/constants/user/roles';

export default [
  {
    path: '/',
    view: 'StartPage',
    meta: {
      authorize: [
        ROLES.ROLE_VERS_ADMIN,
        ROLES.ROLE_WORKSHOP_ADMIN,
        ROLES.ROLE_WORKSHOP_STAFF,
        ROLES.ROLE_WORKSHOP_WAGON_QA,
        ROLES.ROLE_WORKSHOP_WHEELSET_QA,
      ],
    },
  },
  {
    path: '/orders',
    view: 'Orders',
    meta: {
      authorize: [
        ROLES.ROLE_WORKSHOP_ADMIN,
        ROLES.ROLE_WORKSHOP_STAFF,
        ROLES.ROLE_WORKSHOP_WAGON_QA,
        ROLES.ROLE_WORKSHOP_WHEELSET_QA,
      ],
    },
  },
  {
    path: '/login',
    view: 'Login',
    props: true,
  },
  {
    path: '/profile',
    view: 'Profile',
  },
  {
    path: '/defaultoptions',
    view: 'DefaultOptions',
    meta: {
      authorize: [ROLES.ROLE_VERS_ADMIN, ROLES.ROLE_ADMIN],
    },
    comp: () =>
      import(/* webpackChunkName: "admin" */ '@/views/DefaultOptions'),
  },
  {
    path: '/defaultoptions/:optionId',
    view: 'EditDefaultOption',
    comp: () =>
      import(/* webpackChunkName: "admin" */ '@/views/EditDefaultOption'),
    props: true,
    meta: {
      authorize: [ROLES.ROLE_VERS_ADMIN, ROLES.ROLE_ADMIN],
    },
  },
  {
    path: '/camera/:orderType/:orderId/:tabName',
    view: 'Camera',
    props: true,
    meta: {
      authorize: [
        ROLES.ROLE_WORKSHOP_ADMIN,
        ROLES.ROLE_WORKSHOP_STAFF,
        ROLES.ROLE_WORKSHOP_WAGON_QA,
        ROLES.ROLE_WORKSHOP_WHEELSET_QA,
      ],
    },
  },
  {
    path: '/orders/add',
    view: 'AddOrder',
    meta: {
      authorize: [
        ROLES.ROLE_WORKSHOP_ADMIN,
        ROLES.ROLE_WORKSHOP_STAFF,
        ROLES.ROLE_WORKSHOP_WAGON_QA,
        ROLES.ROLE_WORKSHOP_WHEELSET_QA,
      ],
    },
  },
  {
    path: '/orders/:orderType/:orderId/:tabName',
    view: 'EditOrder',
    props: true,
    meta: {
      authorize: [
        ROLES.ROLE_WORKSHOP_ADMIN,
        ROLES.ROLE_WORKSHOP_STAFF,
        ROLES.ROLE_WORKSHOP_WAGON_QA,
        ROLES.ROLE_WORKSHOP_WHEELSET_QA,
      ],
    },
  },
  {
    path: '/orders/:orderType/:orderId',
    view: 'EditOrder',
    props: true,
    meta: {
      authorize: [
        ROLES.ROLE_WORKSHOP_ADMIN,
        ROLES.ROLE_WORKSHOP_STAFF,
        ROLES.ROLE_WORKSHOP_WAGON_QA,
        ROLES.ROLE_WORKSHOP_WHEELSET_QA,
      ],
    },
  },
  {
    path: '/assets/:orderId/:orderType/:anzahlTeilfahrzeuge?',
    view: 'OrderAssets',
    props: true,
    meta: {
      authorize: [
        ROLES.ROLE_WORKSHOP_ADMIN,
        ROLES.ROLE_WORKSHOP_STAFF,
        ROLES.ROLE_WORKSHOP_WAGON_QA,
        ROLES.ROLE_WORKSHOP_WHEELSET_QA,
      ],
    },
  },
  {
    path: '/images/:orderId',
    view: 'OrderImages',
    props: true,
    meta: {
      authorize: [
        ROLES.ROLE_WORKSHOP_ADMIN,
        ROLES.ROLE_WORKSHOP_STAFF,
        ROLES.ROLE_WORKSHOP_WAGON_QA,
        ROLES.ROLE_WORKSHOP_WHEELSET_QA,
      ],
    },
  },
  {
    path: '/users',
    view: 'UserManagement',
    comp: () =>
      import(/* webpackChunkName: "admin" */ '@/views/UserManagement'),

    meta: {
      authorize: [
        ROLES.ROLE_VERS_ADMIN,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_WORKSHOP_ADMIN,
      ],
    },
  },
  {
    path: '/workshop/:workshopId',
    view: 'Workshop',
    props: true,
    comp: () => import(/* webpackChunkName: "admin" */ '@/views/Workshop'),
    meta: {
      authorize: [
        ROLES.ROLE_VERS_ADMIN,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_WORKSHOP_ADMIN,
      ],
    },
  },
];
