const DB_NAME = "versdb";
const DB_VERSION = 2;
let DB;

const OBJECT_STORES = [
  {
    table: "defaultOptions",
    keyPath: { keyPath: ["key", "lang"] },
    indexes: [
      { name: "id", indexOptions: { unique: false } },
      { name: "lang", indexOptions: { unique: false } },
    ],
  },
  {
    table: "orders",
  },
  {
    table: "orderImages",
    indexes: [
      { name: "orderId", indexOptions: { unique: false } },
      { name: "sent", indexOptions: { unique: false } },
      { name: "toBeDeleted", indexOptions: { unique: false } },
    ],
  },
  {
    table: "orderResults",
    indexes: [
      { name: "orderId", indexOptions: { unique: false } },
      { name: "sent", indexOptions: { unique: false } },
    ],
  },
];

export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB);
      }
      let request = window.indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (e) => {
        console.error("Error opening db", e);
        alert(
          "Es gab einen Fehler beim Laden der Datenbank, bitte prüfen Sie, dass sie nicht im privaten Modus arbeiten"
        );
        reject("Error");
      };

      request.onsuccess = (e) => {
        DB = e.target.result;
        resolve(DB);
      };

      request.onupgradeneeded = (e) => {
        console.info("onupgradeneeded");
        let db = e.target.result;
        if (e.oldVersion < 1) {
          OBJECT_STORES.forEach((conf) => {
            const keyPath = conf.keyPath || {
              keyPath: "id",
              autoIncrement: true,
            };
            let objectStore = db.createObjectStore(conf.table, keyPath);
            if (conf.indexes) {
              conf.indexes.forEach((index) => {
                if (index.keyPath)
                  objectStore.createIndex(
                    index.name,
                    index.keyPath,
                    index.indexOptions
                  );
                else
                  objectStore.createIndex(
                    index.name,
                    index.name,
                    index.indexOptions
                  );
              });
            }
          });
        }
        if (e.oldVersion < 2) {
          request.transaction
            .objectStore("orders")
            .createIndex("sent", "sent", { unique: false });
          request.transaction
            .objectStore("orderResults")
            .createIndex("sent, orderId", ["sent", "orderId"], {
              unique: false,
            });
        }
      };
    });
  },
  async getById(tableName, id) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction([tableName], "readonly");
      trans.oncomplete = () => {
        resolve(item);
      };

      let store = trans.objectStore(tableName);
      const request = store.get(id);
      let item = null;
      request.onsuccess = () => {
        item = request.result;
      };
    });
  },
  async getOrderById(orderId) {
    return this.getById("orders", orderId);
  },
  async getUnsentOrders() {
    return this.getByIndex("orders", "sent", 0);
  },
  async getOrderResultById(orderResultId) {
    return this.getById("orderResults", orderResultId);
  },

  async getByIndex(tableName, index, query) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction([tableName], "readonly");
      trans.oncomplete = () => {
        resolve(results);
      };

      let store = trans.objectStore(tableName);
      let results = [];

      store.index(index).openCursor(query).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          results.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
  async getOrderImages(orderId) {
    const images = await this.getByIndex("orderImages", "orderId", orderId);
    return images.filter((image) => image.toBeDeleted !== 1);
  },
  async getOrderResults(orderId) {
    return this.getByIndex("orderResults", "orderId", orderId);
  },
  async getUnsentOrderResults() {
    return this.getByIndex("orderResults", "sent", 0);
  },
  async getUnsentOrderResultsById(orderId) {
    const sent = 0;
    const sentAndOrderID = [sent, orderId];
    return this.getByIndex("orderResults", "sent, orderId", sentAndOrderID);
  },
  async getUnsentOrderImages() {
    return this.getByIndex("orderImages", "sent", 0);
  },
  async getOrderImagesToDelete() {
    return this.getByIndex("orderImages", "toBeDeleted", 1);
  },
  async getDefaultOptionsByLang(lang) {
    return this.getByIndex("defaultOptions", "lang", lang);
  },
  async getDefaultOptionsByKeyAndLang(keyAndLang) {
    return this.getByIndex("defaultOptions", "key, lang", keyAndLang);
  },
  async getDefaultOptionById(id) {
    return this.getByIndex("defaultOptions", "id", id);
  },
  async getAll(tableName) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction([tableName], "readonly");
      trans.oncomplete = () => {
        resolve(items);
      };

      let store = trans.objectStore(tableName);
      let items = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          items.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
  async getOrders() {
    return this.getAll("orders");
  },
  async getDefaultOptions() {
    return this.getAll("defaultOptions");
  },
  async saveAll(tableName, items) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction([tableName], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore(tableName);
      items.forEach((item) => {
        store.put(item);
      });
    });
  },
  async saveOrders(orders) {
    return this.saveAll("orders", orders);
  },
  async saveDefaultOptions(defaultOptions) {
    return this.saveAll("defaultOptions", defaultOptions);
  },
  async save(tableName, item) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction([tableName], "readwrite");

      let store = trans.objectStore(tableName);
      const request = store.put(item);
      request.onsuccess = (e) => {
        resolve(e.target.result);
      };
    });
  },
  async saveOrder(order) {
    return this.save("orders", order);
  },
  async saveOrderImage(orderImage) {
    return this.save("orderImages", orderImage);
  },
  async saveOrderResult(orderResult) {
    return this.save("orderResults", orderResult);
  },
  async saveOrderResults(orderResults) {
    return this.saveAll("orderResults", orderResults);
  },
  async delete(tableName, id) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction([tableName], "readwrite");
      trans.oncomplete = () => {
        resolve(id);
      };

      let store = trans.objectStore(tableName);
      store.delete(id);
    });
  },
  async deleteOrderImage(id) {
    return this.delete("orderImages", id);
  },
  async deleteOrder(id) {
    return this.delete("orders", id);
  },
  async deleteOrderResult(id) {
    return this.delete("orderResults", id);
  },
  async deleteOrderResultsForOrderId(orderId) {
    const orderResults = await this.getOrderResults(orderId);
    for (const orderResult of orderResults) {
      await this.deleteOrderResult(orderResult.id);
    }
  },
  async deleteDefaultOption(keyAndLang) {
    return this.delete("defaultOptions", keyAndLang);
  },
};
