const root = '/api';
const v1 = `${root}/v1`;

const apiEndpoints = {
    assets: `${v1}/assets`,
    defaultOptions: `${v1}/default-options`,
    defaultOptionsWithValues: `${v1}/default-options-with-values`,
    orders: `${v1}/orders`,
    activity: `${v1}/activity`,
    toggles: `${v1}/toggles`,
    auth: `${v1}/auth`,
    export: `${v1}/export`,
    users: `${v1}/users`,
    workshop: `${v1}/workshops`,
    version: `${v1}/version`,
    emailAddress: `${v1}/emailAddress`,
};
export default apiEndpoints;
