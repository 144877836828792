import api from './api'
import Vue from "vue";
import idb from "../store/idb";
import apiEndpoints from "@/constants/api/apiEndpoints";


export default {
    async getBase64StrFromFile(file) {
        const dataUrl = await this.convertImageToBase64Str(file)
        return dataUrl.replace('data:', '')
            .replace(/^.+,/, '');
    },

    async uploadXmlImport(base64Str, fileName) {
        let assetResponse = await api().post(apiEndpoints.assets, {
            filename: fileName,
            base64: base64Str,
            size: -1,
            id: '',
            type: "IMPORT",
        });

        if (assetResponse !== undefined && assetResponse.status === 200) {
            return assetResponse.data.id;
        }
        return null;
    },
    async getValidationStatus(assetId, orderType) {
        return await api().get(`${apiEndpoints.assets}/validation-result/${orderType}/${assetId}`);
    },
    checkValidationStatusPeriodically({commit, dispatch}, template, type) {
        setTimeout(() => {
            this.getValidationStatus(template.assetId, type).then(response => {
                if (response.status === 200) {
                    Vue.set(template, 'validation', response.data)

                    if(!template.validActionCallback) {
                        commit(template.updateCallback, template);
                    }

                    if(response.data.validationStatus === 'IN_VALIDATION') {
                        this.checkValidationStatusPeriodically({commit, dispatch}, template, type)
                    } else if (response.data.validationStatus === 'VALID' && template.validActionCallback) {
                        dispatch(template.validActionCallback, {template })
                    }
                }
            })
        }, 2000)
    },
    async convertImageToBase64Str(imageBlob) {
        let reader = new FileReader();
        reader.readAsDataURL(imageBlob); // converts the blob to base64 and calls onload

        return new Promise(resolve => {
            reader.onload = function () {
                resolve(reader.result);
            };
        });
    },
    async getOrderImagesFromDB(orderId) {
        return await idb.getOrderImages(orderId) || [];
    },
    async saveOrderImageToDB(orderImage) {
        await idb.saveOrderImage(orderImage)
    },
    async deleteOrderImageFromDB(image) {
        await idb.deleteOrderImage(image.id)
    },
    async saveOrderImageToAPI(image) {
        const cleanBase64 = image.data.replace(/.*,/, '');
        const assetWithDataDTO = {
            base64: cleanBase64,
            id: '',
            type: 'PICTURE',
            filename: image.name,
            size: -1,
            orderId: image.orderId,
            creator: null,
            created: image.capturedDate
        };
        const resultResponse = await api().post(apiEndpoints.assets, assetWithDataDTO).catch(err => console.error(err));
        if (resultResponse !== undefined && resultResponse.status === 200) {
            image.imageId = resultResponse.data.id;
            image.sent = 1;
        }
        return image;
    },
    async getAssetDTOsFromApi(orderId) {
        return await api().get(`${apiEndpoints.assets}/order/${orderId}`).catch(err => console.error(err));
    },
    async getAssetFromApi(assetId) {
        return await api().get(`${apiEndpoints.assets}/${assetId}`).catch(err => console.error(err));
    },
    async downloadAssetFromApi(assetId) {
        return await api().get(`${apiEndpoints.assets}/download/${assetId}`, {responseType: "blob"}).catch(err => err);
    },
    async deleteAssetFromApi(assetId) {
        const resultResponse = await api().delete(`${apiEndpoints.assets}/${assetId}`).catch(err => console.error(err));
        return resultResponse !== undefined && resultResponse.status === 204;
    },
    async generateReportDocuments({language, exportTypes, orderId}) {
        return await api().post(`${apiEndpoints.export}/${orderId}/${language}`, exportTypes).catch(err => err);
    },
    async getAssetsByIdFromApi(ids) {
        return await api().get(`${apiEndpoints.assets}/?ids=${ids.join(',')}`);
    }
}
