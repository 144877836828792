<template>
  <div v-if="order && unsentOrderEvents > 0 && isOffline" style="position: relative">
    <span>{{ $tc('app.unsent', unsentOrderEvents) }}</span>
    <span style="right: 0;position: absolute"><v-icon>mdi-wifi-off</v-icon></span>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import api from "@/service/api";
  import apiEndpoints from "@/constants/api/apiEndpoints";
  import orderService from "@/service/orderService";
export default {
  name: "core-scheduler",
  computed: {
    unsentOrderEvents() {
      return this.$store.state.order.orderResults.filter(res => !res.sent).length
    },
    ...mapState('order', ['order']),
    ...mapState(['authenticated']),
  },
  data() {
    return {
      send: null,
      getVersion: null,
      stillRunning: false,
      isOffline: false,
    }
  },
  methods: {
    sendData() {
      this.send = setInterval(async() => this.exchangeData(), 10000)
    },
    async exchangeData() {
      this.isOffline = await orderService.checkOnlineStatus()
      // we should never run this twice parallel, so some items would be send twice
      // skip excecution if stillRunning
      if(this.authenticated && !this.stillRunning) {
        this.stillRunning = true
        const syncParams = {
          isOffline: this.isOffline
        }
        await this.sendUnsentOrders(syncParams);

        if(this.order) {
          syncParams.orderId = this.order.id;
          await this.sendOrderResults(syncParams);
          await this.sendOrderImages(syncParams);
          await this.deleteOrderImages(syncParams);
          await this.synchronizeOrderResults(syncParams);
        }
        this.stillRunning = false
      }
    },
    backendVersion() {
      this.getVersion = setInterval(async() => this.getBackendVersion(), 1000*60*60);
    },
    async getBackendVersion() {
        if(this.authenticated && !this.isOffline) {
          const response = await api().get(`${apiEndpoints.version}/current`).catch(err => console.error(err));
          if(response && response.status === 200) {
            localStorage.setItem('backVersion', response.data.gitSha)
            window.dispatchEvent(new CustomEvent('backVersion-changed', {
              detail: {
                backVersion: localStorage.getItem('backVersion')
              }
            }));
          }
        }
    },
    ...mapActions({
      sendOrderImages: 'order/sendOrderImages',
      deleteOrderImages: 'order/deleteOrderImages',
      sendOrderResults: 'order/sendOrderResults',
      synchronizeOrderResults: 'order/synchronizeOrderResults',
      sendUnsentOrders: 'order/sendUnsentOrders'
    })
  },
  watch: {
    $route(to, from) {
      if(from.name === "EditOrder") {
        clearInterval(this.send);
        this.exchangeData();
        this.sendData();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.send);
    clearInterval(this.getVersion);
  },
  created() {
    this.sendData();
    this.getBackendVersion();
    this.backendVersion();
  }
}
</script>

<style scoped>

</style>
