<template>
    <v-snackbar v-model="show" :color="color" :timeout="timeout" right top>
        {{message}}

        <template #action>
            <v-btn text :color="btnColor" @click.native="show = false">{{$t('app.ok')}}</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        data () {
            return {
                show: false,
                color: 'success',
                btnColor: 'accent',
                message: '',
                timeout: 5000
            }
        },
        created: function () {
            this.$store.watch(state => state.snackbar.notification, () => {
                const msg = this.$store.state.snackbar.notification.message;
                if (msg !== '') {
                    this.show = true;
                    this.color= this.$store.state.snackbar.notification.color;
                    this.btnColor= this.$store.state.snackbar.notification.btnColor;
                    this.message = msg;
                    this.timeout = this.$store.state.snackbar.notification.timeout;
                }
            })
        }
    }
</script>