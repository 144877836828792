var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hideCollection || _vm.completeUniform)?_c('v-card',{style:('border-color:' + _vm.getBorderColor()),attrs:{"outlined":"","height":"100%"}},[_c('v-card-text',{staticClass:"pa-2"},[_vm._v(_vm._s(_vm.lineLabel))]),_c('v-container',{staticClass:"ma-0",attrs:{"fluid":""}},[(_vm.ioType === _vm.ioTypes.INCOMING)?_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('input-component',{attrs:{"data":_vm.incomingAttribute,"conf":_vm.conf,"isDifferentiator":_vm.isDifferentiator,"type":"Incoming","useSelfCheckNumber":_vm.useSelfCheckNumber,"disabled":_vm.disabled},on:{"input":_vm.changeIncomingValue}})],1)],1):(_vm.ioType === _vm.ioTypes.OUTGOING)?_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('input-component',{attrs:{"data":_vm.outgoingAttribute,"conf":_vm.conf,"isDifferentiator":_vm.isDifferentiator,"type":"Outgoing","useSelfCheckNumber":_vm.useSelfCheckNumber,"disabled":_vm.disabled},on:{"input":_vm.changeOutgoingValue}})],1)],1):_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{staticClass:"text-center pa-2 ma-0",attrs:{"xs":"5"}},[_c('input-component',{attrs:{"data":_vm.incomingAttribute,"conf":_vm.conf,"isDifferentiator":_vm.isDifferentiator,"type":"Incoming","useSelfCheckNumber":_vm.useSelfCheckNumber,"disabled":_vm.disabled},on:{"input":_vm.changeIncomingValue}})],1),_c('v-col',{staticClass:"text-center ma-0 px-0",attrs:{"xs":"2"}},[_c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"outlined":"","min-width":_vm.$vuetify.breakpoint.xs ? 50 : 100,"max-width":_vm.$vuetify.breakpoint.xs ? 50 : 100,"tabindex":"-1","disabled":_vm.incomingAttribute === undefined ||
              _vm.incomingAttribute === '' ||
              (_vm.incomingAttribute && _vm.incomingAttribute.value === '') ||
              _vm.conf.type === 'formula' ||
              _vm.disabled},on:{"click":_vm.acceptInputValue}},[_c('v-icon',{staticClass:"mr-0 pr-0"},[_vm._v("mdi-text-box-check-outline")])],1)],1),_c('div',[_c('v-btn',{attrs:{"outlined":"","min-width":_vm.$vuetify.breakpoint.xs ? 50 : 100,"max-width":_vm.$vuetify.breakpoint.xs ? 50 : 100,"tabindex":"-1","disabled":_vm.incomingAttribute === undefined ||
              _vm.incomingAttribute === '' ||
              (_vm.incomingAttribute && _vm.incomingAttribute.value === '') ||
              _vm.conf.type === 'formula' ||
              _vm.disabled},on:{"click":_vm.takeInputValue}},[_c('v-icon',[_vm._v("mdi-chevron-double-right")])],1)],1)]),_c('v-col',{staticClass:"text-center ma-0",attrs:{"xs":"5"}},[_c('input-component',{attrs:{"data":_vm.outgoingAttribute,"conf":_vm.conf,"isDifferentiator":_vm.isDifferentiator,"type":"Outgoing","useSelfCheckNumber":_vm.useSelfCheckNumber,"disabled":_vm.disabled ||
            (_vm.collectionPath === 'WheelCollection.Wheel' && _vm.isDifferentiator)},on:{"input":_vm.changeOutgoingValue}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }