// https://vuex.vuejs.org/en/getters.html

export default {
  defaultOptions(state) {
    return state.defaultOptions;
  },
  hasRole: (state) => (requiredRole) => {
    return state.roles.find((role) => role === requiredRole) !== undefined;
  },
  hasAnyRole: (state) => (roles) => {
    return state.roles.find((role) => roles.includes(role));
  },
  getDefaultOptionById: (state) => (id) => {
    return state.defaultOptions.find((option) => option.id === id);
  },
  getDefaultOptionByKey: (state) => (key) => {
    return state.defaultOptions.find(
      (option) => option.key.toLowerCase() === key.toLowerCase()
    );
  },
  defaultOptionsLoaded(state) {
    return state.defaultOptionsLoaded;
  },
};
