<template>
  <v-select
      v-model="languageCodeValue"
      :items="locales"
      :label="showLabel ? $t('language.label') : ''"
      :rules="[rules.required]"
      @change="changeLanguageCode"
      :style="customStyle"
      :filled="isFilled"
  >
    <template v-slot:item="data">
      <v-img
          max-width="60"
          :aspect-ratio="16/9"
          :src="'/img/flags/' + data.item.value + '.svg'"
          :alt="data.item.text"
      />
      <span style="margin-left: 10px">{{data.item.text}}</span>
    </template>
  </v-select>
</template>

<script>
import LANGUAGES from "@/constants/languages";

export default {
  name: "LanguageSelection",
  data() {
    return {
      languageCodeValue: null,
      rules: {
        required: value => !!value || this.$t('usermanagement.editDialog.rules.required'),
      }
    }
  },
  methods: {
    changeLanguageCode() {
      this.$emit('input', this.languageCodeValue);
    }
  },
  computed: {
    locales: function () {
      return LANGUAGES.map(lang => ({value: lang, text: this.$t('language.' + lang)}));
    }
  },
  props: {
    languageCode: {
      type: String,
      default: null
    },
    customStyle: {
      type: String,
      default: ''
    },
    isFilled: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    languageCode: {
      immediate: true,
      handler(newVal) {
        this.languageCodeValue = newVal;
      }
    }
  }
}
</script>

<style scoped>

</style>