<template>
  <div>
    <edit-order-take-over-all-entries :chapters="chapters" />
    <edit-order-collection-input-container :inputLines="chapters.bearing" />
    <edit-order-chapter-frame chapter-name="wheelset.tab.bearingHousing">
      <edit-order-collection-input-container
        :inputLines="chapters.bearingHousing"
        :split-view="true"
      />
    </edit-order-chapter-frame>
  </div>
</template>

<script>
import inputValues from '../../constants/order/inputValues'

export default {
  data() {
    return {
      chapters: inputValues.wheelset.bearing.chapters,
    }
  },
  name: 'WheelsetBearing',
}
</script>

<style scoped></style>
