import emailAddressService from "@/service/emailAddressService";

const actions = {
    async getEmailAddresses({commit}) {
        const response = await emailAddressService.getEmailAddressesFromApi();
        if (response !== undefined && response.status === 200) {
            commit('setEmailAddresses', response.data)
            return true;
        }
    },
    async createEmailAddress({commit}, emailAddress) {
        const response = await emailAddressService.createEmailAddress(emailAddress);
        if (response !== undefined && response.status === 201) {
            commit('addEmailAddress', response.data);
            return response.data;
        } else if (response !== undefined && response.status === 409) {
            console.error('ERROR: Email address already exists')
            return false;
        } else {
            console.error('Error while creating new email address. Reponse:')
            console.error(response)
            return false;
        }
    },
    async updateEmailAddress({commit}, emailAddress) {
        const response = await emailAddressService.updateEmailAddress(emailAddress);
        if (response !== undefined && response.status === 200) {
            commit('replaceEmailAddress', response.data);
            return true;
        } else {
            console.error('Error while updating email address. Reponse:')
            console.error(response)
            return false;
        }
    },
    async deleteEmailAddress({commit}, emailAddress) {
        const response = await emailAddressService.deleteEmailAddress(emailAddress.id);
        if (response !== undefined && response.status === 204) {
            commit('removeEmailAddress', emailAddress);
            return true;
        } else {
            console.error('Error while deleting email address. Reponse:')
            console.error(response)
            return false;
        }
    },
};

export default actions;