<template>
    <v-container>
        <v-row>
            {{label}}
        </v-row>
        <v-row
                v-for="(item,index) in wheelsets"
                :key="index"
        >
            <v-col md="2" sm="1" xs="1" align-self="center">
                <v-avatar color="grey" size="30">
                    <span class="white--text headline">{{index+1}}</span>
                </v-avatar>
            </v-col>
            <v-col md="9" sm="10" xs="10">
                <v-text-field
                        v-model="item.Wheelsetnumber.value"
                        :loading="getValidation(index) && getValidation(index).file && !getValidation(index).validation"
                        :error-messages="getErrorMessage(index)"
                        :append-icon="getValidationIcon(index)"
                        :label="$t('editorder.Wheelsetnumber')"
                        :disabled="disabled"
                        @change="changeWheelsetnumber(index)"
                >
                </v-text-field>
            </v-col>
            <v-col md="1" sm="1" xs="1">
                <v-file-input
                        @change="validateImportFile({
                            file: $event, type:'WHEELSET', index,
                            updateCallback: 'updateEditWagonWheelset',
                            wagonType, validActionCallback:'patchWheelsetToWagon'
                        })"
                        hide-input accept="application/xml"
                        :disabled="disabled"></v-file-input>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>

    import {mapActions, mapState} from "vuex";

    export default {
        name: "fileInput",
        props: {
            wagonType: String,
            label: String,
            disabled: Boolean,
        },
        computed: {
            ...mapState('order', ['order', 'editOrderWheelsetValidations']),
            wheelsets() {
                const wheelsets = this.$store.state.order.order.Body.Wagen[`technicalWaggon${this.wagonType}`].Understructure.WheelsetCollection.Wheelset;
                for (const item of wheelsets){
                  if(!item.Wheelsetnumber) item.Wheelsetnumber = {value: ""};
                }
                return wheelsets;
            },
        },
        methods: {
            async changeWheelsetnumber(index) {
                await this.patchWheelsetnumber({index: index, value: this.wheelsets[index].Wheelsetnumber.value, incomingOrOutgoing: this.wagonType});
            },
            getErrorMessage(index) {
                const validation = this.getValidation(index);
                if (validation.validation && validation.validation.validationStatus === 'INVALID') {
                    return this.$t(validation.validation.messageKey)
                }
                return undefined
            },
            getValidation(index) {
                return this.editOrderWheelsetValidations[`${this.wagonType}${index}`] || {};
            },
            getValidationIcon(index) {
                const validation = this.getValidation(index);
                if (validation.validation) {
                    switch (validation.validation.validationStatus) {
                        case 'INVALID':
                            return 'mdi-alert-circle';
                        case 'VALID':
                            return 'mdi-check-circle';
                    }
                }
                return undefined
            },
            ...mapActions({
                validateImportFile: 'order/validateImportFile',
                patchWheelsetnumber: 'order/patchWheelsetnumber'
            })
        }
    }
</script>

<style scoped>

</style>