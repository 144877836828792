import workshopService from '@/service/workshopService';

const actions = {
  async getWorkshopById({ commit }, workshopId) {
    const response = await workshopService.getWorkshopFromApi(workshopId);
    if (response !== undefined && response.status === 200) {
      commit('setWorkshop', response.data);
      return true;
    }
    return false;
  },
  async getWorkshopIcon({ commit }, workshopId) {
    const response = await workshopService.getWorkshopIcon(workshopId);
    if (response !== undefined && response.status === 200) {
      const base64ImageString = `data:image/png;base64,${response.data}`;
      commit('setWorkshopIcon', base64ImageString);
      return true;
    }
    return false;
  },
  async updateWorkshop({ commit }, workshop) {
    const response = await workshopService.updateWorkshop(workshop);
    if (response !== undefined && response.status === 200) {
      commit('setWorkshop', response.data);
      return true;
    }
    return false;
  },
  async updateWorkshopIcon({ commit }, { workshopId, base64Image }) {
    const response = await workshopService.updateWorkshopIcon(
      workshopId,
      base64Image
    );
    if (response !== undefined && response.status === 200) {
      commit('setWorkshopIcon', base64Image);
      return true;
    }
    return false;
  },
};

export default actions;
