<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="drawer"
    app
    dark
    style="background-color: #005087; overflow-y: hidden"
    floating
    persistent
    mobilebreak-point="991"
    width="260"
  >
    <v-list>
      <v-list-item>
        <v-img src="/img/logo.png" alt="VERS" style="margin-bottom: 10px" />
      </v-list-item>
      <v-divider />
      <v-list-item
        to="/orders"
        v-if="
          hasRole(roles.ROLE_WORKSHOP_STAFF) ||
          hasRole(roles.ROLE_WORKSHOP_WAGON_QA) ||
          hasRole(roles.ROLE_WORKSHOP_WHEELSET_QA)
        "
        class="v-list-item"
      >
        <v-list-item-icon>
          <v-icon>mdi-apple-icloud</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('orders.title') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/profile" class="v-list-item">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('profile.title') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="hasRole(roles.ROLE_WORKSHOP_ADMIN)" />
      <v-list-item
        :to="'/workshop/' + currentWorkshopId"
        v-if="hasRole(roles.ROLE_WORKSHOP_ADMIN)"
        class="v-list-item"
      >
        <v-list-item-icon>
          <v-icon>mdi-home-city</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('workshop.title') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        to="/users"
        v-if="hasRole(roles.ROLE_WORKSHOP_ADMIN)"
        class="v-list-item"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $t('usermanagement.title')
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item
        to="/defaultoptions"
        v-if="hasRole(roles.ROLE_VERS_ADMIN)"
        class="v-list-item"
      >
        <v-list-item-icon>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $t('defaultoptions.title')
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="v-list-item"
        v-if="authenticated"
        @click="logoutAndRedirect"
      >
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('app.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-footer absolute inset style="background-color: #005087">
      <v-container class="font-weight-thin">
        <v-row>
          <v-switch
            v-model="$vuetify.theme.dark"
            color="accent"
            prepend-icon="mdi-weather-sunny"
            append-icon="mdi-weather-night"
            @change="saveDarkMode"
          ></v-switch>
        </v-row>
        <v-row class="pb-2 font-weight-medium">version:</v-row>
        <v-row dense @click="copyToClipboard(frontVersionCurrent)">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ getShortVersionHash(frontVersionCurrent) }}
              </span>
            </template>
            <span>current frontend: {{ frontVersionCurrent }}</span>
          </v-tooltip>
        </v-row>
        <v-row dense @click="copyToClipboard(frontVersion)">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ getShortVersionHash(frontVersion) }}
              </span>
            </template>
            <span>frontend: {{ frontVersion }}</span>
          </v-tooltip>
        </v-row>
        <v-row dense @click="copyToClipboard(backVersion)">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ getShortVersionHash(backVersion) }}
              </span>
            </template>
            <span>backend: {{ backVersion }}</span>
          </v-tooltip>
        </v-row>
      </v-container>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
// Utilities

import { mapGetters, mapMutations, mapState } from 'vuex';
import workshopService from '@/service/workshopService';
import ROLES from '@/constants/user/roles';

export default {
  mounted() {
    window.addEventListener('frontVersion-changed', (event) => {
      this.frontVersion = event.detail.frontVersion;
    });
    window.addEventListener('frontVersionCurrent-changed', (event) => {
      this.frontVersionCurrent = event.detail.frontVersionCurrent;
    });
    window.addEventListener('backVersion-changed', (event) => {
      this.backVersion = event.detail.backVersion;
    });
  },
  computed: {
    ...mapState(['authenticated']),
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    currentWorkshopId() {
      return workshopService.getCurrentWorkshopId();
    },
    ...mapGetters(['hasRole']),
  },
  data() {
    return {
      roles: ROLES,
      frontVersionCurrent:
        localStorage.getItem('frontVersionCurrent') || 'undefined',
      frontVersion: localStorage.getItem('frontVersion') || 'undefined',
      backVersion: localStorage.getItem('backVersion') || 'undefined',
    };
  },
  methods: {
    saveDarkMode() {
      localStorage.setItem(
        'darkMode',
        JSON.stringify(this.$vuetify.theme.dark)
      );
    },
    logoutAndRedirect() {
      this.logout();
      this.$router.push('/login');
    },
    getShortVersionHash(val) {
      return val.substring(0, 8);
    },
    copyToClipboard(version) {
      let dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = version;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    },
    ...mapMutations({
      setDrawer: 'app/setDrawer',
      logout: 'logout',
    }),
  },
  name: 'core-drawer',
};
</script>

<style scoped>
.row {
  font-size: small;
  opacity: 0.6;
}
</style>
