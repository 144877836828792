<template>
  <v-lazy fluid class="ma-0 pa-0">
    <v-container fluid class="mx-0 pa-1 mb-6">
      <v-row v-if="inputLines.uniformPaths">
        <v-col
          class="my-1 px-1 pb-1 pt-2"
          v-for="(conf, index) in uniformLines"
          :key="index"
          :md="conf.halfWidth ? 3 : 6"
          :cols="conf.halfWidth ? 6 : 12"
        >
          <edit-order-input-line
            :conf="conf"
            :isDifferentiator="conf.path === inputLines.differentiator"
            :order="order"
            :key="conf.path + orderUpdate"
            :collectionIndex="0"
            :collectionPath="inputLines.listPath"
            :ioType="conf.conditionalIoType ?? conf.ioType"
            :path-prefix="pathPrefix"
            :disabled="disabled"
            :uniform-line="true"
            :current-uniform="inputLines.currentUniform"
            :complete-uniform="inputLines.completeUniform"
            :differentiator="inputLines.differentiator"
            :differentiatorOption="
              inputLines.differentiatorOption || inputLines.differentiator
            "
            :uniform-paths="inputLines.uniformPaths"
          ></edit-order-input-line>
        </v-col>
      </v-row>

      <v-row
        v-if="splitView && !inputLines.fixedSet && !inputLines.completeUniform"
      >
        <core-add-differentiator-list-dialog
          :in-use="collectionKeys"
          :values="defaultOptionValues"
          :disabled="disabled"
          :diff-name="getDifferentiator()"
          :max-size="inputLines.maxSize"
          @add="addEntry"
        />
        <core-add-differentiator-list-dialog
          :in-use="collectionKeys"
          :values="defaultOptionValues"
          :disabled="disabled"
          :diff-name="getDifferentiator()"
          :deletion="true"
          :min-size="inputLines.minSize"
          @add="removeEntry"
        />
      </v-row>
      <v-row v-if="splitView && !inputLines.completeUniform">
        <v-col
          v-for="(collectionKey, collectionIndex) in collectionKeys"
          :key="collectionIndex + orderUpdate"
          class="ma-0 px-0"
          md="6"
          xs="12"
          cols="12"
        >
          <v-card tile flat>
            <v-card-title class="pa-2 text-subtitle-2">
              {{ getCardTitle(collectionKey) }}
            </v-card-title>
            <v-col
              class="my-1 px-1 pb-2 pt-0"
              v-for="(conf, index) in collectionLinesFilteredByKey(
                collectionKey
              )"
              :key="index"
            >
              <edit-order-input-line
                :conf="conf"
                :isDifferentiator="conf.path === inputLines.differentiator"
                :order="order"
                :key="conf.path + orderUpdate"
                :collectionIndex="collectionIndex"
                :collectionPath="inputLines.listPath"
                :collectionKey="collectionKey"
                :ioType="conf.conditionalIoType ?? conf.ioType"
                :path-prefix="pathPrefix"
                :disabled="disabled"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else-if="!inputLines.completeUniform && !inputLines.currentUniform"
      >
        <v-tabs v-model="activeTab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(collectionKey, collectionIndex) in collectionKeys"
            :key="'#' + collectionKey + '-' + collectionIndex"
            class="text-subtitle-2"
          >
            {{ getCardTitle(collectionKey) }}
          </v-tab>
          <core-add-differentiator-list-dialog
            :in-use="collectionKeys"
            :values="defaultOptionValues"
            :disabled="disabled"
            :diff-name="getDifferentiator()"
            :max-size="inputLines.maxSize"
            @add="addEntry"
          />
          <core-add-differentiator-list-dialog
            :in-use="collectionKeys"
            :values="defaultOptionValues"
            :disabled="disabled"
            :diff-name="getDifferentiator()"
            :deletion="true"
            :min-size="inputLines.minSize"
            @add="removeEntry"
          />
        </v-tabs>
        <v-col md="12" class="mx-0 px-1 mb-4">
          <v-tabs-items v-model="activeTab">
            <v-tab-item
              v-for="(collectionKey, collectionIndex) in collectionKeys"
              :transition="false"
              :reverse-transition="false"
              :key="'#' + collectionKey + '-' + collectionIndex"
            >
              <v-col class="px-2">
                <v-card tile flat>
                  <v-row>
                    <v-col
                      class="my-1 px-1 pb-2 pt-0"
                      md="6"
                      sm="12"
                      xs="12"
                      cols="12"
                      v-for="(conf, index) in collectionLinesFilteredByKey(
                        collectionKey
                      )"
                      :key="index"
                    >
                      <edit-order-input-line
                        :conf="conf"
                        :isDifferentiator="
                          conf.path === inputLines.differentiator
                        "
                        :order="order"
                        :key="conf.path + orderUpdate"
                        :collectionIndex="collectionIndex"
                        :collectionPath="inputLines.listPath"
                        :collectionKey="collectionKey"
                        :ioType="conf.conditionalIoType ?? conf.ioType"
                        :path-prefix="pathPrefix"
                        :disabled="disabled"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </v-lazy>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ORDER_STATUS } from '@/constants/order/orderStatus';
import orderService from '@/service/orderService';
import ioTypes from '@/constants/order/ioTypes';

export default {
  name: 'CollectionInputContainer',
  computed: {
    disabled() {
      return (
        this.order.status === ORDER_STATUS.DONE ||
        this.order.status === ORDER_STATUS.DONE_ARCHIVED
      );
    },
    defaultOptionValues() {
      if (this.inputLines.differentiator) {
        const optionType =
          this.inputLines.differentiatorOption ||
          this.inputLines.differentiator;
        const defaultOption =
          this.$store.getters.getDefaultOptionByKey(optionType);
        if (defaultOption) {
          return defaultOption.values;
        }
      }
      return [];
    },
    filteredLines() {
      // filter conditional values
      // may only show INCOMING or OUTGOING, or hide completely
      return this.inputLines.data.filter((conf) => {
        if (
          !conf.conditionalHideOutgoing?.length &&
          !conf.conditionalHideIncoming?.length
        )
          return true;
        // check if all outgoing conditions are true (if there is any)
        let hideOutgoing = conf.conditionalHideOutgoing?.length ? true : false;
        if (hideOutgoing) {
          for (const entry of conf.conditionalHideOutgoing) {
            const conditionalValue = orderService.resolveOrderAttribute(
              this.order,
              entry.path,
              entry.ioType,
              undefined,
              undefined,
              this.pathPrefix,
              conf.isAbsolutePath
            );
            if (entry.value === true || entry.value === false) {
              // boolean case, should also check if field is just set
              if (!!conditionalValue?.value !== entry.value) {
                hideOutgoing = false;
                break;
              }
            } else {
              // check for specific value
              if (conditionalValue?.value !== entry.value) {
                hideOutgoing = false;
                break;
              }
            }
          }
        }
        // check if all incoming conditions are true (if there is any)
        let hideIncoming = conf.conditionalHideIncoming?.length ? true : false;
        if (hideIncoming) {
          for (const entry of conf.conditionalHideIncoming) {
            const conditionalValue = orderService.resolveOrderAttribute(
              this.order,
              entry.path,
              entry.ioType,
              undefined,
              undefined,
              this.pathPrefix,
              conf.isAbsolutePath
            );
            if (entry.value === true || entry.value === false) {
              // boolean case, should also check if field is just set
              if (!!conditionalValue?.value !== entry.value) {
                hideIncoming = false;
                break;
              }
            } else {
              // check for specific value
              if (conditionalValue?.value !== entry.value) {
                hideIncoming = false;
                break;
              }
            }
          }
        }
        // check computed io type
        if (hideIncoming && hideOutgoing) {
          return false; // only filter out completeley if both are true
        } else if (hideIncoming) {
          conf.conditionalIoType = ioTypes.OUTGOING;
        } else if (hideOutgoing) {
          conf.conditionalIoType = ioTypes.INCOMING;
        } else {
          conf.conditionalIoType = ioTypes.BOTH;
        }
        return true;
      });
    },
    collectionLines() {
      if (this.inputLines.uniformPaths)
        return this.filteredLines.filter(
          (data) => !this.inputLines.uniformPaths.includes(data.path)
        );
      return this.filteredLines;
    },
    uniformLines() {
      if (!this.inputLines.uniformPaths) return [];
      return this.filteredLines.filter((data) =>
        this.inputLines.uniformPaths.includes(data.path)
      );
    },
    ...mapState('order', ['order', 'orderUpdate']),
  },
  watch: {
    orderUpdate: function () {
      this.resolveKeys();
    },
  },
  methods: {
    collectionLinesFilteredByKey(collectionKey) {
      // some lines that should only be visible for a specific collectionKey
      return this.collectionLines.filter(
        (line) =>
          !line.onlyForCollectionKey ||
          line.onlyForCollectionKey === collectionKey
      );
    },
    addEntry(selected) {
      const params = {
        order: this.order,
        path: this.inputLines.listPath,
        index: this.collectionKeys.length,
        differentiator: this.inputLines.differentiator,
        prePath: this.pathPrefix,
        differentiatorOption:
          this.inputLines.differentiatorOption ||
          this.inputLines.differentiator,
        value: selected.value,
        uniformPaths: this.inputLines.uniformPaths,
        dependentPaths: this.inputLines.dependentPaths,
        reloadView: true,
      };
      this.addDifferentiatorEntry(params).then(() => {
        this.resolveKeys();
      });
    },
    removeEntry(selected) {
      //"inital values" are Integer, "added values" are Strings
      const index =
        this.collectionKeys.indexOf(selected.value) != -1
          ? this.collectionKeys.indexOf(selected.value)
          : this.collectionKeys.indexOf(parseInt(selected.value));
      const params = {
        order: this.order,
        path: this.inputLines.listPath,
        index: index,
        differentiator: this.inputLines.differentiator,
        prePath: this.pathPrefix,
        differentiatorOption:
          this.inputLines.differentiatorOption ||
          this.inputLines.differentiator,
        value: '',
        dependentPaths: this.inputLines.dependentPaths,
        collectionSize: this.collectionKeys.length,
        reloadView: true,
      };
      this.removeDifferentiatorEntry(params).then(() => {
        this.resolveKeys();
      });
    },
    getDifferentiator() {
      const diffName =
        this.inputLines.differentiatorName || this.inputLines.differentiator;
      return this.$t('editorder.differentiator.' + diffName);
    },
    getCardTitle(collectionName) {
      const optionValue = this.defaultOptionValues.find(
        (v) => v.value === collectionName
      );

      if (optionValue) return this.getDifferentiator() + ' ' + optionValue.text;
      else return this.getDifferentiator() + ' ' + collectionName;
    },
    resolveKeys() {
      const params = {
        order: this.order,
        path: this.inputLines.listPath,
        type: 'Incoming',
        prePath: this.pathPrefix,
        differentiator: this.inputLines.differentiator,
      };
      this.collectionKeys = orderService.resolveDifferentiatorValues(params);
    },
    ...mapActions({
      addDifferentiatorEntry: 'order/addDifferentiatorEntry',
      removeDifferentiatorEntry: 'order/removeDifferentiatorEntry',
    }),
  },
  data() {
    return {
      collectionKeys: [],
      activeTab: 0,
    };
  },
  mounted() {
    this.resolveKeys();
  },
  props: {
    inputLines: Object,
    splitView: {
      default: false,
    },
    pathPrefix: String,
  },
};
</script>

<style scoped></style>
