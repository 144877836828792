import api from './api'
import apiEndpoints from "@/constants/api/apiEndpoints";

export default {
    async getEmailAddressesFromApi() {
        return api().get(`${apiEndpoints.emailAddress}`).catch(err => {
            console.error(err);
            return err;
        });
    },
    async createEmailAddress({address, languageCode}) {
        return api().post(apiEndpoints.emailAddress, {address, languageCode}).catch(err => {
            console.error(err);
            return err;
        });
    },
    async updateEmailAddress({id, address, languageCode}) {
        return api().put(`${apiEndpoints.emailAddress}/${id}`,{address, languageCode}).catch(err => {
            console.error(err);
            return err;
        });
    },
    async deleteEmailAddress(id) {
        return api().delete(`${apiEndpoints.emailAddress}/${id}`).catch(err => {
            console.error(err);
            return err;
        });
    },
}
