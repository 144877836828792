<template>
  <div>
    <edit-order-take-over-all-entries :chapters="chapters"/>
    <edit-order-chapter-frame chapter-name="wagon.tab.loadAddress">
      <edit-order-simple-input-container :inputLines="chapters.base"/>
    </edit-order-chapter-frame>
    <edit-order-chapter-frame chapter-name="wagon.tab.loadLimitGrid">
      <edit-order-load-limit-collection-input-container :config="chapters.loadLimitGrid"/>
    </edit-order-chapter-frame>
  </div>
</template>

<script>
import inputValues from "../../constants/order/inputValues";

export default {
  data() {
    return {
      chapters: inputValues.wagon.loadLimitGrid.chapters
    }
  },
  name: "WagonLoadlimitGrid"
}
</script>

<style scoped>

</style>