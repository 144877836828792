<template>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('emailAddress.delete.title') }}</span>
        </v-card-title>

        <v-card-text>
          {{ $t('emailAddress.delete.description', [emailAddress.address]) }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteEmailAddressConfirmed">
            {{ $t('app.btn.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapMutations} from "vuex";

    export default {
        name: "deleteDialog",

        data() {
            return {
                dialog: false,
                emailAddress: {}
            }
        },

        methods: {
            open(emailAddress) {
                this.emailAddress = Object.assign({}, emailAddress);
                this.dialog = true;
            },
            deleteEmailAddressConfirmed() {
                let emailAddressName = this.emailAddress.address;
                this.deleteEmailAddress(this.emailAddress).then(success => {
                    if (success) {
                        this.onNotification({message: this.$t('emailAddress.deleteDialog.success.delete.user', [emailAddressName]), color: 'success'});

                    } else {
                        this.onNotification({message: this.$t('emailAddress.deleteDialog.error.delete.user', [emailAddressName]), color: 'error'});
                    }
                });

                this.close();
            },
            close() {
                this.dialog = false;

                this.$nextTick(() => {
                    Object.assign(this.emailAddress, this.defaultEmailAddress);
                });
            },

            ...mapMutations({
                onNotification: 'snackbar/onNotification'
            }),

            ...mapActions({
                deleteEmailAddress: 'email-address/deleteEmailAddress'
            })
        },

    }

</script>

<style scoped>

</style>