<template>
    <v-lazy fluid class="ma-0 pa-0">
        <v-container fluid class="ma-0 pa-1">
            <v-row>
                <v-tabs v-model="activeTab">
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                            v-for="(collectionKey, collectionIndex) in collectionKeys"
                            :key="'#'+collectionKey+'-'+collectionIndex"
                    >
                        {{ getCardTitle(collectionKey) }}
                    </v-tab>
                    <core-add-differentiator-list-dialog
                            :in-use="collectionKeys"
                            :values="defaultOptionValues"
                            :disabled="disabled"
                            :diff-name="getDifferentiator()"
                            @add="addEntry"
                    />
                    <core-add-differentiator-list-dialog
                        :in-use="collectionKeys"
                        :values="defaultOptionValues"
                        :disabled="disabled"
                        :diff-name="getDifferentiator()"
                        :deletion="true"
                        @add="removeEntry"
                    />
                </v-tabs>
                <v-col md="12" class="mx-0 pl-0 pr-1 mb-4">
                    <v-tabs-items v-model="activeTab">
                        <v-tab-item
                                v-for="(collectionKey, collectionIndex) in collectionKeys"
                                :transition="false"
                                :reverse-transition="false"
                                :key="'#'+collectionKey+'-'+collectionIndex"
                        >
                          <v-col class="px-2">
                            <v-card tile flat>
                                <v-row>
                                    <v-col class="my-1 px-1 pb-2 pt-0" cols="12">
                                        <v-radio-group v-model="cmGridType" row>
                                            <v-radio :label="$t('editorder.cmGrid')" :value="true">
                                            </v-radio>
                                            <v-radio :label="$t('editorder.cmdGrid')" :value="false">
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col class="my-1 px-1 pb-2 pt-0" md="6" sm="12" xs="12" cols="12"
                                           v-for="(conf, index) in displayCmType(inputLines.data)"
                                           :key="index">
                                        <edit-order-input-line
                                                :conf="conf"
                                                :order="order"
                                                :key="conf.path+orderUpdate"
                                                :collectionIndex="collectionIndex"
                                                :outgoing-only="conf.outgoingOnly"
                                                :collectionPath="inputLines.listPath"
                                                :path-prefix="pathPrefix"
                                                :disabled="disabled"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card>
                          </v-col>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </v-lazy>
</template>

<script>

  import {mapActions, mapState} from "vuex";
  import {ORDER_STATUS} from "@/constants/order/orderStatus";
  import orderService from "@/service/orderService";

  export default {
    name: "CollectionInputContainer",
    computed: {
      disabled() {
        return this.order.status === ORDER_STATUS.DONE || this.order.status === ORDER_STATUS.DONE_ARCHIVED;
      },
      defaultOptionValues() {
        if (this.inputLines.differentiator) {
          const optionType = this.inputLines.differentiatorOption || this.inputLines.differentiator;
          const defaultOption = this.$store.getters.getDefaultOptionByKey(optionType);
          if (defaultOption) {
            return defaultOption.values;
          }
        }
        return [];
      },
      ...mapState('order', ['order', 'orderUpdate']),
    },
    methods: {
      displayCmType(data) {
        return data.filter(conf => {
          const prefix = conf.path.substr(0, 3)
          if (prefix === "CMD") {
            return !this.cmGridType
          } else if (prefix.startsWith("CM")) {
            return this.cmGridType
          }
          return true;
        })
      },
      addEntry(selected) {
        const params = {
          order: this.order,
          path: this.inputLines.listPath,
          index: this.collectionKeys.length,
          differentiator: this.inputLines.differentiator,
          prePath: this.pathPrefix,
          differentiatorOption: this.inputLines.differentiatorOption || this.inputLines.differentiator,
          value: selected.value,
          uniformPaths: this.inputLines.uniformPaths,
          dependentPaths: this.inputLines.dependentPaths,
          reloadView: true
        }
        this.addDifferentiatorEntry(params).then(() => {
          this.resolveKeys();
        })
      },
      removeEntry(selected) {
        //"inital values" are Integer, "added values" are Strings
        const index = this.collectionKeys.indexOf(selected.value) != -1
            ? this.collectionKeys.indexOf(selected.value)
            : this.collectionKeys.indexOf(parseInt(selected.value));
        const params = {
          order: this.order,
          path: this.inputLines.listPath,
          index: index,
          differentiator: this.inputLines.differentiator,
          prePath: this.pathPrefix,
          differentiatorOption: this.inputLines.differentiatorOption || this.inputLines.differentiator,
          value: "",
          dependentPaths: this.inputLines.dependentPaths,
          collectionSize: this.collectionKeys.length,
          reloadView: true
        }
        this.removeDifferentiatorEntry(params).then(() => {
          this.resolveKeys();
        })
      },
      getDifferentiator() {
        const diffName = this.inputLines.differentiatorName || this.inputLines.differentiator;
        return this.$t('editorder.differentiator.' + diffName)
      },
      getCardTitle(collectionName) {
        return this.getDifferentiator() + ' ' + collectionName
      },
      resolveCmGrid(){
        const additionalGrid = orderService.plainResolveOrderAttribute(this.order,`$.Body.Wagen.technicalWaggonIncoming.${this.pathPrefix}.${this.inputLines.listPath}[0]`)
        this.cmGridType = !additionalGrid || Object.keys(additionalGrid).filter(key => key.startsWith("CMD") && additionalGrid[key].value).length === 0
      },
      resolveKeys() {
        const params = {
          order: this.order,
          path: this.inputLines.listPath,
          type: 'Incoming',
          prePath: this.pathPrefix,
          differentiator: this.inputLines.differentiator
        }
        this.collectionKeys = orderService.resolveDifferentiatorValues(params);
      },
      ...mapActions({
            addDifferentiatorEntry: 'order/addDifferentiatorEntry',
            removeDifferentiatorEntry: 'order/removeDifferentiatorEntry'
          }
      )
    },
    data() {
      return {
        cmGridType: true,
        collectionKeys: [],
        activeTab: 0
      }
    },
    mounted() {
      this.resolveKeys()
      this.resolveCmGrid()
    },
    props: {
      inputLines: Object,
      splitView: {
        default: false
      },
      pathPrefix: String
    },
  }
</script>

<style scoped>

</style>
