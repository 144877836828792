import api from './api'
import apiEndpoints from "@/constants/api/apiEndpoints";

//representation of fixed toggles
//please keep up to date
const TOGGLES = {}

const togglzService = {
    async getTogglzFromApi() {
        return api()
            .get(apiEndpoints.toggles)
    },
    getTogglz() {
        let togglz = window.localStorage.getItem('vers-togglz');
        return togglz !== null ? JSON.parse(togglz) : {};
    },
    isEnabled(key) {
        return this.getTogglz()[key] === true;
    }
}

const togglzServiceMixin = {
    methods: togglzService
}
export {togglzService, togglzServiceMixin, TOGGLES}
