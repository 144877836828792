// https://vuex.vuejs.org/en/actions.html

import defaultOptionService from "../service/defaultOptionService";

import i18n from '../i18n';
import {togglzService} from "../service/togglzService";
import workshopService from "@/service/workshopService";
import userService from "@/service/userService";

const actions = {
    async initApp(context, isLogin) {
        await context.dispatch('initDefaultOptions', {lang: context.state.language, isLogin: isLogin});
        const togglz = await togglzService.getTogglzFromApi();
        if (togglz) {
            context.commit('onTogglzUpdated', togglz.data);
        }
        context.commit('setProtocolMaintenance', workshopService.protocolMaintenance());
        context.commit('setUser', userService.getUser());
    },
    initDefaultOptions(context, {lang, isLogin}) {
        context.commit('onDefaultOptionsLoaded', 0);
        //get options from DB
        const defaultOptionsLang = lang.replace(/-.*/, '');
        defaultOptionService.getDefaultOptionsFromDb(defaultOptionsLang)
            .then(defaultOptionsFromDb => {
                let afterValue;
                if (defaultOptionsFromDb.length > 0) {
                    context.commit('setDefaultOptions', defaultOptionsFromDb);
                    context.commit('onDefaultOptionsLoaded', 33);
                    // get latest lastModified date of the defaultOptions
                    const reducer = (pre, cur) => pre.lastModified > cur.lastModified ? pre : cur;
                    afterValue = defaultOptionsFromDb.reduce(reducer).lastModified
                }

                //get options from API
                defaultOptionService.getDefaultOptionsWithValuesFromApi(defaultOptionsLang, afterValue, isLogin)
                    .then(defaultOptionsFromApi => {
                        context.commit('onDefaultOptionsLoaded', 66);
                        defaultOptionService.saveDefaultOptionValuesFromApi(defaultOptionsFromDb, defaultOptionsFromApi, defaultOptionsLang, isLogin)
                            .then(updatedDefaultOptions => {
                                context.commit('setDefaultOptions', updatedDefaultOptions);
                                context.commit('onDefaultOptionsLoaded', 100);
                                context.commit('snackbar/onNotification', {
                                    message: i18n.t('defaultoptions.updated'),
                                    color: 'info',
                                    btnColor: 'white'
                                });
                            }).catch(error => {
                            console.error(error);
                        });
                    });
            });
    },
    getDefaultOptionValuesForKey({getters}, key) {
        if (key) {
            const defaultOption = getters.getDefaultOptionByKey(key);
            if (defaultOption) {
                return defaultOption.values;
            }
        }
        return [];
    },
    saveLanguage({dispatch, commit}, payload) {
        dispatch('initDefaultOptions', {lang: payload.language, isLogin: false}).then(() => null);
        commit('onLanguageChanged', payload);
    },

    async updateDefaultOption(context, {id, newValues, modifiedValues, valuesToDelete}) {
        let withoutErrors = true;
        const defaultOption = context.state.defaultOptions.filter(option => option.id === id)[0];

        // handle new values
        for (const value of newValues) {
            // tmp will be a clean defaultOption value again, without 'modified' ord 'delete' flags
            let tmp = {text: value.text, value: value.value, global: value.global}
            // send update to api
            const response = await defaultOptionService.addDefaultOptionValueToApi(id, tmp);
            if (response !== undefined && response.status === 200) {
                const newId = response.data.id;
                tmp = {id: newId, ...tmp}
                // add to List
                defaultOption.values.push(tmp);
            } else {
                withoutErrors = false;
                console.error('Error while adding defaultOptionValue to api. Response:')
                console.error(response);
            }
        }

        // handle modified values
        for (const value of modifiedValues) {
            // tmp will be a clean defaultOption value again, without 'modified' ord 'delete' flags
            const tmp = {id: value.id, text: value.text, value: value.value, global: value.global}
            // send update to api
            const response = await defaultOptionService.updateDefaultOptionValueToApi(id, tmp);
            if (response !== undefined && response.status === 200) {
                // update in list
                const index = defaultOption.values.findIndex(v => v.id === tmp.id);
                Object.assign(defaultOption.values[index], tmp);
            } else {
                withoutErrors = false;
                console.error('Error while updating defaultOptionValue to api. Response:')
                console.error(response);
            }
        }

        // handle values to delete
        for (const value of valuesToDelete) {
            const response = await defaultOptionService.deleteDefaultOptionValueFromApi(id, value.id);
            if (response !== undefined && response.status === 204) {
                // delete from list
                const index = defaultOption.values.findIndex(v => v.id === value.id);
                defaultOption.values.splice(index, 1);
            } else {
                withoutErrors = false;
                console.error('Error while deleting defaultOptionValue from api. Response:')
                console.error(response);
            }
        }
        // update defaultOption in indexDB
        await defaultOptionService.saveDefaultOptions([defaultOption]);
        // update defaultOption in state
        context.commit('updateDefaultOptionOption', defaultOption);
        return withoutErrors;
    },
}

export default actions
