<template>
    <edit-order-collection-input-container :inputLines="inputLines" />
</template>

<script>
    import inputValues from "../../constants/order/inputValues";

    export default {
        data() {
            return {
                inputLines: inputValues.wagon.additionalGrid
            }
        },
        name: "WagonLoadlimitGrid"
    }
</script>

<style scoped>

</style>