<template>
      <div>
            <edit-order-take-over-all-entries :chapters="chapters"/>
            <edit-order-simple-input-container :inputLines="chapters.basedata" />
            <edit-order-chapter-frame chapter-name="axle">
                  <edit-order-simple-input-container :inputLines="chapters.axle"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="paintworkAxle">
                  <edit-order-collection-input-container :inputLines="chapters.paintwork"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="wheels">
                  <edit-order-collection-input-container :inputLines="chapters.wheel" :split-view="true"/>
            </edit-order-chapter-frame>
            <edit-order-chapter-frame chapter-name="bearing">
                  <edit-order-collection-input-container :inputLines="chapters.bearing" />
            </edit-order-chapter-frame>
      </div>
</template>

<script>
import inputValues from "../../constants/order/inputValues";

export default {
      data() {
            return {
                  chapters: inputValues.wheelset.repairs.chapters
            }
      },
      name: "WheelsetRepairs"
}
</script>

<style scoped>

</style>