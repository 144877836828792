// https://vuex.vuejs.org/en/getters.html
export default {
  getOrderById: (state) => (id) => {
    return state.orders.find((order) => order.id === id);
  },
  newOrder: (state) => state.newOrder,
  getAxesNumber: (state) => {
    return state.order?.Body?.Wagen?.technicalWaggonOutgoing?.Understructure
      ?.WheelsetCollection?.Wheelset?.length;
  },
  getWagonPathNameDict: (state) => {
    return state.wagonPathNameDict
  },
  getWheelsetPathNameDict: (state) => {
    return state.wheelsetPathNameDict
  },
};
