<template>
  <v-card
    outlined
    height="100%"
    :style="'border-color:' + getBorderColor()"
    v-if="!hideCollection || completeUniform"
  >
    <v-card-text class="pa-2">{{ lineLabel }}</v-card-text>
    <v-container fluid class="ma-0">
      <v-row align="start" justify="center" v-if="ioType === ioTypes.INCOMING">
        <v-col cols="12">
          <input-component
            :data="incomingAttribute"
            :conf="conf"
            :isDifferentiator="isDifferentiator"
            @input="changeIncomingValue"
            type="Incoming"
            :useSelfCheckNumber="useSelfCheckNumber"
            :disabled="disabled"
          />
        </v-col>
      </v-row>

      <v-row
        align="start"
        justify="center"
        v-else-if="ioType === ioTypes.OUTGOING"
      >
        <v-col cols="12">
          <input-component
            :data="outgoingAttribute"
            :conf="conf"
            :isDifferentiator="isDifferentiator"
            @input="changeOutgoingValue"
            type="Outgoing"
            :useSelfCheckNumber="useSelfCheckNumber"
            :disabled="disabled"
          />
        </v-col>
      </v-row>

      <v-row align="start" justify="center" v-else>
        <v-col xs="5" class="text-center pa-2 ma-0">
          <input-component
            :data="incomingAttribute"
            :conf="conf"
            :isDifferentiator="isDifferentiator"
            @input="changeIncomingValue"
            type="Incoming"
            :useSelfCheckNumber="useSelfCheckNumber"
            :disabled="disabled"
          />
        </v-col>
        <v-col xs="2" class="text-center ma-0 px-0">
          <div>
            <v-btn
              outlined
              :min-width="$vuetify.breakpoint.xs ? 50 : 100"
              :max-width="$vuetify.breakpoint.xs ? 50 : 100"
              class="mb-2"
              @click="acceptInputValue"
              tabindex="-1"
              :disabled="
                incomingAttribute === undefined ||
                incomingAttribute === '' ||
                (incomingAttribute && incomingAttribute.value === '') ||
                conf.type === 'formula' ||
                disabled
              "
            >
              <v-icon class="mr-0 pr-0">mdi-text-box-check-outline</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn
              outlined
              :min-width="$vuetify.breakpoint.xs ? 50 : 100"
              :max-width="$vuetify.breakpoint.xs ? 50 : 100"
              @click="takeInputValue"
              tabindex="-1"
              :disabled="
                incomingAttribute === undefined ||
                incomingAttribute === '' ||
                (incomingAttribute && incomingAttribute.value === '') ||
                conf.type === 'formula' ||
                disabled
              "
            >
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col xs="5" class="text-center ma-0">
          <input-component
            :data="outgoingAttribute"
            :conf="conf"
            :isDifferentiator="isDifferentiator"
            @input="changeOutgoingValue"
            type="Outgoing"
            :useSelfCheckNumber="useSelfCheckNumber"
            :disabled="
              disabled ||
              (collectionPath === 'WheelCollection.Wheel' && isDifferentiator)
            "
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import InputComponent from './InputComponent';
import orderService from '../../service/orderService';
import jsonPathHelper from '../../service/jsonPathHelper';
import objectHelper from '@/service/objectHelper';
import ioTypes from '../../constants/order/ioTypes';

export default {
  name: 'InputLine',
  props: {
    conf: Object,
    collectionIndex: Number,
    collectionPath: String,
    order: Object,
    isDifferentiator: {
      type: Boolean,
      default: false,
    },
    useSelfCheckNumber: {
      type: Boolean,
      default: false,
    },
    uniformLine: {
      type: Boolean,
      default: false,
    },
    additionalPaths: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    ioType: {
      type: String,
      default: ioTypes.BOTH,
    },
    pathPrefix: String,
    differentiator: String,
    differentiatorOption: String,
    uniformPaths: Array,
    completeUniform: Boolean,
    currentUniform: Boolean,
  },
  components: {
    InputComponent,
  },
  mounted() {
    this.$nextTick(() => {
      this.incomingAttribute = orderService.resolveOrderAttribute(
        this.order,
        this.conf.path,
        'Incoming',
        this.collectionIndex,
        this.collectionPath,
        this.pathPrefix,
        this.conf.isAbsolutePath
      );
      this.outgoingAttribute = orderService.resolveOrderAttribute(
        this.order,
        this.conf.path,
        'Outgoing',
        this.collectionIndex,
        this.collectionPath,
        this.pathPrefix,
        this.conf.isAbsolutePath
      );
    });
  },
  computed: {
    lineLabel() {
      let lineLabel = this.$t(
        'editorder.' + (this.conf.name || this.conf.path)
      );

      if (this.additionalPaths && this.additionalPaths.length > 0) {
        lineLabel += `, ${this.additionalPaths
          .map((e) => this.$t('editorder.' + e))
          .join(', ')}`;
      }
      if (this.conf.unit) {
        lineLabel += this.conf.unitDisplayed
          ? ` (${this.conf.unitDisplayed})`
          : ` (${this.conf.unit})`;
      }
      return lineLabel;
    },
    emptyCollection() {
      const collection = orderService.resolveOrderAttribute(
        this.order,
        this.collectionPath,
        'Incoming',
        undefined,
        undefined,
        this.pathPrefix,
        this.conf.isAbsolutePath
      );
      if (!collection || Object.keys(collection).length === 0) {
        return true;
      }
      return false;
    },
    hideCollection() {
      if (this.uniformLine) {
        if (this.currentUniform) return false;
        if (this.emptyCollection) return true;
      }
      return false;
    },
    getPossibleAndSetValues() {
      let possibleValues, setValues;
      const optionType = this.differentiatorOption;
      const defaultOption =
        this.$store.getters.getDefaultOptionByKey(optionType);
      if (defaultOption) {
        possibleValues = defaultOption.values.map((v) => v.value);
        const params = {
          order: this.order,
          path: this.collectionPath,
          type: 'Outgoing',
          prePath: this.pathPrefix,
          differentiator: this.differentiator,
        };
        setValues = orderService.resolveDifferentiatorValues(params);
      }
      return { possibleValues, setValues };
    },
  },
  data() {
    return {
      incomingAttribute: null,
      outgoingAttribute: null,
      ioTypes: ioTypes,
    };
  },
  methods: {
    async initFirstCollectionEntry() {
      const differentiatorValues = this.getPossibleAndSetValues;
      if (differentiatorValues.possibleValues) {
        if (!differentiatorValues.setValues.length) {
          const params = {
            order: this.order,
            path: this.collectionPath,
            index: 0,
            differentiator: this.differentiator,
            prePath: this.pathPrefix,
            differentiatorOption: this.differentiatorOption,
            value: differentiatorValues.possibleValues[0],
            uniformPaths: this.uniformPaths,
            reloadView: false,
          };
          await this.addDifferentiatorEntry(params);
        }
      }
    },
    async initMissingCollectionEntries() {
      const differentiatorValues = this.getPossibleAndSetValues;
      if (differentiatorValues.possibleValues) {
        let i = 0;
        for (const value of differentiatorValues.possibleValues) {
          if (!differentiatorValues.setValues.includes(value)) {
            const params = {
              order: this.order,
              path: this.collectionPath,
              index: differentiatorValues.setValues.length + i,
              differentiator: this.differentiator,
              prePath: this.pathPrefix,
              differentiatorOption: this.differentiatorOption,
              value: value,
              uniformPaths: this.uniformPaths,
              reloadView: false,
            };
            await this.addDifferentiatorEntry(params);
            i++;
          }
        }
      }
    },
    getBorderColor() {
      return (this.outgoingAttribute && this.outgoingAttribute.value !== '') ||
        (this.ioType === ioTypes.INCOMING &&
          this.incomingAttribute &&
          this.incomingAttribute.value !== '') ||
        this.disabled
        ? 'rgba(0,0,0,.12)'
        : '#ff8c00';
    },
    async changeResultValue(resultValue, incomingOrOutgoing) {
      const orderType = this.order.type.toLowerCase();
      const orderId = this.order.id;

      //check if a "complete uniform"-collection is incomplete
      if (this.completeUniform) {
        await this.initMissingCollectionEntries();
      } else if (this.currentUniform) {
        await this.initFirstCollectionEntry();
      }

      //gather paths that should be updated
      let paths;
      if (this.uniformLine) {
        const uniformUpdatePaths = this.resolveUniformUpdatePaths();
        paths = [...this.additionalPaths, ...uniformUpdatePaths];
      } else {
        paths = [...this.additionalPaths, this.conf.path];
      }

      for (let i = 0, end = paths.length; i < end; i++) {
        const path = paths[i];
        const reloadView =
          (i === paths.length - 1 && this.completeUniform) ||
          path === 'SuperstructureType';
        //only reload after last item of uniform Collection or if Superstructure-Type changed
        const key = this.uniformLine
          ? jsonPathHelper.getJsonPathKey(
              orderType,
              path,
              incomingOrOutgoing,
              undefined,
              undefined,
              this.pathPrefix,
              this.conf.isAbsolutePath
            )
          : jsonPathHelper.getJsonPathKey(
              orderType,
              path,
              incomingOrOutgoing,
              this.collectionPath,
              this.collectionIndex,
              this.pathPrefix,
              this.conf.isAbsolutePath
            );
        const activityType = this.conf.activityType || 'XML_MODIFICATION';
        const sanitizedResultValue =
          this.conf.type === 'baseString'
            ? resultValue
            : objectHelper.removeUndefined(resultValue);
        this.saveOrderResult({
          orderType,
          orderId,
          key,
          resultValue: sanitizedResultValue,
          activityType,
          reloadView,
        }).then(() => {
          if (this.conf.invoke) {
            this.calculateMatchingOrderResult({
              orderType,
              orderId,
              conf: this.conf,
              incomingOrOutgoing,
              collectionPath: this.collectionPath,
              collectionIndex: this.collectionIndex,
              pathPrefix: this.pathPrefix,
              CaptureDate: resultValue.CaptureDate,
            });
          }
        });
      }
    },
    resolveUniformUpdatePaths() {
      const collection = orderService.resolveOrderAttribute(
        this.order,
        this.collectionPath,
        'Incoming',
        undefined,
        undefined,
        this.pathPrefix,
        this.conf.isAbsolutePath
      );
      if (collection) {
        return collection.map(
          (_, idx) => `${this.collectionPath}[${idx}].${this.conf.path}`
        );
      }
      return [];
    },
    changeIncomingValue(value) {
      orderService.enrichLocalLastModifierIfAllowed(this.$store.state, value);
      this.incomingAttribute = value;
      this.changeResultValue(value, 'Incoming');
    },
    changeOutgoingValue(value) {
      orderService.enrichLocalLastModifierIfAllowed(this.$store.state, value);
      this.outgoingAttribute = value;
      this.changeResultValue(value, 'Outgoing');
    },
    takeInputValue() {
      if (Array.isArray(this.incomingAttribute)) {
        const valueList = this.incomingAttribute.map((entry) => ({
          value: entry.value ? entry.value : entry,
          type: this.conf.optionType || this.conf.option,
          CaptureDate: this.$moment().utc().toISOString(),
        }));
        this.changeResultValue(valueList, 'Outgoing');
        orderService.enrichLocalLastModifierIfAllowed(
          this.$store.state,
          valueList
        );
        this.outgoingAttribute = valueList;
      } else {
        const val =
          this.conf.type === 'baseString'
            ? this.incomingAttribute
            : {
                value: this.incomingAttribute.value,
                unit: this.conf.unit,
                type: this.conf.optionType || this.conf.option,
                CaptureDate: this.$moment().utc().toISOString(),
              };
        this.changeResultValue(val, 'Outgoing');
        orderService.enrichLocalLastModifierIfAllowed(this.$store.state, val);
        this.outgoingAttribute = val;
      }
    },
    acceptInputValue() {
      if (Array.isArray(this.incomingAttribute)) {
        const valueList = this.incomingAttribute.map((entry) => ({
          value: entry.value ? entry.value : entry,
          type: this.conf.optionType || this.conf.option,
          CaptureDate: this.$moment().utc().toISOString(),
        }));
        this.changeResultValue(valueList, 'Incoming');
        orderService.enrichLocalLastModifierIfAllowed(
          this.$store.state,
          valueList
        );
        this.incomingAttribute = valueList;
      } else {
        const val =
          this.conf.type === 'baseString'
            ? this.incomingAttribute
            : {
                value: this.incomingAttribute.value,
                unit: this.conf.unit,
                type: this.conf.optionType || this.conf.option,
                CaptureDate: this.$moment().utc().toISOString(),
              };

        this.changeResultValue(val, 'Incoming');
        orderService.enrichLocalLastModifierIfAllowed(this.$store.state, val);
        this.incomingAttribute = val;
      }
    },
    ...mapActions({
      addDifferentiatorEntry: 'order/addDifferentiatorEntry',
      saveOrderResult: 'order/saveOrderResult',
      calculateMatchingOrderResult: 'order/calculateMatchingOrderResult',
    }),
  },
};
</script>

<style scoped></style>
