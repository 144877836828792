import inputValues from "../constants/order/inputValues";
import jsonPathHelper from "./jsonPathHelper";
import jsonpath from "jsonpath";
import ERRORS from "@/constants/errors";
import orderService from "@/service/orderService";

const calculations = {
    calculateSRMass: {
        formula: ({ar, sdA, sdB}) => parseFloat(ar) + parseFloat(sdA) + parseFloat(sdB),
        path: inputValues.wagon.wheelsets.chapters.basedata.data
    },
    calculateShrinkInterferenceFit: {
        formula: ({axleStubBore, diameterRing}) => (parseFloat(axleStubBore) - parseFloat(diameterRing)) * 1000,
        path: inputValues.wheelset.axleMeasures.data
    }
}

function checkUndefinedParameters(parameters, conf) {
    const undefinedKeys = Object.keys(parameters).filter(key => parameters[key] === undefined)
    if(undefinedKeys.length > 0) {
        throw {error: ERRORS.undefinedParamsForCalc, values: undefinedKeys, conf}
    }
}

function getReferenceCollectionConf(path) {
    let obj = inputValues;
    for (let i=0, paths = path.split('.'), len = paths.length; i < len; i++){
        obj = obj[paths[i]];
    }
    return obj;
}

export default {
    getResolvedParameters({orderType, formulaConf, incomingOrOutgoing, collectionPath, collectionIndex, pathPrefix, order}) {
        const params = {};
        Object.keys(formulaConf.params).map(key => {
            const param = formulaConf.params[key];
            const path = param.path;

            if(param.collectionIdentifier) {
                const paramCollectionConf = getReferenceCollectionConf(param.referencePath);
                collectionPath = paramCollectionConf.listPath;
                const params = {
                    order: order,
                    path: collectionPath,
                    type: 'Incoming',
                    prePath: undefined,
                    differentiator: paramCollectionConf.differentiator
                }
                const collectionKeys = orderService.resolveDifferentiatorValues(params);
                collectionIndex = collectionKeys.findIndex(k => param.collectionIdentifier.includes(k));
            }

            const jsonPathKey = jsonPathHelper.getJsonPathKey(orderType, path, incomingOrOutgoing, collectionPath, collectionIndex, pathPrefix);
            const resolvedAttribute = jsonpath.value(order, jsonPathKey);
            params[key] = resolvedAttribute ? parseFloat(resolvedAttribute.value) : undefined;
        })
        return params;
    },
    invokeCalculation({orderType, conf, incomingOrOutgoing, pathPrefix, order}) {
        const calculation = calculations[conf.invoke];
        const formulaConfs = calculation.path.filter(p => p.formula === conf.invoke && Object.values(p.params).find(param => param.path.indexOf(conf.path) >= 0) !== undefined);
        return formulaConfs.map(formulaConf => {
            const jsonPath = jsonPathHelper.getJsonPathKey(orderType, formulaConf.path, incomingOrOutgoing, undefined, undefined, pathPrefix);
            const parameters = this.getResolvedParameters({
                orderType,
                formulaConf,
                incomingOrOutgoing,
                pathPrefix,
                order
            })

            checkUndefinedParameters(parameters, formulaConf)

            const decimalPlaces = formulaConf.decimalPlaces || 0
            return {
                calculatedValue: parseFloat(calculation.formula(parameters)).toFixed(decimalPlaces),
                key: jsonPath
            }
        })
    }
}
