<template>
  <v-lazy fluid class="ma-0 pa-0">
    <v-container fluid class="ma-0 pa-1">
      <v-row v-if="config.uniformPaths">
        <v-col class="my-1 px-1 pb-1 pt-0" v-for="(conf, index) in uniformLines"
               :key="index" :md="conf.halfWidth?3:6" :cols="conf.halfWidth?6:12">
          <edit-order-input-line
              :conf="conf"
              :order="order"
              :key="conf.path+orderUpdate"
              :collectionIndex="0"
              :collectionPath="config.listPath"
              :ioType="conf.ioType"
              :uniform-line="true"
              :disabled="disabled"
              :show-if-empty="true"
          ></edit-order-input-line>
        </v-col>
      </v-row>

      <v-row>
        <v-tabs v-model="activeTab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
              v-for="(collectionKey, collectionIndex) in collectionKeys"
              :key="'#'+collectionKey+'-'+collectionIndex"
          >
            {{ getCardTitle(collectionKey) }}
          </v-tab>
          <core-add-differentiator-list-dialog
              :in-use="collectionKeys"
              :values="defaultOptionValues"
              :diff-name="getDifferentiator()"
              :disabled="disabled"
              @add="addEntry"
          />
          <core-add-differentiator-list-dialog
              v-if="!singleLimitGridKeys"
              :in-use="collectionKeys"
              :values="defaultOptionValues"
              :disabled="disabled"
              :diff-name="getDifferentiator()"
              :deletion="true"
              @add="removeEntry"
          />
          <v-btn-toggle v-model="filterLoadLimitGridByType" dense style="margin-top: 10px"
                        v-if="loadlimitType"
                        @change="updateLoadLimitGrid(loadlimitType)">
            <v-btn :value="true">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
            <v-btn :value="false">
              <v-icon>mdi-filter-off</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-tabs>
        <v-col md="12" class="mx-0 px-1 mb-4">
          <v-tabs-items v-model="activeTab">
            <v-tab-item
                v-for="(collectionKey, collectionIndex) in collectionKeys"
                :transition="false"
                :reverse-transition="false"
                :key="'#'+collectionKey+'-'+collectionIndex"
            >
              <v-col class="px-2">
              <v-card tile flat>
                <div v-if="!loadlimitType"  class="grey--text text--darken-2 my-1">{{$t('editorder.loadlimitGrid.noLoadLimitType')}}</div>
                <v-row>
                  <v-col class="my-1 px-1 pb-2 pt-0" md="6" sm="12" xs="12" cols="12" v-for="(conf, index) in gridFields"
                         :key="index">
                    <edit-order-input-line
                        :conf="conf"
                        :isDifferentiator="conf.path === config.differentiator"
                        :order="order"
                        :key="conf.path+orderUpdate"
                        :collectionIndex="collectionIndex"
                        :additionalPaths="getAdditionalPaths(conf.path)"
                        :collectionPath="config.listPath"
                        :disabled="disabled"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <edit-order-chapter-frame chapter-name="wagon.tab.additionalGrid">
                <edit-order-additional-grid-collection-input-container :inputLines="config.chapters.additionalGrid"
                                                       :pathPrefix="getPathPrefix(collectionIndex)"/>
              </edit-order-chapter-frame>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </v-lazy>
</template>

<script>

import {mapActions, mapState} from "vuex";
import {ORDER_STATUS} from "@/constants/order/orderStatus";
import orderService from "@/service/orderService";
import loadLimitGridService from "@/service/loadLimitGridService";

export default {
  name: "CollectionInputContainer",
  computed: {
    disabled() {
      return this.order.status === ORDER_STATUS.DONE || this.order.status === ORDER_STATUS.DONE_ARCHIVED;
    },
    defaultOptionValues() {
      if (this.config.differentiator && !this.singleLimitGridKeys) {
        const optionType = this.config.differentiatorOption || this.config.differentiator;
        const defaultOption = this.$store.getters.getDefaultOptionByKey(optionType);
        if (defaultOption) {
          return defaultOption.values;
        }
      }
      return [];
    },
    gridFields() {
      if(!this.config.uniformPaths) return this.limitGridFields;
      return this.limitGridFields.filter(data => !this.config.uniformPaths.includes(data.path));
    },
    uniformLines() {
      if(!this.config.uniformPaths) return [];
      return this.config.chapters.loadLimitGrid.data.filter(data => this.config.uniformPaths.includes(data.path));
    },
    ...mapState('order', ['order', 'orderUpdate', 'loadlimitType', 'singleLimitGridKeys']),
  },
  watch: {
    'orderUpdate': function() {
      this.resolveKeys();
    },
    'singleLimitGridKeys': function() {
      this.resolveKeys();
    },
    'loadlimitType': function(type) {
      this.updateLoadLimitGrid(type)
    }
  },
  methods: {
    getPathPrefix(index) {
      return `${this.config.listPath}[${index}]`
    },
    getAdditionalPaths(path) {
      const key = path.split('.')[1];
      const paths = this.loadLimitMap[key];
      if(paths) {
        return paths.map(entry => `Lastgrenzraster.${entry}`)
      }
      return []
    },
    addEntry(selected) {
      const params = {
        order: this.order,
        path: this.config.listPath,
        index: this.collectionKeys.length,
        differentiator: this.config.differentiator,
        prePath: this.pathPrefix,
        differentiatorOption: this.config.differentiatorOption || this.config.differentiator,
        value: selected.value,
        uniformPaths: this.config.uniformPaths,
        dependentPaths: this.config.dependentPaths,
        reloadView: true
      }
      this.addDifferentiatorEntry(params).then(() => {
        this.resolveKeys();
      })
    },
    removeEntry(selected) {
      //"inital values" are Integer, "added values" are Strings
      const index = this.collectionKeys.indexOf(selected.value) != -1
          ? this.collectionKeys.indexOf(selected.value)
          : this.collectionKeys.indexOf(parseInt(selected.value));
      const params = {
        order: this.order,
        path: this.config.listPath,
        index: index,
        differentiator: this.config.differentiator,
        prePath: this.pathPrefix,
        differentiatorOption: this.config.differentiatorOption || this.config.differentiator,
        value: "",
        dependentPaths: this.config.dependentPaths,
        collectionSize: this.collectionKeys.length,
        reloadView: true
      }
      this.removeDifferentiatorEntry(params).then(() => {
        this.resolveKeys();
      })
    },
    updateLoadLimitGrid(type) {
      if (this.filterLoadLimitGridByType) {
        if (type) {
          this.loadLimitMap = loadLimitGridService.getLoadlimitGridMapByType(type);
          const uniqueFields = Object.keys(this.loadLimitMap).map(entry => 'Lastgrenzraster.' + entry);
          this.limitGridFields = this.config.chapters.loadLimitGrid.data.filter(field => field.option || uniqueFields.includes(field.path));
          return
        }
      } else {
        this.loadLimitMap = {}
      }
      this.limitGridFields = this.config.chapters.loadLimitGrid.data;
    },
    getDifferentiator() {
      const diffName = this.config.differentiatorName || this.config.differentiator;
      return this.$t('editorder.differentiator.' + diffName)
    },
    getCardTitle(collectionName) {
      if (!this.singleLimitGridKeys) {
        return this.getDifferentiator() + ' ' + collectionName
      }
      return this.$t('editorder.wagon.tab.loadLimitGrid');
    },
    resolveKeys() {
      if (!this.singleLimitGridKeys) {
        const params = {
          order: this.order,
          path: this.config.listPath,
          type: 'Incoming',
          prePath: this.pathPrefix,
          differentiator: this.config.differentiator
        }
        this.collectionKeys = orderService.resolveDifferentiatorValues(params);
      } else {
        this.collectionKeys = this.singleLimitGridKeys;
      }
    },
    ...mapActions({
          addDifferentiatorEntry: 'order/addDifferentiatorEntry',
          removeDifferentiatorEntry: 'order/removeDifferentiatorEntry'
        }
    )
  },
  data() {
    return {
      collectionKeys: [],
      limitGridFields:[],
      loadLimitMap:{},
      filterLoadLimitGridByType: true,
      activeTab: 0
    }
  },
  mounted() {
    setTimeout(() => {
          this.resolveKeys();
          this.updateLoadLimitGrid(this.loadlimitType);
        }, 50) // hack: fix loading of invisible keys
  },
  props: {
    config: Object
  },
}
</script>

<style scoped>

</style>
