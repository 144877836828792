<template>
  <v-lazy fluid class="ma-0 pa-0">
    <v-container fluid class="ma-0 pa-1">
      <v-row>
        <v-col
          v-for="(collectionKey, collectionIndex) in inputLines.collectionKeys"
          :key="collectionIndex"
          class="ma-0 px-0"
          md="6"
          xs="12"
          cols="12"
        >
          <v-card tile flat>
            <v-card-title class="pa-2"
              >{{ $t(collectionKey) }}
              {{ addNumber ? collectionIndex + 1 : '' }}
            </v-card-title>
            <v-col
              class="my-1 px-1 pb-2 pt-0"
              v-for="(conf, index) in collectionLinesFilteredByKey(
                collectionKey
              )"
              :key="index"
            >
              <edit-order-input-line
                :conf="conf"
                :order="order"
                :key="conf.path + orderUpdate"
                :collectionIndex="collectionIndex"
                :collectionPath="inputLines.listPath"
                :collectionKey="collectionKey"
                :path-prefix="pathPrefix"
                :disabled="disabled"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-lazy>
</template>

<script>
import { mapState } from 'vuex';
import { ORDER_STATUS } from '@/constants/order/orderStatus';

export default {
  name: 'SingleCollectionInputContainer',
  computed: {
    disabled() {
      return (
        this.order.status === ORDER_STATUS.DONE ||
        this.order.status === ORDER_STATUS.DONE_ARCHIVED
      );
    },
    ...mapState('order', ['order', 'orderUpdate']),
  },
  props: {
    inputLines: Object,
    pathPrefix: String,
    addNumber: Boolean,
  },
  methods: {
    collectionLinesFilteredByKey(collectionKey) {
      // some lines that should only be visible for a specific collectionKey
      return this.collectionLines.filter(
        (line) =>
          !line.onlyForCollectionKey ||
          line.onlyForCollectionKey === collectionKey
      );
    },
  },
};
</script>

<style scoped></style>
