import api from './api'
import idb from "../store/idb";
import apiEndpoints from "@/constants/api/apiEndpoints";

export default {
    async getDefaultOptionsFromDb(lang) {
        return await idb.getDefaultOptionsByLang(lang);
    },
    async getDefaultOptionByIdFromDb(id) {
        return await idb.getDefaultOptionById(id);
    },
    async getDefaultOptionByKeyAndLang(key, lang) {
        return await idb.getDefaultOptionsByKeyAndLang([key, lang]);
    },
    async getDefaultOptionsWithValuesFromApi(lang, afterValue, isLogin) {
        const params = {}
        params.lang = lang;
        if(!isLogin) params.after = afterValue;
        return await new Promise((resolve) => {
            api()
                .get(apiEndpoints.defaultOptionsWithValues, {params})
                .then(result => {
                    if (result) {
                        resolve(result.data)
                    }
                });
        });
    },
    async saveDefaultOptionValuesFromApi(defaultOptionsFromDb, defaultOptionsFromApi, lang, isLogin) {
        // check DefaultOptions deletions once after login
        if(isLogin) {
            const apiIndexes = defaultOptionsFromApi.map(v => [v.key, v.lang]);
            for(const option of defaultOptionsFromDb) {
                const index = [option.key, option.lang];
                if(! apiIndexes.some(a => index.every((v, i) => v === a[i]))) {
                    //index [key, lang] dont exist anymore at backend
                    await idb.deleteDefaultOption(index);
                }
            }
        }
        // just overwrite the changed DefaultOptions completely
        await idb.saveDefaultOptions(defaultOptionsFromApi);
        // return the fresh state of the options from the indexDB
        return await idb.getDefaultOptionsByLang(lang);
    },
    async saveDefaultOptions(defaultOptionsFromApi) {
        await idb.saveDefaultOptions(defaultOptionsFromApi);
    },
    async addDefaultOptionValueToApi(defaultOptionId, value) {
        return await api().post(`${apiEndpoints.defaultOptions}/${defaultOptionId}`, value).catch(error => error);
    },
    async updateDefaultOptionValueToApi(defaultOptionId, value) {
        return await api().put(`${apiEndpoints.defaultOptions}/${defaultOptionId}/${value.id}`, value).catch(error => error);
    },
    async deleteDefaultOptionValueFromApi(defaultOptionId, valueId) {
        return await api().delete(`${apiEndpoints.defaultOptions}/${defaultOptionId}/${valueId}`).catch(error => error);
    },
}
