<template>
  <v-col class="px-0">
    <v-container fluid class="mx-0 py-0 px-1 mb-8">
      <v-row>
        <v-col
          class="my-1 px-1 pb-1 pt-0"
          v-for="(conf, index) in filteredLines"
          :key="index"
          :md="conf.halfWidth ? 3 : !fullWidth ? 6 : 12"
          :cols="conf.halfWidth ? 6 : 12"
        >
          <edit-order-input-line
            :conf="conf"
            :order="order"
            :path-prefix="pathPrefix"
            :isDifferentiator="conf.path === differentiator"
            :useSelfCheckNumber="conf.useSelfCheckNumber"
            :key="conf.path + orderUpdate"
            :disabled="disabled"
            :ioType="conf.conditionalIoType ?? conf.ioType"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { ORDER_STATUS } from '@/constants/order/orderStatus';
import orderService from '../../service/orderService';
import ioTypes from '@/constants/order/ioTypes';

export default {
  name: 'SimpleInputContainer',
  computed: {
    ...mapState('order', ['order', 'orderUpdate']),
    disabled() {
      return (
        this.order.status === ORDER_STATUS.DONE ||
        this.order.status === ORDER_STATUS.DONE_ARCHIVED
      );
    },
    filteredLines() {
      // filter conditional values
      // may only show INCOMING or OUTGOING, or hide completely
      return this.inputLines.data.filter((conf) => {
        if (
          !conf.conditionalHideOutgoing?.length &&
          !conf.conditionalHideIncoming?.length
        )
          return true;
        // check if all outgoing conditions are true (if there is any)
        let hideOutgoing = conf.conditionalHideOutgoing?.length ? true : false;
        if (hideOutgoing) {
          for (const entry of conf.conditionalHideOutgoing) {
            const conditionalValue = orderService.resolveOrderAttribute(
              this.order,
              entry.path,
              entry.ioType,
              undefined,
              undefined,
              this.pathPrefix,
              conf.isAbsolutePath
            );
            if (entry.value === true || entry.value === false) {
              // boolean case, should also check if field is just set
              if (!!conditionalValue?.value !== entry.value) {
                hideOutgoing = false;
                break;
              }
            } else {
              // check for specific value
              if (conditionalValue?.value !== entry.value) {
                hideOutgoing = false;
                break;
              }
            }
          }
        }
        // check if all incoming conditions are true (if there is any)
        let hideIncoming = conf.conditionalHideIncoming?.length ? true : false;
        if (hideIncoming) {
          for (const entry of conf.conditionalHideIncoming) {
            const conditionalValue = orderService.resolveOrderAttribute(
              this.order,
              entry.path,
              entry.ioType,
              undefined,
              undefined,
              this.pathPrefix,
              conf.isAbsolutePath
            );
            if (entry.value === true || entry.value === false) {
              // boolean case, should also check if field is just set
              if (!!conditionalValue?.value !== entry.value) {
                hideIncoming = false;
                break;
              }
            } else {
              // check for specific value
              if (conditionalValue?.value !== entry.value) {
                hideIncoming = false;
                break;
              }
            }
          }
        }
        // check computed io type
        if (hideIncoming && hideOutgoing) {
          return false; // only filter out completeley if both are true
        } else if (hideIncoming) {
          conf.conditionalIoType = ioTypes.OUTGOING;
        } else if (hideOutgoing) {
          conf.conditionalIoType = ioTypes.INCOMING;
        } else {
          conf.conditionalIoType = ioTypes.BOTH;
        }
        return true;
      });
    },
  },
  props: {
    fullWidth: Boolean,
    inputLines: Object,
    pathPrefix: String,
    differentiator: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
